import { FlagIcon, MapIcon } from '@iconicicons/react';

/**
 *
 * @param {{
 *  point: import('../services/edgeApi').Point;
 *  theme?: import('./hooks').Theme;
 *  extra_text?: string;
 * }} props
 * @returns
 */
export const PointBadge = ({ point, theme = 'dark', extra_text = '' }) => {
  if (!point) {
    return null;
  }
  return (
    <div
      style={{
        borderRadius: 5,
        fontSize: 12,
        backgroundColor: theme === 'dark' ? '#212629' : 'lightgrey',
      }}
      className="m-1 p-1 text-center fw-bold"
      key={point.id}
    >
      {Boolean(point.node) === false && <MapIcon color="yellow" />}{' '}
      {point.delivery_zone_name}: {point.name}
      <br />
      {extra_text}
    </div>
  );
};

/**
 *
 * @param {{
 *  name: string;
 *  theme?: import('./hooks').Theme;
 *  extra_text?: string;
 * }} props
 * @returns
 */
export const MissingPointBadge = ({
  name,
  theme = 'dark',
  extra_text = '',
}) => {
  return (
    <div
      style={{
        borderRadius: 5,
        fontSize: 12,
        backgroundColor: theme === 'dark' ? '#212629' : 'lightgrey',
      }}
      className="m-1 p-1 text-center fw-bold"
      key={name}
    >
      <FlagIcon color="red" />
      {name}
      <br />
      {extra_text}
    </div>
  );
};
