import React, { useCallback } from 'react';
import { useDebouncedCallback } from '../../helpers/hooks';
import { useSelector } from 'react-redux';
import { selectAllPoints } from '../../plotter/plotterSelectors';
import { SpreadsheetModal } from '../../helpers/SpreadsheetModal';
import { selectPointConstraints } from '../scenarioSlice';

/**
 * @typedef {{
 *  POINT: 0,
 *  LOC_NAME: 1,
 *  DIRECTION: 2,
 *  DESCRIPTION: 3,
 *  OAC: 4,
 *  CONSTRAINT_FACTOR: 5,
 *  PRIORITY: 6,
 * }} PointConstraintSpreadsheetColumns
 */

/**
 *
 * Spreadsheet column indexers
 *
 * @type {PointConstraintSpreadsheetColumns}
 */
const COLUMNS = {
  POINT: 0,
  LOC_NAME: 1,
  DIRECTION: 2,
  DESCRIPTION: 3,
  OAC: 4,
  CONSTRAINT_FACTOR: 5,
  PRIORITY: 6,
};

const REQUIRED_COLUMNS = [
  COLUMNS.POINT,
  COLUMNS.DIRECTION,
  COLUMNS.OAC,
  COLUMNS.CONSTRAINT_FACTOR,
  COLUMNS.PRIORITY,
];

/**
 * @type {Record<PointConstraintSpreadsheetColumns, string>}
 */
const COLUMNS_LABELS = {
  [COLUMNS.POINT]: 'Point',
  [COLUMNS.LOC_NAME]: 'Location',
  [COLUMNS.DIRECTION]: 'Direction',
  [COLUMNS.DESCRIPTION]: 'Description',
  [COLUMNS.OAC]: 'Volume',
  [COLUMNS.CONSTRAINT_FACTOR]: 'Constraint Factor (%)',
  [COLUMNS.PRIORITY]: 'Priority',
};

/**
 * @typedef {{
 *  isOpen: boolean;
 *  onToggle: () => void;
 *  onImport: (pointConstraint: import('../scenarioSlice').PointConstraint[]) => void;
 *  onOverwrite: (pointConstraint: import('../scenarioSlice').PointConstraint[]) => void;
 * }} PointConstraintSpreadsheetProps
 */

/**
 *
 * @type {React.FC<PointConstraintSpreadsheetProps>}
 */
export const PointConstraintSpreadsheet = ({
  isOpen,
  onToggle,
  onImport,
  onOverwrite,
}) => {
  const allPoints = useSelector(selectAllPoints);

  const pointConstraint = useSelector(selectPointConstraints);

  /** @type {React.Ref<import('../helpers/Spreadsheet').SpreadsheetRef<import('react-spreadsheet').CellBase>>} */
  const sheetRef = React.useRef(null);

  const parseDirection = useCallback(
    /**
     * @param {string} direction
     * @returns {import('../scenarioSlice').PointFlowDirection}
     */
    (direction) => {
      const upper = direction.toUpperCase();
      if (['RECEIPT', 'WITHDRAW', 'R'].includes(upper)) {
        return 'RECEIPT';
      }
      if (['DELIVERY', 'INJECT', 'INJ', 'D'].includes(upper)) {
        return 'DELIVERY';
      }
      return null;
    },
    [],
  );

  const parseData = useCallback(
    /**
     *
     * @param {import('react-spreadsheet').Matrix<import('react-spreadsheet').CellBase>} data
     * @returns {import('../scenarioSlice').PointConstraint[]}
     */
    (data) => {
      /** @type {import('../scenarioSlice').PointConstraint[]} */
      const parsedPointConstraints = data
        .filter((row) => {
          return REQUIRED_COLUMNS.every((col) => row[col].value !== '');
        })
        .map((row) => {
          return {
            point: row[COLUMNS.POINT].value,
            flow_direction: parseDirection(row[COLUMNS.DIRECTION].value),
            description: row[COLUMNS.DESCRIPTION].value,
            max_volume: row[COLUMNS.OAC].value,
            constraint_factor:
              Number(row[COLUMNS.CONSTRAINT_FACTOR].value || 0) / 100,
            cuts_at_priority: row[COLUMNS.PRIORITY].value,
          };
        });
      return parsedPointConstraints;
    },
    [],
  );

  /**
   * @type {(data: import('react-spreadsheet').Matrix<import('react-spreadsheet').CellBase>) => void}
   */
  const onChangeSpreadsheetDebounce = useDebouncedCallback(
    (data) => {
      const newData = data.map((row) => {
        let newRow = [...row];
        if (
          row[COLUMNS.POINT].value !== '' &&
          !isNaN(Number(row[COLUMNS.POINT].value))
        ) {
          newRow[COLUMNS.LOC_NAME].value =
            allPoints[row[COLUMNS.POINT].value]?.name ?? '';
        }
        return newRow;
      });
      sheetRef.current?.setData(newData);
    },
    1000,
    [allPoints],
  );

  /**
   *
   * @param {import('react-spreadsheet').Matrix<import('react-spreadsheet').CellBase>} data
   */
  const confirmImport = (data) => {
    onImport(parseData(data));
  };

  /**
   *
   * @param {import('react-spreadsheet').Matrix<import('react-spreadsheet').CellBase>} data
   */
  const overwrite = (data) => {
    onOverwrite(parseData(data));
  };

  return (
    <SpreadsheetModal
      ref={sheetRef}
      isOpen={isOpen}
      toggle={onToggle}
      title="Import Point Constraints"
      subTitle={<p className="text-muted">Required fields: *</p>}
      data={[]}
      readonlyColumns={[COLUMNS.LOC_NAME]}
      sheetProps={{
        onChange: onChangeSpreadsheetDebounce,
        columnLabels: Object.keys(COLUMNS_LABELS).map((col) => {
          return `${COLUMNS_LABELS[col]}${
            REQUIRED_COLUMNS.includes(Number(col)) ? ' *' : ''
          }`;
        }),
      }}
      fileName="pointConstraints.xlsx"
      sheetName="Point Constraints"
    >
      <button
        className="btn btn-danger"
        onClick={() => {
          sheetRef.current?.setData([]);
        }}
      >
        Clear
      </button>
      <button
        className="btn btn-primary"
        onClick={() => {
          const scenarioPointConstraints = pointConstraint.map((constraint) => [
            { value: constraint.point },
            { value: allPoints[constraint.point]?.name ?? '' },
            { value: constraint.flow_direction },
            { value: constraint.description },
            { value: constraint.max_volume },
            { value: constraint.constraint_factor },
            { value: constraint.cuts_at_priority },
          ]);
          const newPointConstraints = [
            ...sheetRef.current
              .getData()
              .filter((row) => row[COLUMNS.POINT].value !== ''),
            ...scenarioPointConstraints,
          ];
          sheetRef.current?.setData(newPointConstraints);
        }}
      >
        Pull From Scenario
      </button>
      <button
        className="btn btn-primary"
        onClick={() => {
          overwrite(sheetRef.current.getData());
          sheetRef.current.clear();
        }}
      >
        Overwrite
      </button>
      <button
        className="btn btn-success"
        onClick={() => {
          confirmImport(sheetRef.current.getData());
          sheetRef.current.clear();
        }}
      >
        Confirm Import
      </button>
    </SpreadsheetModal>
  );
};
