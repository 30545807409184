import React, { useCallback } from 'react';
import { useDebouncedCallback } from '../../helpers/hooks';
import { useSelector } from 'react-redux';
import {
  selectAllEdges,
  selectAllPoints,
} from '../../plotter/plotterSelectors';
import { SpreadsheetModal } from '../../helpers/SpreadsheetModal';
import { selectSegmentConstraints } from '../scenarioSlice';

/**
 * @typedef {{
 *  POINT: 0,
 *  LOC_NAME: 1,
 *  DIRECTION: 2,
 *  DESCRIPTION: 3,
 *  OAC: 4,
 *  CONSTRAINT_FACTOR: 5,
 *  PRIORITY: 6,
 * }} SegmentConstraintSpreadsheetColumns
 */

/**
 *
 * Spreadsheet column indexers
 *
 * @type {SegmentConstraintSpreadsheetColumns}
 */
const COLUMNS = {
  POINT: 0,
  LOC_NAME: 1,
  DIRECTION: 2,
  DESCRIPTION: 3,
  OAC: 4,
  CONSTRAINT_FACTOR: 5,
  PRIORITY: 6,
};

const REQUIRED_COLUMNS = [
  COLUMNS.POINT,
  COLUMNS.DIRECTION,
  COLUMNS.OAC,
  COLUMNS.CONSTRAINT_FACTOR,
  COLUMNS.PRIORITY,
];

/**
 * @type {Record<SegmentConstraintSpreadsheetColumns, string>}
 */
const COLUMNS_LABELS = {
  [COLUMNS.POINT]: 'Point',
  [COLUMNS.LOC_NAME]: 'Location',
  [COLUMNS.DIRECTION]: 'Direction',
  [COLUMNS.DESCRIPTION]: 'Description',
  [COLUMNS.OAC]: 'Volume',
  [COLUMNS.CONSTRAINT_FACTOR]: 'Constraint Factor',
  [COLUMNS.PRIORITY]: 'Priority',
};

/**
 * @typedef {{
 *  isOpen: boolean;
 *  onToggle: () => void;
 *  onImport: (segmentConstraint: import('../scenarioSlice').SegmentConstraint[]) => void;
 *  onOverwrite: (segmentConstraint: import('../scenarioSlice').SegmentConstraint[]) => void;
 * }} SegmentConstraintSpreadsheetProps
 */

/**
 *
 * @type {React.FC<SegmentConstraintSpreadsheetProps>}
 */
export const SegmentConstraintSpreadsheet = ({
  isOpen,
  onToggle,
  onImport,
  onOverwrite,
}) => {
  const allPoints = useSelector(selectAllPoints);
  const allEdges = useSelector(selectAllEdges);

  const segmentConstraint = useSelector(selectSegmentConstraints);

  /** @type {React.Ref<import('../helpers/Spreadsheet').SpreadsheetRef<import('react-spreadsheet').CellBase>>} */
  const sheetRef = React.useRef(null);

  const parseDirection = useCallback(
    /**
     * @param {string} direction
     * @returns {import('../scenarioSlice').SegmentFlowDirection}
     */
    (direction) => {
      const upper = direction.toUpperCase();
      if (['FORWARDHAUL', 'FH', 'F', 'FORWARD'].includes(upper)) {
        return 'FORWARDHAUL';
      }
      if (['BACKHAUL', 'BH', 'B', 'BACK'].includes(upper)) {
        return 'BACKHAUL';
      }
      return null;
    },
    [],
  );

  const parseData = useCallback(
    /**
     *
     * @param {import('react-spreadsheet').Matrix<import('react-spreadsheet').CellBase>} data
     * @returns {import('../scenarioSlice').SegmentConstraint[]}
     */
    (data) => {
      /** @type {import('../scenarioSlice').SegmentConstraint[]} */
      const parsedSegmentConstraints = data
        .filter((row) => {
          return REQUIRED_COLUMNS.every((col) => row[col].value !== '');
        })
        .flatMap((row) => {
          const point = allPoints[row[COLUMNS.POINT].value];
          if (!point) {
            return [];
          }
          const direction = parseDirection(row[COLUMNS.DIRECTION].value);
          const edges = Object.values(allEdges).filter((edge) => {
            return (
              (direction === 'FORWARDHAUL' && edge.end_node === point.node) ||
              (direction === 'BACKHAUL' && edge.start_node === point.node)
            );
          });
          return edges.map((edge) => ({
            edge: edge.id,
            flow_direction: direction,
            description: row[COLUMNS.DESCRIPTION].value,
            max_volume: row[COLUMNS.OAC].value,
            constraint_factor:
              Number(row[COLUMNS.CONSTRAINT_FACTOR].value || 0) / 100,
            cuts_at_priority: row[COLUMNS.PRIORITY].value,
          }));
        });
      return parsedSegmentConstraints;
    },
    [],
  );

  /**
   * @type {(data: import('react-spreadsheet').Matrix<import('react-spreadsheet').CellBase>) => void}
   */
  const onChangeSpreadsheetDebounce = useDebouncedCallback(
    (data) => {
      const newData = data.map((row) => {
        let newRow = [...row];
        if (
          row[COLUMNS.POINT].value !== '' &&
          !isNaN(Number(row[COLUMNS.POINT].value))
        ) {
          newRow[COLUMNS.LOC_NAME].value =
            allPoints[row[COLUMNS.POINT].value]?.name ?? '';
        }
        return newRow;
      });
      sheetRef.current?.setData(newData);
    },
    1000,
    [allPoints],
  );

  /**
   *
   * @param {import('react-spreadsheet').Matrix<import('react-spreadsheet').CellBase>} data
   */
  const confirmImport = (data) => {
    onImport(parseData(data));
  };

  /**
   *
   * @param {import('react-spreadsheet').Matrix<import('react-spreadsheet').CellBase>} data
   */
  const overwrite = (data) => {
    onOverwrite(parseData(data));
  };

  return (
    <SpreadsheetModal
      ref={sheetRef}
      isOpen={isOpen}
      toggle={onToggle}
      title="Import Segment Constraints"
      subTitle={<p className="text-muted">Required fields: *</p>}
      data={[]}
      readonlyColumns={[COLUMNS.LOC_NAME]}
      sheetProps={{
        onChange: onChangeSpreadsheetDebounce,
        columnLabels: Object.keys(COLUMNS_LABELS).map((col) => {
          return `${COLUMNS_LABELS[col]}${
            REQUIRED_COLUMNS.includes(Number(col)) ? ' *' : ''
          }`;
        }),
      }}
      fileName="segmentConstraints.xlsx"
      sheetName="Segment Constraints"
    >
      <button
        className="btn btn-danger"
        onClick={() => {
          sheetRef.current?.setData([]);
        }}
      >
        Clear
      </button>
      <button
        className="btn btn-primary"
        onClick={() => {
          const scenarioSegmentConstraints = segmentConstraint.map(
            (constraint) => [
              { value: constraint.edge },
              { value: '' },
              { value: constraint.flow_direction },
              { value: constraint.description },
              { value: constraint.max_volume },
              { value: constraint.constraint_factor },
              { value: constraint.cuts_at_priority },
            ],
          );
          const newSegmentConstraints = [
            ...sheetRef.current
              .getData()
              .filter((row) => row[COLUMNS.POINT].value !== ''),
            ...scenarioSegmentConstraints,
          ];
          sheetRef.current?.setData(newSegmentConstraints);
        }}
      >
        Pull From Scenario
      </button>
      <button
        className="btn btn-primary"
        onClick={() => {
          overwrite(sheetRef.current.getData());
          sheetRef.current.clear();
        }}
      >
        Overwrite
      </button>
      <button
        className="btn btn-success"
        onClick={() => {
          confirmImport(sheetRef.current.getData());
          sheetRef.current.clear();
        }}
      >
        Confirm Import
      </button>
    </SpreadsheetModal>
  );
};
