import React, { createContext, useState, useContext, useEffect } from 'react';
import { Collapse } from 'reactstrap';
import {
  ChevronUpIcon,
  ChevronDownIcon,
  MaximizeIcon,
  MinimizeIcon,
} from '@iconicicons/react';

/**
 * @typedef {{
 *  components: {[key: string]: React.ReactNode},
 *  fullScreen: boolean,
 *  isOpen: boolean,
 *  activeTab: string,
 *  addComponents: (objectsToAdd: {[key: string]: React.ReactNode}) => void,
 *  removeComponents: (keysToRemove: string[]) => void,
 *  setFullScreen: React.Dispatch<React.SetStateAction<boolean>>,
 *  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
 *  setActiveTab: React.Dispatch<React.SetStateAction<string>>,
 * }} BelowMapContextType
 */

/**
 * @type {React.Context<BelowMapContextType>}
 */
export const BelowMapContext = createContext();

function BelowMapProvider(props) {
  const { children } = props;
  const [components, setComponents] = useState({});
  const [fullScreen, setFullScreen] = useState(false);
  const [isOpen, _setOpen] = useState(true);
  const [activeTab, setActiveTab] = useState('0');

  // this could be a reducer
  // not sure if it's better to make an object with unique keys or an array of objects
  // {markets: <Markets>, trades: <Trades>} vs [{name:markets, component: <Markets>}, {name: trades, component: <Trades>}]
  // array might be better if order of tabs starts to change randomly

  /**
   *
   * @param {[key: string]: React.ReactNode} objectsToAdd
   */
  const addComponents = (objectsToAdd) => {
    setComponents((components) => {
      const newComponents = { ...components, ...objectsToAdd };
      const activeComponent = React.Children.toArray(
        Object.values(components),
      ).some((component) => component.props?.activeTab === true)
        ? null
        : React.Children.toArray(Object.values(objectsToAdd)).find(
            (component) =>
              component.props?.activeTab === true &&
              !component[component.props?.contextKey],
          )?.props?.contextKey;

      if (activeComponent) {
        setActiveTab(activeComponent);
      } else {
        if (
          !Object.keys(newComponents).includes(activeTab) &&
          Object.keys(newComponents).length > 0
        ) {
          setActiveTab(Object.keys(newComponents)[0]);
        }
      }
      return { ...newComponents };
    });
    // console.log(JSON.stringify(components))
  };

  const removeComponents = (keysToRemove) => {
    setComponents((components) => {
      // const {[keyToRemove]:_, ...remainingComponents} = components
      keysToRemove.forEach((key) => delete components[key]);
      // have to spread the components so it's a new object like redux
      const newComponents = { ...components };
      if (
        keysToRemove.includes(activeTab) &&
        Object.keys(newComponents).length > 0
      ) {
        setActiveTab(Object.keys(newComponents)[0]);
      }
      return newComponents;
    });
  };

  const setOpen = (value) => {
    if (typeof value === 'function') {
      value = _setOpen(value);
      return;
    }
    if (Boolean(value) === Boolean(isOpen)) {
      return;
    }
    _setOpen(Boolean(value));
  };

  // const value = { components, addComponents, removeComponents };

  return (
    <BelowMapContext.Provider
      value={{
        components,
        fullScreen,
        isOpen,
        activeTab,
        addComponents,
        removeComponents,
        setFullScreen,
        setOpen,
        setActiveTab,
      }}
    >
      {children}
    </BelowMapContext.Provider>
  );
}

const useBelowMap = () => {
  const context = useContext(BelowMapContext);
  if (context === undefined) {
    throw new Error('BelowMap must be used within a BelowMapProvider');
  }
  return context;
};

/**
 *
 * @type {React.FC<React.PropsWithChildren>}
 */
const BelowMapSetter = (props) => {
  // add components as children with unique keys
  // prop for tab title - or compose it using a tab component?
  // prop that changes to know when to update? or will that be handled automatically
  const { children } = props;
  const { addComponents, removeComponents } = useBelowMap();
  const childrenKeys = React.Children.toArray(children).map(
    (child) => child?.props?.contextKey ?? child.toString(),
  );

  useEffect(() => {
    if (children) {
      const childrenToObject = React.Children.toArray(children).reduce(
        (acc, child) => {
          acc[child.props.contextKey] = child;
          return acc;
        },
        {},
      );
      addComponents(childrenToObject);
      // addComponent({capacityComponents: <MapFlows capacityComponents={filteredCapacityComponents} />})
    }
  }, [children]);

  useEffect(() => {
    return () => {
      removeComponents(childrenKeys);
    };
  }, [childrenKeys.join(', ')]);

  return null; // should it be a hook if it returns nothing? Only thing is I want it to behave like a normal component otherwise
};

function BelowMapDisplay() {
  const { components, fullScreen, setFullScreen } = useBelowMap();
  const { activeTab, isOpen, setActiveTab, setOpen } = useBelowMap();

  /**
   *
   * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} evt
   * @param {string} key
   */
  const setSelected = (evt, key) => {
    evt.stopPropagation();
    if (!isOpen) {
      setOpen(true);
    }
    // if it's already selected, close it?  Not sure if that's expected though
    if (isOpen && activeTab === key) {
      setOpen(false);
    } else {
      setActiveTab(key);
    }
  };

  if (Object.keys(components).length === 0) {
    return null;
  }

  return (
    <div
      style={fullScreen ? { height: '100%' } : { maxHeight: '50%' }}
      className="d-flex flex-grow-0 flex-column border"
    >
      <ul className="d-flex nav nav-tabs">
        {Object.keys(components)
          .sort((k1, k2) => {
            let order1 = components[k1]?.props?.tabOrder;
            let order2 = components[k2]?.props?.tabOrder;

            if (order1 === undefined && order2 === undefined) {
              return 0;
            }

            if (order1 === undefined) {
              if (order2 < 0) {
                return -1;
              }

              return 1;
            }

            if (order2 === undefined) {
              if (order1 < 0) {
                return 1;
              }

              return -1;
            }

            // if order is negative, put it at the end
            order1 = order1 < 0 ? Number.MAX_SAFE_INTEGER + order1 : order1;
            order2 = order2 < 0 ? Number.MAX_SAFE_INTEGER + order2 : order2;

            return order1 - order2;
          })
          .map((key) => {
            const component = components[key];

            return (
              <li className="nav-item" key={key}>
                <button
                  className={`nav-link ${key === activeTab ? 'active' : ''}`}
                  onClick={(evt) => setSelected(evt, key)}
                >
                  {component.props.tabName || key}
                </button>
              </li>
            );
          })}
        <button
          onClick={() => setOpen((p) => !p)}
          className="btn btn-outline-default"
        >
          {isOpen ? <ChevronDownIcon /> : <ChevronUpIcon />}{' '}
        </button>
        <button
          onClick={() => setFullScreen((p) => !p)}
          className="btn btn-outline-default"
          style={{ marginLeft: 'auto' }}
        >
          {fullScreen ? <MinimizeIcon /> : <MaximizeIcon />}{' '}
        </button>
      </ul>
      <Collapse
        id="below-map-context"
        isOpen={isOpen || fullScreen}
        className="overflow-auto h-100"
      >
        {/* switched to show and hide instead of rendering selected tab so it doesn't affect the data when tabs are changed */}
        {Object.keys(components).map((key) => (
          <div key={key} className={key === activeTab ? 'h-100' : 'd-none'}>
            {components[key]}
          </div>
        ))}
      </Collapse>
    </div>
  );
}

export { BelowMapProvider, BelowMapDisplay, useBelowMap, BelowMapSetter };
