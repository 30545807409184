import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectSegmentConstraints } from '../scenarios/scenarioSlice';
import { numberFormatter } from '../helpers/formatters';
import { selectPlotterCapacities } from '../plotter/plotterSlice';
import { titleize } from '../utils/stringUtils';
import { EdgeFlowTable } from './EdgeFlow';

/**
 * @typedef {{
 *  edge: import('../services/edgeApi').Edge,
 *  showScenarioInfo: boolean,
 *  showCapacities: boolean,
 * }} EdgeTooltipProps
 */

/**
 *
 * @param {EdgeTooltipProps} props
 * @returns
 */
export const EdgeTooltip = ({
  edge,
  showScenarioInfo,
  showCapacities,
  operations,
}) => {
  const segmentConstraints = useSelector(selectSegmentConstraints);
  const capacities = useSelector(selectPlotterCapacities);

  /**
   * @type {Object<string, import('../scenarios/scenarioSlice').SegmentConstraint[]>}
   */
  const constraintsOnEdges = useMemo(() => {
    if (!showScenarioInfo || segmentConstraints.length === 0) return {};
    return segmentConstraints.reduce((agg, constraint) => {
      if (!agg[constraint.edge]) {
        agg[constraint.edge] = [];
      }
      agg[constraint.edge].push(constraint);
      return agg;
    }, {});
  }, [segmentConstraints, showScenarioInfo]);

  /**
   * @type {import('../scenarios/scenarioSlice').SegmentConstraint[]}
   */
  const constraintsOnEdge = useMemo(() => {
    return constraintsOnEdges?.[edge?.id] ?? [];
  }, [edge, constraintsOnEdges]);

  /**
   * @type {Object<string, import('../plotter/plotterSlice').Capacity[]>}
   */
  const capacitiesOnEdges = useMemo(() => {
    if (!showCapacities || capacities.length === 0) return {};
    return capacities.reduce((agg, capacity) => {
      const record = capacity.best_available;
      if (!record) return agg;
      const edgeIds =
        capacity.flow_direction === 'FORWARDHAUL'
          ? [...record.with_edges]
          : [...record.against_edges];

      edgeIds.forEach((edgeId) => {
        if (!agg[edgeId]) {
          agg[edgeId] = [];
        }
        agg[edgeId].push(capacity);
      });
      return agg;
    }, {});
  }, [capacities, showCapacities]);

  /**
   * @type {import('../plotter/plotterSlice').Capacity[]}
   */
  const capacitiesOnEdge = useMemo(() => {
    return capacitiesOnEdges?.[edge?.id] ?? [];
  }, [edge, capacitiesOnEdges]);

  return (
    <div className="bg-dark p-2 px-3 border-dark text-light rounded">
      <div style={{ fontSize: 12 }}>#{edge?.id}</div>
      <div className="fs-6">
        {edge?.tsp_short_name ?? edge?.tsp_name}: {edge?.zone}
      </div>
      <div>
        {showScenarioInfo && (
          <>
            <table className="table table-dark">
              <thead>
                <tr>
                  <th className="text-center" colSpan="5">
                    Constraints
                  </th>
                </tr>
                <tr>
                  <th>Description</th>
                  <th>Priority</th>
                  <th>Direction</th>
                  <th>Source</th>
                  <th>OAC</th>
                </tr>
              </thead>
              <tbody>
                {constraintsOnEdge.length > 0 ? (
                  constraintsOnEdge.map((constraint) => {
                    return (
                      <tr key={constraint.id}>
                        <td>
                          {constraint.short_description ||
                            constraint.description}
                        </td>
                        <td>{constraint.cuts_at_priority}</td>
                        <td>{constraint.flow_direction}</td>
                        <td>
                          {titleize(
                            constraint.source_type?.split('_').join(' ') ?? '',
                          )}
                        </td>
                        <td>{numberFormatter(constraint.max_volume)}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="4" className="text-center">
                      <em>No constraints</em>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <EdgeFlowTable
              operations={operations}
              className="table table-dark table-sm"
            />
          </>
        )}
        {showCapacities && capacitiesOnEdge.length > 0 && (
          <table className="table table-dark">
            <thead>
              <tr>
                <th className="text-center" colSpan="8">
                  Flow
                </th>
              </tr>
              <tr>
                <th>GAs Date</th>
                <th>Cycle</th>
                <th>Capacity</th>
                <th>Scheduled Vol</th>
                <th>OAC</th>
                <th>Utilization</th>
                <th>IT?</th>
                <th>Direction</th>
              </tr>
            </thead>
            <tbody>
              {capacitiesOnEdge.map((capacity) => {
                return (
                  <tr key={capacity.id}>
                    <td>{capacity.best_available.gas_date}</td>
                    <td>{capacity.best_available.nomination_cycle}</td>
                    <td>
                      {numberFormatter(capacity.best_available.actual_capacity)}
                    </td>
                    <td>
                      {numberFormatter(
                        capacity.best_available.scheduled_volume,
                      )}
                    </td>
                    <td>{numberFormatter(capacity.best_available.oac_calc)}</td>
                    <td>
                      {numberFormatter(capacity.best_available.utilization)}
                    </td>
                    <td>{capacity.best_available.interruptible_flow}</td>
                    <td>{titleize(capacity.flow_direction)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
