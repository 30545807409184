import { useDispatch, useSelector } from 'react-redux';
import { useGetTSPsQuery, useLazyGetZonesQuery } from '../services/edgeApi';
import { useMemo, useState } from 'react';
import { selectAllPoints } from '../plotter/plotterSelectors';
import { EditFeedbackPointInput } from '../helpers/EditFeedbackInput';
import { ReduxPointSearch } from '../search/ReduxPointSearch';
import { scenarioActions } from './scenarioSlice';
import { EditableTable } from '../helpers/EditableTable';
import { ReusableModal } from '../modals/ReusableModal';
import { LoaderIcon } from 'react-hot-toast';

/**
 *
 * @typedef {{
 *  isEditing: boolean
 * }} ScenarioReceiptPointsProps
 *
 * @param {ScenarioReceiptPointsProps} props
 * @returns {React.FC<ScenarioReceiptPointsProps>}
 */
export const ScenarioReceiptPoints = ({ isEditing }) => {
  const dispatch = useDispatch();
  const [getZonesTrigger, { isFetching: isFetchingZones }] =
    useLazyGetZonesQuery();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [zones, setZones] = useState([]);
  const [filterTSP, setFilterTSP] = useState('');
  const [filterZone, setFilterZone] = useState('');

  const { data: tsps } = useGetTSPsQuery();
  const allPoints = useSelector(selectAllPoints);
  const receiptPoints = useSelector(
    (state) => state.scenario.data.settings.optimization_settings.receipt,
  );

  /** @type {import('../helpers/EditableTable').EditableTableColumn[]} */
  const tableColumns = useMemo(
    () => [
      {
        title: 'Point #',
        key: 'id',
        type: 'text',
        placeholder: 'Point #',
        renderInput: EditFeedbackPointInput,
        isEditable: false,
        informedOnCreation: true,
      },
      {
        title: 'Name',
        key: 'loc_name',
        type: 'text',
        placeholder: 'Name',
        isEditable: false,
        renderCell: ({ data }) => allPoints[data.id]?.name,
      },
      {
        title: 'Pipeline',
        key: 'tsp_name',
        type: 'text',
        isEditable: false,
        renderCell: ({ data }) => allPoints[data.id]?.tsp_name,
      },
      {
        title: 'Zone',
        key: 'zone_name',
        type: 'text',
        isEditable: false,
        renderCell: ({ data }) => allPoints[data.id]?.zone_name,
      },
    ],
    [allPoints],
  );

  return (
    <div>
      {isEditing && (
        <div className="row m-3">
          <div className="col-xl-3 col-md-4 col-sm-6">
            <ReduxPointSearch
              placeholder="Add Single Point"
              handler={(point) => {
                if (receiptPoints.some((p) => p.id === point.id)) {
                  return;
                }
                if (!allPoints[point.id]) {
                  return;
                }
                dispatch(
                  scenarioActions.setOptimizationSettings({
                    optimizationSettings: {
                      receipt: [
                        ...receiptPoints,
                        {
                          id: point.id,
                          loc_name: allPoints[point.id].loc_name,
                        },
                      ],
                    },
                  }),
                );
              }}
            />
          </div>
          <div className="col-auto">
            <button
              onClick={() => setIsModalOpen(true)}
              className="btn btn-success mx-1"
            >
              Add Multiple Points
            </button>
          </div>
        </div>
      )}
      <EditableTable
        columns={tableColumns}
        data={receiptPoints}
        isEditable
        isEditing={isEditing}
        actions={['DELETE']}
        emptyText="No receipt points added — All points will be considered"
        keyExtractor={(point) => point.id}
        onBulkRemove={(pointsIds) => {
          dispatch(
            scenarioActions.setOptimizationSettings({
              optimizationSettings: {
                receipt: receiptPoints.filter(
                  (point) => !pointsIds.includes(point.id.toString()),
                ),
              },
            }),
          );
        }}
        onDeleteItem={(pointId) => {
          dispatch(
            scenarioActions.setOptimizationSettings({
              optimizationSettings: {
                receipt: receiptPoints.filter(
                  (point) => point.id.toString() !== pointId.toString(),
                ),
              },
            }),
          );
        }}
      />
      <ReusableModal
        header={'Add Points From:'}
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setFilterTSP('');
          setFilterZone('');
        }}
      >
        <div className="row">
          <div className="col-12 my-1">
            <div className="input-group">
              <span className="input-group-text">Pipeline</span>
              <select
                value={filterTSP}
                className="form-select"
                onChange={async (evt) => {
                  setFilterTSP(evt.target.value);
                  const response = await getZonesTrigger({
                    tsp: evt.target.value,
                  });
                  if (response.error) return;
                  setZones(response.data);
                }}
              >
                <option disabled value="">
                  Select a pipeline
                </option>
                {tsps &&
                  Object.values(tsps).map((tsp) => (
                    <option value={tsp.id} key={tsp.id}>
                      {tsp.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>

          <div className="col-12 my-1">
            <div className="input-group">
              <span className="input-group-text">
                Zone {isFetchingZones && <LoaderIcon className="ms-1" />}
              </span>
              <select
                value={filterZone}
                disabled={isFetchingZones || filterTSP?.length <= 0}
                className="form-select"
                onChange={(evt) => {
                  setFilterZone(evt.target.value);
                }}
              >
                <option value="">All</option>
                {tsps &&
                  Object.values(zones).map((zone) => (
                    <option value={zone.id} key={zone.id}>
                      {zone.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>

          <div className="col-12 my-1">
            <button
              disabled={filterTSP?.length <= 0}
              className="btn btn-success"
              onClick={() => {
                setIsModalOpen(false);
                const points = Object.values(allPoints)
                  .filter((point) => {
                    const isMapped = point.node > 0;
                    /**
                     * This Array.some is not very efficient, it will make this filter O(n^2),
                     * but I'm hoping that this list will never be big enough for it to be a problem.
                     * If it does become a problem, we can do something like a different data structure.
                     */
                    if (receiptPoints.some((p) => p.id === point.id))
                      return false;
                    if (!filterTSP) return isMapped;
                    if (!filterZone)
                      return point.tsp?.toString() === filterTSP && isMapped;
                    return (
                      point.tsp?.toString() === filterTSP &&
                      point.zone_name === zones[filterZone]?.name &&
                      isMapped
                    );
                  })
                  .map((point) => ({ id: point.id, loc_name: point.loc_name }));
                setFilterTSP('');
                setFilterZone('');
                dispatch(
                  scenarioActions.setOptimizationSettings({
                    optimizationSettings: {
                      receipt: [...receiptPoints, ...points],
                    },
                  }),
                );
              }}
            >
              Add
            </button>
          </div>
        </div>
      </ReusableModal>
    </div>
  );
};
