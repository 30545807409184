import { useSelector } from 'react-redux';
import {
  selectDeletedPointConstraints,
  selectFlowDate,
  selectPointConstraints,
} from '../scenarios/scenarioSlice';
import { useEffect, useState } from 'react';
import { useLazyGetPointConstraintsQuery } from '../services/edgeApi';
import { useScenarioFunctions } from './useScenarioFunctions';
import { toIsoDate } from '../utils/stringUtils';

export const useScenarioPointConstraints = () => {
  const pointConstraints = useSelector(selectPointConstraints);
  const deletedPointConstraints = useSelector(selectDeletedPointConstraints);
  const flowDate = useSelector(selectFlowDate);

  const [constraintDate, setConstraintDate] = useState(new Date(flowDate));

  const [pointConstraintsTrigger, { isFetching, isLoading }] =
    useLazyGetPointConstraintsQuery();

  useEffect(() => {
    setConstraintDate(new Date(flowDate));
  }, [flowDate]);

  const {
    saveItem,
    append,
    overwrite,
    removeItem,
    bulkRemove,
    restoreItems,
    bulkUpdate,
  } = useScenarioFunctions('point_constraints');

  const refreshPointConstraints = async () => {
    try {
      /**
       * @type {import('../scenarios/scenarioSlice').PointConstraint[]}
       */
      const constraints = await pointConstraintsTrigger({
        source: 'PLATTS',
        date: toIsoDate(constraintDate),
      }).unwrap();

      const userConstraints = pointConstraints.filter(
        (constraint) => constraint.source_type === 'USER_DEFINED',
      );
      overwrite([...userConstraints, ...constraints]);
    } catch (e) {
      console.error(e);
    }
  };

  return {
    pointConstraints,
    deletedPointConstraints,
    constraintDate,
    isFetching,
    isLoading,
    refreshPointConstraints,
    setConstraintDate,
    removeItem,
    bulkRemove,
    restoreItems,
    saveItem,
    append,
    overwrite,
    bulkUpdate,
  };
};
