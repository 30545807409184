import { useState } from 'react';
import { DebouncedInput } from '../helpers/DebounceInput';
import { ReusableModal } from '../modals/ReusableModal';
import { useLazyGetPriceComponentsQuery } from '../services/edgeApi';

/**
 * @typedef {{
 *  isOpen: boolean;
 *  onClose: () => void;
 *  onSelect: (priceComponent: PriceComponent) => void;
 * }} PriceComponentSearchProps
 */

/**
 *
 * @type {React.FC<PriceComponentSearchProps>}
 */
export const PriceComponentSearch = ({ isOpen, onClose, onSelect }) => {
  const [priceComponentsTrigger, { isFetching }] =
    useLazyGetPriceComponentsQuery();
  const [priceComponents, setPriceComponents] = useState([]);

  return (
    <ReusableModal header="Point Search" isOpen={isOpen} onClose={onClose}>
      <DebouncedInput
        className="form-control"
        value={''}
        onChange={async (searchQuery) => {
          if (!searchQuery) return;
          const result = await priceComponentsTrigger({
            searchQuery,
          });
          if (result.isSuccess) {
            setPriceComponents(Object.values(result.data));
          }
        }}
      />

      {isFetching ? (
        <div>Loading...</div>
      ) : (
        <div className="d-grid gap-2 mt-2">
          {priceComponents.map((priceComponent) => (
            <div
              key={priceComponent.id}
              className="btn btn-outline-secondary"
              onClick={() => {
                onSelect(priceComponent);
              }}
            >
              <p>{priceComponent.description}</p>
            </div>
          ))}
        </div>
      )}
    </ReusableModal>
  );
};
