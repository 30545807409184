import { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { priceFormatter } from '../../helpers/formatters';
import { scenarioActions } from '../../scenarios/scenarioSlice';

const defaultTradeValues = {
  description: '',
  buy_sell: 'placeholder',
  volume: 0,
  price: 0,
  base_price: 0,
};

/**
 *
 * @param {{
 *  pointId?: string,
 * }} props
 * @returns
 */
export function PopupTrades({ pointId }) {
  const componentType = 'trades';
  const dispatch = useDispatch();
  const [isAdding, setIsAdding] = useState(false);

  /**
   * @type {import('../scenarios/scenarioSlice').ScenarioState}
   */
  const {
    data: { trades: allTrades },
    isEditing,
  } = useSelector((state) => state.scenario);
  const trades = allTrades.filter((trade) => trade.point == pointId);

  const [newTrade, setNewTrade] = useState({
    ...defaultTradeValues,
    point: pointId,
  });

  const resetNewTrade = () => {
    setNewTrade({ ...defaultTradeValues, point: pointId });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewTrade({ ...newTrade, [name]: value });
  };

  const handleSubmit = (e) => {
    e.view.L.DomEvent.stopPropagation(e);
    dispatch(
      scenarioActions.updateComponent({ componentType, component: newTrade }),
    );
    resetNewTrade();
    setIsAdding(false);
  };

  const handleCancel = (e) => {
    e.view.L.DomEvent.stopPropagation(e);
    resetNewTrade();
    setIsAdding(false);
  };

  const isValid = useMemo(() => {
    if (newTrade.buy_sell === 'placeholder') {
      return false;
    }
    return true;
  }, [newTrade.buy_sell]);

  if (isEditing !== true) {
    return null;
  }

  return (
    <div className="my-1" style={{ minWidth: 300 }}>
      {isAdding ? (
        <h5>New Trade</h5>
      ) : (
        <div className="d-flex justify-content-between">
          <h5>Trades</h5>
          <button
            className="btn btn-sm btn-success mb-1"
            onClick={(e) => {
              e.view.L.DomEvent.stopPropagation(e);
              setIsAdding(true);
            }}
          >
            Add Trade
          </button>
        </div>
      )}
      {isAdding ? (
        <>
          <div className="form-group my-2">
            <label
              className="form-label mt-2"
              htmlFor={`${pointId}-description`}
            >
              Description
            </label>
            <input
              className="form-control"
              id={`${pointId}-description`}
              name="description"
              type="text"
              value={newTrade.description}
              onChange={handleChange}
            />

            <label className="form-label mt-2" htmlFor={`${pointId}-buy-sell`}>
              Buy/Sell
            </label>
            <select
              className="form-control"
              id={`${pointId}-buy-sell`}
              name="buy_sell"
              value={newTrade.buy_sell}
              onChange={handleChange}
            >
              <option value="placeholder" disabled>
                Select
              </option>
              <option value="BUY">Buy</option>
              <option value="SELL">Sell</option>
              <option value="BOTH">Both</option>
            </select>

            <label className="form-label mt-2" htmlFor={`${pointId}-price`}>
              Price
            </label>
            <input
              className="form-control"
              id={`${pointId}-price`}
              name="price"
              type="number"
              value={newTrade.base_price}
              onChange={(e) => {
                handleChange(e);
                handleChange({
                  ...e,
                  target: { ...e.target, name: 'base_price' },
                });
              }}
            />

            <label className="form-label mt-2" htmlFor={`${pointId}-volume`}>
              Volume
            </label>
            <input
              className="form-control"
              id={`${pointId}-volume`}
              name="volume"
              type="number"
              value={newTrade.volume}
              onChange={handleChange}
            />
          </div>
          <div className="d-flex">
            <button className="btn btn-danger me-1 mb-1" onClick={handleCancel}>
              Cancel
            </button>
            <button
              className="btn btn-success mb-1"
              onClick={handleSubmit}
              disabled={isValid === false}
            >
              Save
            </button>
          </div>
        </>
      ) : (
        <table className="table">
          <thead>
            <tr>
              <th>Buy/Sell</th>
              <th>Price</th>
              <th>Volume</th>
            </tr>
          </thead>
          <tbody>
            {trades?.length > 0 ? (
              trades.map((trade) => (
                <tr key={trade.id}>
                  <td>{trade.buy_sell}</td>
                  <td>
                    {trade.price_component
                      ? trade.price
                      : priceFormatter.format(trade.base_price)}
                  </td>
                  <td>{trade.volume} dth</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3} className="text-center">
                  No Trades
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
}
