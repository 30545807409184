import React, { useCallback } from 'react';
import { useDebouncedCallback } from '../../helpers/hooks';
import { useSelector } from 'react-redux';
import { selectAllPoints } from '../../plotter/plotterSelectors';
import { SpreadsheetModal } from '../../helpers/SpreadsheetModal';
import { selectTrades } from '../scenarioSlice';

/**
 * @typedef {{
 *  POINT: 0,
 *  LOC_NAME: 1,
 *  DESCRIPTION: 2,
 *  BUY_SELL: 3,
 *  VOLUME: 4,
 *  PRICE: 5,
 * }} TradeSpreadsheetColumns
 */

/**
 *
 * Spreadsheet column indexers
 *
 * @type {TradeSpreadsheetColumns}
 */
const COLUMNS = {
  POINT: 0,
  LOC_NAME: 1,
  DESCRIPTION: 2,
  BUY_SELL: 3,
  VOLUME: 4,
  PRICE: 5,
};

const REQUIRED_COLUMNS = [
  COLUMNS.POINT,
  COLUMNS.BUY_SELL,
  COLUMNS.VOLUME,
  COLUMNS.PRICE,
];

/**
 * @type {Record<TradeSpreadsheetColumns, string>}
 */
const COLUMNS_LABELS = {
  [COLUMNS.POINT]: 'Point',
  [COLUMNS.LOC_NAME]: 'Location',
  [COLUMNS.DESCRIPTION]: 'Description',
  [COLUMNS.BUY_SELL]: 'Buy/Sell',
  [COLUMNS.VOLUME]: 'Volume',
  [COLUMNS.PRICE]: 'Price',
};

/**
 * @typedef {{
 *  isOpen: boolean;
 *  onToggle: () => void;
 *  onImport: (trades: import('../scenarioSlice').Trade[]) => void;
 *  onOverwrite: (trades: import('../scenarioSlice').Trade[]) => void;
 * }} TradeSpreadsheetProps
 */

/**
 *
 * @type {React.FC<TradeSpreadsheetProps>}
 */
export const TradeSpreadsheet = ({
  isOpen,
  onToggle,
  onImport,
  onOverwrite,
}) => {
  const allPoints = useSelector(selectAllPoints);

  const trades = useSelector(selectTrades);

  /** @type {React.Ref<import('../helpers/Spreadsheet').SpreadsheetRef<import('react-spreadsheet').CellBase>>} */
  const sheetRef = React.useRef(null);

  const parseBuySell = useCallback(
    /**
     *
     * @param {string} buySell
     * @returns {'BUY' | 'SELL'}
     */
    (buySell) => {
      const upper = buySell.toUpperCase();
      if (['BUY', 'LIFT', 'B'].includes(upper)) {
        return 'BUY';
      }
      if (['SELL', 'HIT', 'S'].includes(upper)) {
        return 'SELL';
      }
      return null;
    },
    [],
  );

  const parseData = useCallback(
    /**
     *
     * @param {import('react-spreadsheet').Matrix<import('react-spreadsheet').CellBase>} data
     * @returns {import('../scenarioSlice').Trade[]}
     */
    (data) => {
      /** @type {import('../scenarioSlice').Trade[]} */
      const parsedTrades = data
        .filter((row) => {
          return REQUIRED_COLUMNS.every((col) => row[col].value !== '');
        })
        .map((row) => {
          const price = parseFloat(row[COLUMNS.PRICE].value);
          return {
            point: parseInt(row[COLUMNS.POINT].value),
            description: row[COLUMNS.DESCRIPTION].value,
            buy_sell: parseBuySell(String(row[COLUMNS.BUY_SELL].value)),
            price,
            volume: parseFloat(row[COLUMNS.VOLUME].value),
            base_price: price,
            adder: 0,
          };
        });
      return parsedTrades;
    },
    [],
  );

  /**
   * @type {(data: import('react-spreadsheet').Matrix<import('react-spreadsheet').CellBase>) => void}
   */
  const onChangeSpreadsheetDebounce = useDebouncedCallback(
    (data) => {
      const newData = data.map((row) => {
        let newRow = [...row];
        if (
          row[COLUMNS.POINT].value !== '' &&
          !isNaN(Number(row[COLUMNS.POINT].value))
        ) {
          newRow[COLUMNS.LOC_NAME].value =
            allPoints[row[COLUMNS.POINT].value]?.name ?? '';
        }
        return newRow;
      });
      sheetRef.current?.setData(newData);
    },
    1000,
    [allPoints],
  );

  /**
   *
   * @param {import('react-spreadsheet').Matrix<import('react-spreadsheet').CellBase>} data
   */
  const confirmImport = (data) => {
    onImport(parseData(data));
  };

  /**
   *
   * @param {import('react-spreadsheet').Matrix<import('react-spreadsheet').CellBase>} data
   */
  const overwrite = (data) => {
    onOverwrite(parseData(data));
  };

  return (
    <SpreadsheetModal
      ref={sheetRef}
      isOpen={isOpen}
      toggle={onToggle}
      title="Import Trades"
      subTitle={<p className="text-muted">Required fields: *</p>}
      data={[]}
      readonlyColumns={[COLUMNS.LOC_NAME]}
      sheetProps={{
        onChange: onChangeSpreadsheetDebounce,
        columnLabels: Object.keys(COLUMNS_LABELS).map((col) => {
          return `${COLUMNS_LABELS[col]}${
            REQUIRED_COLUMNS.includes(Number(col)) ? ' *' : ''
          }`;
        }),
      }}
      fileName="trades.xlsx"
      sheetName="Trades"
    >
      <button
        className="btn btn-danger"
        onClick={() => {
          sheetRef.current?.setData([]);
        }}
      >
        Clear
      </button>
      <button
        className="btn btn-primary"
        onClick={() => {
          const scenarioTrades = trades.map((trade) => [
            { value: trade.point },
            { value: allPoints[trade.point].name },
            { value: trade.description },
            { value: trade.buy_sell },
            { value: trade.volume },
            { value: trade.price },
          ]);
          const newTrades = [
            ...sheetRef.current
              .getData()
              .filter((row) => row[COLUMNS.POINT].value !== ''),
            ...scenarioTrades,
          ];
          sheetRef.current?.setData(newTrades);
        }}
      >
        Pull From Scenario
      </button>
      <button
        className="btn btn-primary"
        onClick={() => {
          overwrite(sheetRef.current.getData());
          sheetRef.current.clear();
        }}
      >
        Overwrite
      </button>
      <button
        className="btn btn-success"
        onClick={() => {
          confirmImport(sheetRef.current.getData());
          sheetRef.current.clear();
        }}
      >
        Confirm Import
      </button>
    </SpreadsheetModal>
  );
};
