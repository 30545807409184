import { useSelector } from 'react-redux';
import React, { useState, useMemo } from 'react';
import { DateSelector } from '../../helpers/DateSelector';
import {
  selectEditState,
  selectOptimizationType,
  selectSimulatedSpread,
} from '../scenarioSlice';
import { toIsoDate } from '../../utils/stringUtils';
import * as plotterSelectors from '../../plotter/plotterSelectors';
import { MarketTable } from './MarketTable';
import { CreateMarket } from './CreateMarket';
import { MarketSpreadsheet } from './MarketsSpreadsheet';
import { TableRowsIcon } from '@iconicicons/react';
import { useScenarioMarkets } from '../../hooks/useScenarioMarkets';

/**
 * @typedef {{ }} MarketsProps
 * @param {MarketsProps} props
 * @returns {React.FC<MarketsProps>}
 */
export const Markets = () => {
  const {
    markets: _markets,
    deletedMarkets: _deletedMarkets,
    marketDate,
    isFetching,
    isLoading,
    refreshMarks,
    refreshMarkets,
    setMarketDate,
    saveItem,
    append,
    overwrite,
    removeItem,
    bulkRemove,
    restoreItems,
    bulkUpdate,
  } = useScenarioMarkets();

  const simulateSpread = useSelector(selectSimulatedSpread);

  /** @type {number} */
  const deliveryPoint = useSelector(
    (state) => state.scenario.data.settings.optimization_settings?.delivery,
  );
  const optimizationType = useSelector(selectOptimizationType);
  const isEditing = useSelector(selectEditState);
  const allPoints = useSelector(plotterSelectors.selectAllPoints);

  /**
   * @type {Array<import('../scenarioSlice').Market | { pipeline: string }>}
   */
  const markets = useMemo(
    () =>
      _markets.map((market) => {
        const point = allPoints[market.point];
        return {
          ...market,
          pipeline: point?.tsp_name ?? '',
        };
      }),
    [_markets],
  );

  const deletedMarkets = useMemo(
    () =>
      _deletedMarkets.map((market) => {
        const point = allPoints[market.point];
        return {
          ...market,
          pipeline: point?.tsp_name ?? '',
        };
      }),
    [_deletedMarkets],
  );

  const [isAdding, setIsAdding] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);

  /**
   * @type {import('../scenarios/scenarioSlice').Market[]}
   */
  const listedMarkets = useMemo(() => {
    if (
      ['ALL_PATHS_TO_POINT', 'BEST_PATHS_TO_POINT'].includes(optimizationType)
    ) {
      return markets.map((market) => {
        return market.point?.toString() === deliveryPoint?.toString()
          ? { ...market, buy_sell: 'SELL' }
          : { ...market, buy_sell: 'BUY' };
      });
    }
    return markets;
  }, [markets, optimizationType, deliveryPoint]);

  return (
    <>
      {isEditing && (
        <>
          <div
            className="row justify-content-between m-3 w-100"
            style={{ flexWrap: 'nowrap' }}
          >
            <div className="col-6 col-md-8 col-sm-12 d-flex gap-2 align-items-center">
              <DateSelector
                value={toIsoDate(marketDate)}
                onChange={(evt) => setMarketDate(evt.target.valueAsDate)}
                isLoading={isFetching || isLoading}
                onConfirm={refreshMarkets}
                buttonText="Get Markets"
              />
              <span role="button" className="text-primary">
                <TableRowsIcon onClick={() => setIsImportModalOpen(true)} />
              </span>
            </div>
            <div className="col-auto d-flex gap-2 align-items-center">
              <button
                className="btn btn-outline-primary"
                onClick={refreshMarks}
              >
                Get Marks
              </button>
              <button
                onClick={() => setIsAdding((prev) => !prev)}
                className="btn btn-success"
              >
                {isAdding ? 'Cancel' : 'Add Markets'}
              </button>
            </div>
          </div>
          {isAdding && (
            <div className="row m-3">
              <CreateMarket
                onCreate={(markets) => {
                  markets.forEach((market) => saveItem(market));
                  setIsAdding(false);
                }}
              />
            </div>
          )}
          {simulateSpread && (
            <div className="row justify-content-between m-3">
              <div className="col-12">
                <p className="text-center alert alert-primary">
                  The scenario is currently set to simulate spreads, it will
                  assume fixed prices for receipt/delivery locations. <br />
                  The prices are fixed in a way that transport costs are always
                  covered, resulting in all possible paths to a point being
                  found even though actual prices may not be profitable. <br />
                  In order to edit the prices, unmark the {'"'}
                  <i>Simulate Spread</i>
                  {'"'} checkbox.
                </p>
              </div>
            </div>
          )}
        </>
      )}
      <MarketTable
        markets={listedMarkets}
        deletedMarkets={deletedMarkets}
        isEditing={isEditing}
        onChange={saveItem}
        onDelete={removeItem}
        onBulkDelete={bulkRemove}
        onRestore={restoreItems}
        onBulkUpdate={bulkUpdate}
      />
      <MarketSpreadsheet
        isOpen={isImportModalOpen}
        onToggle={() => setIsImportModalOpen((p) => !p)}
        onImport={append}
        onOverwrite={overwrite}
      />
    </>
  );
};
