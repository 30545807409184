import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  selectAllEdges,
  selectAllNodes,
  selectAllPoints,
} from '../../plotter/plotterSelectors';
import { ReduxPointSearch } from '../../search/ReduxPointSearch';

/**
 * @typedef {{
 *  onCreate: (constraints: import('../scenarios/scenarioSlice').SegmentConstraint[]) => void;
 * }} CreateSegmentConstraintProps
 */

/**
 * @type {React.FC<CreateSegmentConstraintProps>}
 */
export const CreateSegmentConstraint = ({ onCreate }) => {
  const allPoints = useSelector(selectAllPoints);
  const allNodes = useSelector(selectAllNodes);
  const allEdges = useSelector(selectAllEdges);
  /**
   * @type {import('../types/types').State<import('../services/edgeApi').Point>}
   */
  const [point, setPoint] = React.useState(null);

  /**
   * @type {import('../types/types').State<"FORWARDHAUL" | "BACKHAUL" | "">}
   */
  const [direction, setDirection] = React.useState('');

  const [description, setDescription] = React.useState('');
  const [maxVolume, setMaxVolume] = React.useState(0);
  const [constraintFactor, setConstraintFactor] = React.useState(0);
  const [cutsAtPriority, setCutsAtPriority] = React.useState('');

  const isFormValid = useMemo(() => {
    return Boolean(
      point &&
        ['FORWARDHAUL', 'BACKHAUL'].includes(direction) &&
        description.length &&
        maxVolume &&
        maxVolume >= 0 &&
        constraintFactor &&
        constraintFactor >= 0 &&
        constraintFactor <= 100 &&
        ['PIP', 'SIP_PS', 'SIP_SP', 'SIP_SS', 'SOP', 'IT'].includes(
          cutsAtPriority,
        ),
    );
  }, [
    point,
    direction,
    description,
    maxVolume,
    constraintFactor,
    cutsAtPriority,
  ]);

  const edges = useMemo(() => {
    if (!point || !direction || !point.node) {
      return [];
    }
    const node = allNodes[point.node];
    if (!node) {
      return [];
    }
    const edgesIds =
      direction === 'BACKHAUL' ? node.starts_edges : node.ends_edges;

    return edgesIds.map((edgeId) => allEdges[edgeId]);
  }, [allPoints, allEdges, allNodes, point, direction]);

  const edgeNames = useMemo(() => {
    return edges.map(
      /**
       *
       * @param {import('../services/edgeApi').Edge} edge
       * @returns {[number, string]}
       */
      (edge) => {
        const start = allPoints[edge.start_points[0]]?.name;
        const end = allPoints[edge.end_points[0]]?.name;
        if (!start || !end) {
          return [edge.id, `Unknown edge (points missing)`];
        }
        return [
          edge.id,
          direction === 'FORWARDHAUL'
            ? `From ${start} to ${end}`
            : `From ${end} to ${start}`,
        ];
      },
    );
  }, [edges, allNodes, allPoints]);

  return (
    <div className="mt-3">
      <h4>New Segment Constraint</h4>
      <form className="form">
        <div className="row">
          <div className="col-6 col-xs-12">
            <div className="input-group">
              <label htmlFor="segment" className="input-group-text">
                Point
              </label>
              <ReduxPointSearch
                value={point?.name ?? ''}
                placeholder=""
                type="text"
                id="segment"
                className="form-control"
                handler={({ id: pointId }) => {
                  setPoint(allPoints[pointId] ?? null);
                }}
              />
              <select
                value={direction}
                onChange={(evt) => setDirection(evt.target.value)}
                className="form-select"
                style={{
                  maxWidth: '170px',
                }}
              >
                <option value="" disabled>
                  Select Direction
                </option>
                <option value="FORWARDHAUL">Forwardhaul</option>
                <option value="BACKHAUL">Backhaul</option>
              </select>
            </div>
            <div className="input-group mt-2">
              <textarea
                value={description}
                onChange={(evt) => setDescription(evt.target.value)}
                type="text"
                className="form-control"
                placeholder="Description"
              />
            </div>
            <div className="input-group mt-2">
              <label htmlFor="maxVolume" className="input-group-text">
                Volume
              </label>
              <input
                id="maxVolume"
                value={maxVolume}
                onChange={(evt) => setMaxVolume(evt.target.value)}
                type="number"
                className="form-control"
                placeholder="Max Volume"
              />
              <label htmlFor="constraintFactor" className="input-group-text">
                Constraint Factor (%)
              </label>
              <input
                value={constraintFactor}
                onChange={(evt) => setConstraintFactor(evt.target.value)}
                type="number"
                min={0}
                max={100}
                className="form-control"
                placeholder="Constraint Factor"
              />
              <select
                className="form-select"
                value={cutsAtPriority}
                onChange={(evt) => setCutsAtPriority(evt.target.value)}
              >
                <option value="" disabled>
                  Priority
                </option>
                <option value="PIP">PIP</option>
                <option value="SIP PS">SIP PS</option>
                <option value="SIP PS">SIP SP</option>
                <option value="SIP PS">SIP SS</option>
                <option value="SOP">SOP</option>
                <option value="IT">IT</option>
              </select>
            </div>
            <div className="mt-2">
              <button
                className="btn w-100 btn-success"
                disabled={isFormValid === false}
                type="button"
                onClick={() =>
                  onCreate(
                    edges.map((edge) => {
                      return {
                        id: null,
                        edge: edge.id,
                        flow_direction: direction,
                        description,
                        max_volume: maxVolume,
                        constraint_factor: constraintFactor / 100,
                        cuts_at_priority: cutsAtPriority,
                        source_type: 'USER_DEFINED',
                      };
                    }),
                  )
                }
              >
                Create
              </button>
            </div>
          </div>
          <div className="col">
            <h4>Edges</h4>
            {edgeNames?.map(([edgeId, edgeName]) => (
              <p key={edgeName}>
                <b>#{edgeId}:</b> {edgeName}
              </p>
            ))}
          </div>
        </div>
      </form>
    </div>
  );
};
