import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectAllPoints } from '../../plotter/plotterSelectors';
import { ReduxPointSearch } from '../../search/ReduxPointSearch';
import { PriceComponentSearch } from '../../search/PriceComponentSearch';
import { priceFormatter } from '../../helpers/formatters';

/**
 * @typedef {{
 *  onCreate: (trades: import('../scenarioSlice').Trade[]) => void;
 * }} CreateTradeProps
 */

/**
 * @type {React.FC<CreateTradeProps>}
 */
export const CreateTrade = ({ onCreate }) => {
  const allPoints = useSelector(selectAllPoints);

  /**
   * @type {import('../types/types').State<import('../services/edgeApi').Point>}
   */
  const [point, setPoint] = React.useState(null);

  const [description, setDescription] = React.useState('');

  /**
   * @type {import('../types/types').State<"BUY" | "SELL" | "">}
   */
  const [buySell, setBuySell] = React.useState('');
  const [volume, setMaxVolume] = React.useState(0);
  const [price, setPrice] = React.useState(0);
  const [adder, setAdder] = React.useState(0);
  const [priceComponent, setPriceComponent] = React.useState('');
  const [priceComponentSearchOpen, setPriceComponentSearchOpen] =
    React.useState(false);

  /**
   * @type {import('../types/types').State<"FIXED" | "VARIABLE">}
   */
  const [priceType, setPriceType] = React.useState('FIXED');

  const openPriceComponentSearch = () => {
    setPriceComponentSearchOpen(true);
  };

  const closePriceComponentSearch = () => {
    setPriceComponentSearchOpen(false);
  };

  const isFormValid = useMemo(() => {
    return Boolean(
      point &&
        ['BUY', 'SELL'].includes(buySell) &&
        description.length &&
        volume &&
        volume >= 0 &&
        ((priceType === 'FIXED' && price >= 0) ||
          (priceType === 'VARIABLE' &&
            adder !== null &&
            adder !== undefined &&
            priceComponent)),
    );
  }, [point, buySell, description, volume, price]);

  return (
    <div className="mt-3">
      <h4>New Trade</h4>
      <form className="form">
        <div className="row">
          <div className="col-6 col-xs-12">
            <div className="input-group">
              <label htmlFor="location" className="input-group-text">
                Point
              </label>
              <ReduxPointSearch
                value={point?.name ?? ''}
                placeholder=""
                type="text"
                id="location"
                className="form-control"
                handler={({ id: pointId }) => {
                  setPoint(allPoints[pointId] ?? null);
                }}
              />
              <select
                value={buySell}
                onChange={(evt) => setBuySell(evt.target.value)}
                className="form-select"
                style={{
                  maxWidth: '170px',
                }}
              >
                <option value="" disabled>
                  Select Type
                </option>
                <option value="BUY">Buy</option>
                <option value="SELL">Sell</option>
              </select>
            </div>
            <div className="input-group mt-2">
              <textarea
                value={description}
                onChange={(evt) => setDescription(evt.target.value)}
                type="text"
                className="form-control"
                placeholder="Description"
              />
            </div>
            <div className="input-group mt-2">
              <label
                htmlFor="max-volume-form-input"
                className="input-group-text"
              >
                Volume
              </label>
              <input
                id="max-volume-form-input"
                value={volume}
                onChange={(evt) => setMaxVolume(evt.target.value)}
                type="number"
                className="form-control"
                placeholder="Max Volume"
              />
            </div>
            <div className="mt-2">
              <button
                className="btn w-100 btn-success"
                disabled={isFormValid === false}
                type="button"
                onClick={() =>
                  onCreate([
                    {
                      id: null,
                      point: point?.id,
                      buy_sell: buySell,
                      description,
                      volume: volume,
                      price: priceType === 'FIXED' ? price : adder,
                      base_price: priceType === 'FIXED' ? price : 0,
                      adder: priceType === 'VARIABLE' ? adder : 0,
                      price_component:
                        priceType === 'VARIABLE' ? priceComponent?.id : null,
                      price_explanation:
                        priceType === 'VARIABLE'
                          ? `${priceComponent.description} ${
                              adder < 0 ? '-' : '+'
                            } ${priceFormatter.format(Math.abs(adder))}`
                          : `Fixed Price: ${priceFormatter.format(price)}`,
                    },
                  ])
                }
              >
                Create
              </button>
            </div>
          </div>
          <div className="col">
            <h4>Price</h4>
            <div className="input-group">
              <label htmlFor="price-type-input" className="input-group-text">
                Price Type
              </label>
              <select
                id="price-type-input"
                className="form-select"
                value={priceType}
                onChange={(evt) => setPriceType(evt.target.value)}
              >
                <option value="FIXED">Fixed</option>
                <option value="VARIABLE">Variable</option>
              </select>
            </div>
            <div className="input-group mt-2">
              {priceType === 'FIXED' && (
                <>
                  <label
                    htmlFor="price-form-input"
                    className="input-group-text"
                  >
                    Price
                  </label>
                  <input
                    id="price-form-input"
                    value={price}
                    onChange={(evt) => setPrice(evt.target.value)}
                    type="number"
                    className="form-control"
                    placeholder="Constraint Factor"
                  />
                </>
              )}
              {priceType === 'VARIABLE' && (
                <>
                  <label
                    htmlFor="adder-form-input"
                    className="input-group-text"
                  >
                    Adder
                  </label>
                  <input
                    id="adder-form-input"
                    value={adder}
                    onChange={(evt) => setAdder(evt.target.value)}
                    type="number"
                    className="form-control"
                    placeholder="Constraint Factor"
                  />
                  <label
                    htmlFor="price-component-form-input"
                    className="input-group-text"
                  >
                    Reference Price
                  </label>
                  <input
                    readOnly
                    type="text"
                    value={priceComponent?.description ?? ''}
                    className="form-control"
                    onClick={openPriceComponentSearch}
                  />
                  <PriceComponentSearch
                    isOpen={priceComponentSearchOpen}
                    onClose={closePriceComponentSearch}
                    onSelect={(component) => {
                      setPriceComponent(component);
                      closePriceComponentSearch();
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
