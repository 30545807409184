import { useSelector } from 'react-redux';
import {
  selectDeletedTrades,
  selectFlowDate,
  selectPriceSource,
  selectTrades,
} from '../scenarios/scenarioSlice';
import { useEffect, useMemo, useState } from 'react';
import { useLazyGetBaseloadQuery } from '../services/edgeApi';
import { useScenarioFunctions } from './useScenarioFunctions';
import { toIsoDate } from '../utils/stringUtils';

export const useScenarioTrades = () => {
  const flowDate = useSelector(selectFlowDate);
  const trades = useSelector(selectTrades);
  const deletedTrades = useSelector(selectDeletedTrades);
  const priceSource = useSelector(selectPriceSource);

  const [tradesDate, setTradesDate] = useState(new Date(flowDate));

  const [baseloadTrigger, { isFetching, isLoading }] =
    useLazyGetBaseloadQuery();

  const {
    saveItem,
    append,
    overwrite,
    removeItem,
    bulkRemove,
    restoreItems,
    bulkUpdate,
  } = useScenarioFunctions('trades');

  useEffect(() => {
    setTradesDate(new Date(flowDate));
  }, [flowDate]);

  const volumeDifference = useMemo(() => {
    return trades.reduce((acc, trade) => {
      if (trade.buy_sell === 'BUY') {
        return acc + Number(trade.volume);
      }
      if (trade.buy_sell === 'SELL') {
        return acc - Number(trade.volume);
      }
      return acc;
    }, 0);
  }, [trades]);

  const refreshBaseload = async () => {
    try {
      const trades = await baseloadTrigger({
        gasDate: toIsoDate(tradesDate),
        priceSource: priceSource,
      }).unwrap();
      overwrite(Object.values(trades));
    } catch (e) {
      console.error(e);
    }
  };

  return {
    trades,
    deletedTrades,
    volumeDifference,
    tradesDate,
    isFetching,
    isLoading,
    refreshBaseload,
    setTradesDate,
    saveItem,
    append,
    overwrite,
    removeItem,
    bulkRemove,
    restoreItems,
    bulkUpdate,
  };
};
