import { useSelector } from 'react-redux';
import {
  selectDeletedMarkets,
  selectFlowDate,
  selectMarkets,
  selectPriceSource,
  selectPriceType,
  selectStrip,
} from '../scenarios/scenarioSlice';
import { useCallback, useEffect, useState } from 'react';
import { useLazyGetPricesQuery } from '../services/edgeApi';
import { useScenarioFunctions } from './useScenarioFunctions';
import { toIsoDate } from '../utils/stringUtils';

export const useScenarioMarkets = () => {
  const flowDate = useSelector(selectFlowDate);
  const markets = useSelector(selectMarkets);
  const deletedMarkets = useSelector(selectDeletedMarkets);
  const priceSource = useSelector(selectPriceSource);
  const priceType = useSelector(selectPriceType);
  const strip = useSelector(selectStrip);

  const [marketDate, setMarketDate] = useState(new Date(flowDate));
  const [pricesTrigger, { isFetching, isLoading }] = useLazyGetPricesQuery();

  const {
    saveItem,
    append,
    overwrite,
    removeItem,
    bulkRemove,
    restoreItems,
    bulkUpdate,
  } = useScenarioFunctions('markets');

  useEffect(() => {
    setMarketDate(new Date(flowDate));
  }, [flowDate]);

  const refreshMarks = useCallback(async () => {
    try {
      /** @type {import('../scenarios/scenarioSlice').Market[]} */
      const marketPrices = await pricesTrigger({
        date: toIsoDate(marketDate),
        priceSource: priceSource,
        endpoint: 'mtm_marks/',
        bidAsk: priceType === 'BID_ASK',
      }).unwrap();

      const scenarioMarkets = markets.filter(
        (market) => market.price_source !== 'MARK_TO_MARKET',
      );
      const marks = marketPrices.filter((market) => market.base_price !== null);

      overwrite([...marks, ...scenarioMarkets]);
    } catch (e) {
      console.error(e);
    }
  }, [marketDate, priceSource, priceType, markets, pricesTrigger, overwrite]);

  const refreshMarkets = useCallback(async () => {
    try {
      /** @type {import('../scenarios/scenarioSlice').Market[]} */
      const marketPrices = await pricesTrigger({
        date: toIsoDate(marketDate),
        priceSource: priceSource,
        strip: strip,
        bidAsk: priceType === 'BID_ASK',
      }).unwrap();

      const marks = markets.filter(
        (market) => market.price_source === 'MARK_TO_MARKET',
      );

      const userMarkets = markets.filter(
        (market) => market.price_source === 'USER_DEFINED',
      );

      overwrite([
        ...userMarkets,
        ...marketPrices.filter((market) => market.base_price !== null),
        ...marks,
      ]);
    } catch (e) {
      console.error(e);
    }
  }, [
    marketDate,
    priceSource,
    priceType,
    strip,
    markets,
    pricesTrigger,
    overwrite,
  ]);

  return {
    markets,
    deletedMarkets,
    marketDate,
    isFetching,
    isLoading,
    refreshMarks,
    refreshMarkets,
    setMarketDate,
    saveItem,
    append,
    overwrite,
    removeItem,
    bulkRemove,
    restoreItems,
    bulkUpdate,
  };
};
