import React, { useState } from 'react';
import { PriceComponentSearch } from '../search/PriceComponentSearch';
import { DebouncedInput } from './DebounceInput';

/**
 * @typedef {{
 *  price: import('../scenarios/scenarioSlice').Price;
 *  onChange: (price: import('../scenarios/scenarioSlice').Price, patch: Partial<import('../scenarios/scenarioSlice').Price>) => void;
 * }} PriceInputProps
 */

/**
 *
 * @type {React.FC<PriceInputProps>}
 */
export const PriceInput = ({ price, onChange }) => {
  /**
   * @type {import("../types/types").State<'FIXED' | 'VARIABLE'>}
   */
  const [priceType, setPriceType] = useState(
    price.price_component === null || price.price_component === undefined
      ? 'FIXED'
      : 'VARIABLE',
  );
  const [priceComponentSearchOpen, setPriceComponentSearchOpen] =
    useState(false);

  return (
    <>
      {priceType === 'VARIABLE' && (
        <div className="mb-1">
          <input
            readOnly
            type="text"
            placeholder="Select Reference Price"
            value={price.price_component_description ?? ''}
            className="form-control form-control-sm"
            onClick={() => {
              setPriceComponentSearchOpen(true);
            }}
          />
          <PriceComponentSearch
            isOpen={priceComponentSearchOpen}
            onClose={() => {
              setPriceComponentSearchOpen(false);
            }}
            onSelect={(component) => {
              onChange(price, {
                price_component: component.id,
                price_component_description: component.description,
              });
              setPriceComponentSearchOpen(false);
            }}
          />
        </div>
      )}
      <div
        className="input-group input-group-sm"
        style={{ position: 'static' }}
      >
        <label className="input-group-text d-flex align-items-center">
          Variable{' '}
          <input
            type="checkbox"
            className="form-check-input form-check-input-sm ms-2"
            onChange={(e) => {
              setPriceType(e.target.checked ? 'VARIABLE' : 'FIXED');
              if (!e.target.checked) {
                onChange(price, {
                  price_component: null,
                  price_component_description: '',
                  adder: 0,
                });
              } else {
                onChange(price, {
                  base_price: 0,
                });
              }
            }}
            checked={priceType === 'VARIABLE'}
          />
        </label>
        {priceType === 'FIXED' ? (
          <DebouncedInput
            style={{ position: 'static' }}
            key="base-price-input"
            type="number"
            className="form-control form-control-sm"
            value={price.base_price}
            onChange={(value) =>
              onChange(price, {
                price: Number(value ?? 0),
                base_price: Number(value ?? 0),
              })
            }
            min={0}
          />
        ) : (
          <DebouncedInput
            style={{ position: 'static' }}
            key="adder-input"
            type="number"
            className="form-control form-control-sm"
            placeholder="Adder"
            value={price.adder}
            onChange={(value) =>
              onChange(price, {
                adder: Number(value ?? 0),
              })
            }
          />
        )}
      </div>
    </>
  );
};
