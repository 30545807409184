import { useSelector } from 'react-redux';
import React, { useMemo, useState } from 'react';
import { DateSelector } from '../../helpers/DateSelector';
import { selectEditState } from '../scenarioSlice';
import {
  selectAllEdges,
  selectAllNodes,
  selectAllPoints,
} from '../../plotter/plotterSelectors';
import { constraintToString, toIsoDate } from '../../utils/stringUtils';
import { SegmentConstraintTable } from './SegmentConstraintTable';
import { CreateSegmentConstraint } from './CreateSegmentConstraint';
import { TableRowsIcon } from '@iconicicons/react';
import { SegmentConstraintSpreadsheet } from './SegmentConstraintSpreadsheet';
import { useScenarioSegmentConstraints } from '../../hooks/useScenarioSegmentConstraints';

/**
 * @typedef {{contextKey: string}} SegmentConstraintsProps
 * @param {SegmentConstraintsProps} props
 * @returns {React.FC<SegmentConstraintsProps>}
 */
export const SegmentConstraints = ({ contextKey }) => {
  const allEdges = useSelector(selectAllEdges);
  const allNodes = useSelector(selectAllNodes);
  const allPoints = useSelector(selectAllPoints);
  const isEditing = useSelector(selectEditState);

  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  const {
    segmentConstraints: _segmentConstraints,
    deletedSegmentConstraints: _deletedSegmentConstraints,
    constraintDate,
    isFetching,
    isLoading,
    refreshSegmentConstraints,
    setConstraintDate,
    removeItem,
    bulkRemove,
    restoreItems,
    saveItem,
    append,
    overwrite,
    bulkUpdate,
  } = useScenarioSegmentConstraints();

  const segmentConstraints = useMemo(() => {
    return _segmentConstraints.map((constraint) => {
      const edge = allEdges[constraint.edge];
      return {
        ...constraint,
        pipeline: edge?.tsp_short_name || edge?.tsp_name || '',
        name: constraintToString(
          allPoints[allNodes[edge?.start_node]?.points[0]],
          allPoints[allNodes[edge?.end_node]?.points[0]],
          constraint,
        ),
      };
    });
  }, [_segmentConstraints]);

  const deletedSegmentConstraints = useMemo(() => {
    return _deletedSegmentConstraints.map((constraint) => {
      const edge = allEdges[constraint.edge];
      return {
        ...constraint,
        pipeline: edge?.tsp_short_name || edge?.tsp_name || '',
        name: constraintToString(
          allPoints[allNodes[edge?.start_node]?.points[0]],
          allPoints[allNodes[edge?.end_node]?.points[0]],
          constraint,
        ),
      };
    });
  }, [_deletedSegmentConstraints]);

  return (
    <>
      {isEditing && (
        <>
          <div className="row justify-content-between m-3">
            <div className="col-6 col-md-8 col-sm-12 d-flex gap-2 align-items-center">
              <DateSelector
                value={toIsoDate(constraintDate)}
                onChange={(evt) => setConstraintDate(evt.target.valueAsDate)}
                isLoading={isLoading || isFetching}
                onConfirm={refreshSegmentConstraints}
                buttonText="Get Constraints"
              />
              <span role="button" className="text-primary">
                <TableRowsIcon onClick={() => setIsImportModalOpen(true)} />
              </span>
            </div>
            <div className="col-auto">
              <button
                onClick={() => setIsAdding((prev) => !prev)}
                className="btn btn-success"
              >
                {isAdding ? 'Cancel' : 'Add Segment Constraint'}
              </button>
            </div>
          </div>
          {isAdding && (
            <div className="row m-3">
              <CreateSegmentConstraint
                onCreate={(constraints) => {
                  constraints.forEach((constraint) => saveItem(constraint));
                  setIsAdding(false);
                }}
              />
            </div>
          )}
        </>
      )}
      <SegmentConstraintTable
        key={contextKey}
        segmentConstraints={segmentConstraints}
        deletedSegmentConstraints={deletedSegmentConstraints}
        onDelete={(constraint) => removeItem(constraint.id)}
        onBulkDelete={bulkRemove}
        onChange={saveItem}
        onCreate={saveItem}
        onRestore={restoreItems}
        onBulkUpdate={bulkUpdate}
        isEditing={isEditing}
      />
      <SegmentConstraintSpreadsheet
        isOpen={isImportModalOpen}
        onToggle={() => setIsImportModalOpen((p) => !p)}
        onImport={append}
        onOverwrite={overwrite}
      />
    </>
  );
};
