import { useSelector } from 'react-redux';
import React, { useState, useMemo } from 'react';
import { numberFormatter } from '../../helpers/formatters';
import { selectEditState } from '../scenarioSlice';
import { colors } from '../../utils/colors';
import { selectAllPoints } from '../../plotter/plotterSelectors';
import { DateSelector } from '../../helpers/DateSelector';
import { toIsoDate } from '../../utils/stringUtils';
import { TradeTable } from './TradeTable';
import { TradeSpreadsheet } from './TradesSpreadsheet';
import { TableRowsIcon } from '@iconicicons/react';
import { CreateTrade } from './CreateTrade';
import { useScenarioTrades } from '../../hooks/useScenarioTrades';

export function Trades() {
  const isEditing = useSelector(selectEditState);
  const allPoints = useSelector(selectAllPoints);

  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  const {
    trades: _trades,
    deletedTrades: _deletedTrades,
    volumeDifference,
    tradesDate,
    isFetching,
    isLoading,
    refreshBaseload,
    setTradesDate,
    saveItem,
    append,
    overwrite,
    removeItem,
    bulkRemove,
    restoreItems,
    bulkUpdate,
  } = useScenarioTrades();

  const trades = useMemo(
    () =>
      _trades.map((trade) => {
        const point = allPoints[trade.point];
        return {
          ...trade,
          pipeline: point?.tsp_name ?? '',
        };
      }),
    [_trades],
  );

  const deletedTrades = useMemo(
    () =>
      _deletedTrades.map((trade) => {
        const point = allPoints[trade.point];
        return {
          ...trade,
          pipeline: point?.tsp_name ?? '',
        };
      }),
    [_deletedTrades],
  );

  return (
    <>
      {isEditing && (
        <>
          <div className="row justify-content-between m-3">
            <div className="col-6 col-md-8 col-sm-12 d-flex gap-2 align-items-center">
              <DateSelector
                value={toIsoDate(tradesDate)}
                onChange={(evt) => setTradesDate(evt.target.valueAsDate)}
                isLoading={isLoading || isFetching}
                onConfirm={refreshBaseload}
                buttonText="Get Baseload"
              />
              <span role="button" className="text-primary">
                <TableRowsIcon onClick={() => setIsImportModalOpen(true)} />
              </span>
            </div>
            <div className="col-auto">
              <button
                onClick={() => setIsAdding((prev) => !prev)}
                className="btn btn-success"
              >
                {isAdding ? 'Cancel' : 'Add Trade'}
              </button>
            </div>
          </div>
          {isAdding && (
            <div className="row m-3">
              <CreateTrade
                onCreate={(trades) => {
                  trades.forEach((trade) => saveItem(trade));
                  setIsAdding(false);
                }}
              />
            </div>
          )}
        </>
      )}
      <div className="row justify-content-between m-3">
        <div className="col-auto">
          {Math.abs(volumeDifference) > 0 && (
            <p>
              Balance:{' '}
              <strong
                style={{
                  color:
                    volumeDifference > 0 ? colors.matteGreen : colors.matteRed,
                }}
              >
                {numberFormatter(Math.abs(volumeDifference))} dth{' '}
                {volumeDifference > 0 ? 'long' : 'short'}
              </strong>
            </p>
          )}
        </div>
      </div>
      <TradeTable
        trades={trades}
        deletedTrades={deletedTrades}
        isEditing={isEditing}
        onBulkDelete={bulkRemove}
        onChange={saveItem}
        onDelete={removeItem}
        onRestore={restoreItems}
        onBulkUpdate={bulkUpdate}
      />
      <TradeSpreadsheet
        isOpen={isImportModalOpen}
        onToggle={() => setIsImportModalOpen((p) => !p)}
        onImport={append}
        onOverwrite={overwrite}
      />
    </>
  );
}
