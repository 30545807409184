import {
  ChevronDownIcon,
  ChevronUpIcon,
  EditIcon,
  FilterIcon,
  TrashIcon,
  UndoIcon,
  ZoomInIcon,
} from '@iconicicons/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { deepEqual } from '../../utils/objectUtils';
import { useClientPagination, useDebouncedCallback } from '../../helpers/hooks';
import { useSelector } from 'react-redux';
import { selectAllEdges } from '../../plotter/plotterSelectors';
import { usePanMap } from '../../plotter/usePanMap';
import { midPoint } from '../../utils/geoJsonUtils';
import { InfiniteScrollObserver } from '../../helpers/InfiniteScrollObserver';
import { numberFormatter } from '../../helpers/formatters';

/**
 * @typedef {{
 *  segmentConstraints: import("../scenarioSlice").SegmentConstraint[];
 *  deletedSegmentConstraints: import("../scenarioSlice").SegmentConstraint[];
 *  onDelete: (row: import("../scenarioSlice").SegmentConstraint) => void;
 *  onBulkDelete: (constraintIds: number[]) => void;
 *  onRestore: (ids: string[]) => void;
 *  onBulkUpdate: (ids: string[], patch: Partial<import("../scenarioSlice").PointConstraint>) => void;
 *  isEditing: boolean;
 *  onChange: (constraint: import('../scenarioSlice').SegmentConstraint) => void;
 * }} SegmentConstraintTableProps
 */

/**
 *
 * @param {SegmentConstraintTableProps} props
 * @returns {React.FC<SegmentConstraintTableProps>}
 */
export const SegmentConstraintTable = ({
  segmentConstraints = [],
  deletedSegmentConstraints = [],
  isEditing = false,
  onDelete,
  onBulkDelete,
  onRestore,
  onBulkUpdate,
  onChange: _onChange,
}) => {
  const allEdges = useSelector(selectAllEdges);
  const panMap = usePanMap();
  const [filters, setFilters] = useState({
    segment: '',
    pipeline: '',
    flow_direction: '',
    description: '',
    constraint_factor: '',
    max_volume: '',
    cuts_at_priority: '',
  });

  /**
   * @type {import('../../types/types').State<{[key: string]: boolean | undefined}>}
   */
  const [selectedConstraints, setSelectedConstraints] = useState({});
  const [showDeleted, setShowDeleted] = useState(false);

  /**
   *
   * @param {import('../scenarioSlice').SegmentConstraint} constraint
   * @param {boolean} selected
   */
  const onSelect = (constraint, selected) => {
    setSelectedConstraints((p) => ({
      ...p,
      [constraint.component_key]: selected,
    }));
  };

  const onChange = useCallback(
    /**
     *
     * @param {import('../scenarioSlice').SegmentConstraint} constraint
     * @param {Partial<import('../scenarioSlice').SegmentConstraint} patch
     */
    (constraint, patch) => {
      _onChange({ ...constraint, ...patch });
    },
    [_onChange],
  );

  const onLocateEdge = (edgeId) => {
    const edge = allEdges[edgeId];
    if (edge) {
      const coords = midPoint(edge.positions[0], edge.positions[1]);
      panMap(coords, 9);
    }
  };

  const onChangeFilter = useDebouncedCallback((key, value) => {
    setFilters((current) => ({ ...current, [key]: value }));
  });

  const filteredConstraints = useMemo(() => {
    return segmentConstraints.filter((constraint) => {
      const segment = `${constraint.edge} ${constraint.name}`;
      const data = { ...constraint, segment: segment };
      return Object.entries(filters)
        .filter(([_key, value]) => {
          if (value === null || value === undefined) {
            return true;
          }
          if (typeof value === 'string') {
            return value.trim().length > 0;
          }
          return true;
        })
        .map(([key, value]) => {
          return [key, String(value).trim().toLowerCase()];
        })
        .every(([key, value]) => {
          return String(data[key]).trim().toLowerCase().includes(value);
        });
    });
  }, [segmentConstraints, filters]);

  const {
    slice: paginatedFilteredConstraints,
    exhausted,
    getNext,
    reset,
  } = useClientPagination(filteredConstraints, 30);

  useEffect(() => reset(), [filteredConstraints]);

  const isEmptySelection = useMemo(
    () => Object.values(selectedConstraints).every((selected) => !selected),
    [selectedConstraints],
  );

  if (segmentConstraints.length === 0) {
    return (
      <div
        style={{
          minHeight: '200px',
          height: '100%',
        }}
        className="d-flex align-items-center justify-content-center"
      >
        <p className="text-center text-muted">No segment constraints</p>
      </div>
    );
  }
  return (
    <table className="table">
      <thead
        style={{
          position: 'sticky',
          top: 0,
        }}
      >
        <tr>
          <th>
            Segment
            <div className="d-flex align-items-center mt-2">
              <FilterIcon className="mx-3" />
              <input
                className="form-control"
                type="text"
                onChange={(evt) => onChangeFilter('segment', evt.target.value)}
              />
            </div>
          </th>
          <th>
            TSP
            <div className="d-flex align-items-center mt-2">
              <input
                className="form-control"
                type="text"
                onChange={(evt) => onChangeFilter('pipeline', evt.target.value)}
              />
            </div>
          </th>
          <th>
            Direction
            <div className="d-flex align-items-center mt-2">
              <input
                className="form-control"
                type="text"
                onChange={(evt) =>
                  onChangeFilter('flow_direction', evt.target.value)
                }
              />
            </div>
          </th>
          <th>
            Description
            <div className="d-flex align-items-center mt-2">
              <input
                className="form-control"
                type="text"
                onChange={(evt) =>
                  onChangeFilter('description', evt.target.value)
                }
              />
            </div>
          </th>
          <th>
            Constraint Factor (%)
            <div className="d-flex align-items-center mt-2">
              <input
                className="form-control"
                type="number"
                onChange={(evt) =>
                  onChangeFilter('constraint_factor', evt.target.value)
                }
              />
            </div>
          </th>
          <th>
            Max Volume
            <div className="d-flex align-items-center mt-2">
              <input
                className="form-control"
                type="number"
                onChange={(evt) =>
                  onChangeFilter('max_volume', evt.target.value)
                }
              />
            </div>
          </th>
          <th style={{ minWidth: '100px' }}>
            Cuts at Priority
            <div className="d-flex align-items-center mt-2">
              <select
                className="form-select"
                defaultValue=""
                onChange={(evt) =>
                  onChangeFilter('cuts_at_priority', evt.target.value)
                }
              >
                <option value=""> </option>
                <option value="PIP">PIP</option>
                <option value="SIP PS">SIP PS</option>
                <option value="SIP PS">SIP SP</option>
                <option value="SIP PS">SIP SS</option>
                <option value="SOP">SOP</option>
                <option value="IT">IT</option>
              </select>
            </div>
          </th>
          {isEditing && <th>Actions</th>}
        </tr>
      </thead>
      <tbody>
        {isEditing && (
          <>
            {showDeleted && deletedSegmentConstraints.length > 0 && (
              <tr>
                <td colSpan={9}>
                  <div
                    onClick={() =>
                      onRestore(deletedSegmentConstraints.map((m) => m.id))
                    }
                    role="button"
                    className="d-flex align-items-center justify-content-center text-success"
                  >
                    <span>
                      Restore all <UndoIcon />
                    </span>
                  </div>
                </td>
              </tr>
            )}
            {showDeleted &&
              deletedSegmentConstraints.map((constraint) => {
                return (
                  <DeletedSegmentConstraintTableRow
                    key={constraint.id}
                    constraint={constraint}
                    onLocateEdge={onLocateEdge}
                    onRestore={onRestore}
                  />
                );
              })}
            {deletedSegmentConstraints.length > 0 && (
              <tr>
                <td colSpan={9}>
                  <div
                    onClick={() => setShowDeleted((p) => !p)}
                    role="button"
                    className="d-flex flex-column align-items-center justify-content-center"
                  >
                    <span>
                      {showDeleted ? 'Hide' : 'Show'}{' '}
                      {deletedSegmentConstraints.length} deleted constraints
                    </span>
                    <span>
                      {showDeleted ? <ChevronUpIcon /> : <ChevronDownIcon />}
                    </span>
                  </div>
                </td>
              </tr>
            )}
            <BulkEditSegmentConstraintTableRow
              onSelectAll={(checked) => {
                const selected = filteredConstraints.reduce(
                  (acc, constraint) => {
                    acc[constraint.component_key] = checked;
                    return acc;
                  },
                  {},
                );
                setSelectedConstraints(selected);
              }}
              allChecked={filteredConstraints.every(
                (constraint) => selectedConstraints[constraint.component_key],
              )}
              noneChecked={isEmptySelection}
              onDeleteSelected={() => {
                const selected = filteredConstraints
                  .filter(
                    (constraint) =>
                      selectedConstraints[constraint.component_key],
                  )
                  .map((constraint) => constraint.id);
                if (selected.length > 0) {
                  onBulkDelete(selected);
                  setSelectedConstraints({});
                }
              }}
              onBulkUpdate={(patch) => {
                const selection = filteredConstraints
                  .filter(
                    (constraint) =>
                      selectedConstraints[constraint.component_key],
                  )
                  .map((constraint) => constraint.id);
                onBulkUpdate(selection, patch);
              }}
            />
          </>
        )}
        {paginatedFilteredConstraints.map((constraint) =>
          isEditing ? (
            <EditSegmentConstraintTableRow
              key={constraint.id}
              constraint={constraint}
              onDelete={onDelete}
              onChange={onChange}
              onLocateEdge={onLocateEdge}
              onSelect={onSelect}
              isSelected={Boolean(
                selectedConstraints[constraint.component_key],
              )}
            />
          ) : (
            <SegmentConstraintTableRow
              key={constraint.id}
              constraint={constraint}
              onLocateEdge={onLocateEdge}
            />
          ),
        )}
        <tr>
          <td colSpan={isEditing ? 8 : 7}>
            <InfiniteScrollObserver
              onScrollIn={() => !exhausted && getNext()}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

/**
 *
 * @param {{
 *  constraint: import('../scenarioSlice').SegmentConstraint & {pipeline: string; name: string};
 *  onLocateEdge: (edgeId: number) => void;
 * }} props
 * @returns
 */
const _SegmentConstraintTableRow = ({ constraint, onLocateEdge }) => {
  return (
    <tr>
      <td>
        <div className="d-flex align-items-center">
          <button
            className="btn btn-light me-2"
            disabled={!constraint.edge}
            style={{
              opacity: 1,
            }}
            onClick={() => onLocateEdge(constraint.edge)}
          >
            <ZoomInIcon />
          </button>
          <span>
            <strong className="me-2">#{constraint.edge}</strong>{' '}
            {constraint.name}
          </span>
        </div>
      </td>
      <td>{constraint.pipeline}</td>
      <td>{constraint.flow_direction}</td>
      <td>{constraint.description}</td>
      <td className="text-center">{constraint.constraint_factor * 100}%</td>
      <td className="text-center">{numberFormatter(constraint.max_volume)}</td>
      <td className="text-center">{constraint.cuts_at_priority}</td>
    </tr>
  );
};

/**
 *
 * @param {{
 *  constraint: import('../scenarioSlice').SegmentConstraint & {pipeline: string; name: string};
 *  onLocateEdge: (edgeId: number) => void;
 *  onRestore: (ids: string[]) => void;
 * }} props
 * @returns
 */
const _DeletedSegmentConstraintTableRow = ({
  constraint,
  onLocateEdge,
  onRestore,
}) => {
  return (
    <tr className="table-danger">
      <td>
        <div className="d-flex align-items-center">
          <button
            className="btn btn-light me-2"
            disabled={!constraint.edge}
            style={{
              opacity: 1,
            }}
            onClick={() => onLocateEdge(constraint.edge)}
          >
            <ZoomInIcon />
          </button>
          <span>
            <strong className="me-2">#{constraint.edge}</strong>{' '}
            {constraint.name}
          </span>
        </div>
      </td>
      <td>{constraint.pipeline}</td>
      <td>{constraint.flow_direction}</td>
      <td>{constraint.description}</td>
      <td className="text-center">{constraint.constraint_factor * 100}%</td>
      <td className="text-center">{numberFormatter(constraint.max_volume)}</td>
      <td className="text-center">{constraint.cuts_at_priority}</td>
      <td
        className="text-center"
        role="button"
        onClick={() => onRestore([constraint.id])}
      >
        <UndoIcon color="green" />
      </td>
    </tr>
  );
};

/**
 *
 * @param {{
 *  constraint: import('../scenarioSlice').SegmentConstraint & {pipeline: string; name: string};
 *  onDelete: (row: import('../scenarioSlice').SegmentConstraint) => void;
 *  onChange: (original: import('../scenarioSlice').SegmentConstraint, edited: import('../scenarioSlice').SegmentConstraint) => void;
 *  onLocateEdge: (edgeId: number) => void;
 *  onSelect: (constraint: import('../scenarioSlice').SegmentConstraint, selected: boolean) => void;
 *  isSelected: boolean;
 * }} props
 * @returns
 */
const _EditSegmentConstraintTableRow = ({
  constraint,
  onLocateEdge,
  onDelete,
  onChange,
  onSelect,
  isSelected,
}) => {
  return (
    <tr className={isSelected ? 'table-secondary' : ''}>
      <td>
        <div className="d-flex align-items-center">
          <div
            style={{ width: '30px' }}
            role="button"
            onClick={() => onSelect(constraint, !isSelected)}
          >
            <input
              type="checkbox"
              readOnly
              checked={isSelected}
              className="form-check-input form-check-input-sm me-2"
            />
          </div>
          <button
            className="btn btn-light me-2"
            disabled={!constraint.edge}
            style={{
              opacity: 1,
            }}
            onClick={() => onLocateEdge(constraint.edge)}
          >
            <ZoomInIcon />
          </button>
          <span onClick={() => onSelect(constraint, !isSelected)} role="button">
            <strong className="me-2">#{constraint.edge}</strong>{' '}
            {constraint.name}
          </span>
        </div>
      </td>
      <td onClick={() => onSelect(constraint, !isSelected)} role="button">
        {constraint.pipeline}
      </td>
      <td>
        <select
          value={constraint.flow_direction}
          className="form-select form-select-sm"
          onChange={(e) =>
            onChange(constraint, { flow_direction: e.target.value })
          }
        >
          <option value="placeholder" disabled>
            Select Direction
          </option>
          <option value="FORWARDHAUL">Forwardhaul</option>
          <option value="BACKHAUL">Backhaul</option>
        </select>
      </td>
      <td>
        <textarea
          type="text"
          className="form-control form-control-sm"
          value={constraint.description}
          onChange={(e) =>
            onChange(constraint, { description: e.target.value })
          }
        />
      </td>
      <td className="text-center">
        <input
          type="number"
          className="form-control form-control-sm"
          value={constraint.constraint_factor * 100}
          onChange={(e) =>
            onChange(constraint, {
              constraint_factor: e.target.value / 100,
            })
          }
          min={0}
          max={100}
        />
      </td>
      <td className="text-center">
        <input
          type="number"
          className="form-control form-control-sm"
          value={constraint.max_volume}
          onChange={(e) => onChange(constraint, { max_volume: e.target.value })}
          min={0}
        />
      </td>
      <td className="text-center">
        <select
          type="select"
          className="form-select form-select-sm"
          value={constraint.cuts_at_priority}
          onChange={(e) =>
            onChange(constraint, { cuts_at_priority: e.target.value })
          }
        >
          <option value="placeholder" disabled>
            Select Priority
          </option>
          <option value="PIP">PIP</option>
          <option value="SIP_PS">SIP_PS</option>
          <option value="SIP_SP">SIP_SP</option>
          <option value="SIP_SS">SIP_SS</option>
          <option value="SOP">SOP</option>
          <option value="IT">IT</option>
        </select>
      </td>
      <td className="text-center">
        <TrashIcon
          onClick={() => onDelete(constraint)}
          role="button"
          color="red"
        />
      </td>
    </tr>
  );
};

/**
 *
 * @param {{
 *  allChecked: boolean;
 *  noneChecked: boolean;
 *  onSelectAll: (selected: boolean) => void;
 *  onDeleteSelected: () => void;
 *  onBulkUpdate: (patch: Partial<import('../scenarioSlice').SegmentConstraint>) => void;
 * }} props
 * @returns
 */
const BulkEditSegmentConstraintTableRow = ({
  allChecked,
  noneChecked,
  onSelectAll,
  onDeleteSelected,
  onBulkUpdate,
}) => {
  /**
   * @type {import('../../types/types').State<Partial<import('../scenarioSlice').SegmentConstraint>}
   */
  const [patch, setPatch] = useState({
    flow_direction: '',
    description: '',
    constraint_factor: 0,
    max_volume: 0,
    cuts_at_priority: '',
  });

  const reset = () => {
    setPatch({
      flow_direction: '',
      description: '',
      constraint_factor: 0,
      max_volume: 0,
      cuts_at_priority: '',
    });
  };

  useEffect(() => {
    if (noneChecked) {
      reset();
    }
  }, [noneChecked]);

  return (
    <tr>
      <td colSpan={2}>
        <div className="d-flex align-items-center">
          <input
            type="checkbox"
            checked={allChecked}
            onChange={(e) => onSelectAll(e.target.checked)}
            className="form-check-input form-check-input-sm me-3"
          />
          <button
            className={`btn ${noneChecked ? 'btn-secondary' : 'btn-danger'}`}
            style={{ opacity: 1 }}
            disabled={noneChecked}
            onClick={onDeleteSelected}
          >
            Delete Selected
          </button>
        </div>
      </td>
      {noneChecked ? (
        <td colSpan={5}></td>
      ) : (
        <>
          <td>
            <select
              value={patch.flow_direction}
              className="form-select form-select-sm"
              onChange={(e) =>
                setPatch((p) => ({ ...p, flow_direction: e.target.value }))
              }
            >
              <option value="placeholder" disabled>
                Select Direction
              </option>
              <option value="FORWARDHAUL">Forwardhaul</option>
              <option value="BACKHAUL">Backhaul</option>
            </select>
          </td>
          <td>
            <input
              type="text"
              className="form-control form-control-sm"
              value={patch.description}
              onChange={(e) =>
                setPatch((p) => ({ ...p, description: e.target.value }))
              }
            />
          </td>
          <td className="text-center">
            <input
              type="number"
              className="form-control form-control-sm"
              value={patch.constraint_factor * 100}
              onChange={(e) =>
                setPatch((p) => ({
                  ...p,
                  constraint_factor: e.target.value / 100,
                }))
              }
              min={0}
              max={100}
            />
          </td>
          <td className="text-center">
            <input
              type="number"
              className="form-control form-control-sm"
              value={patch.max_volume}
              onChange={(e) =>
                setPatch((p) => ({ ...p, max_volume: e.target.value }))
              }
              min={0}
            />
          </td>
          <td className="text-center">
            <select
              type="select"
              className="form-select form-select-sm"
              value={patch.cuts_at_priority}
              onChange={(e) =>
                setPatch((p) => ({ ...p, cuts_at_priority: e.target.value }))
              }
            >
              <option value="placeholder" disabled>
                Select Priority
              </option>
              <option value="PIP">PIP</option>
              <option value="SIP_PS">SIP_PS</option>
              <option value="SIP_SP">SIP_SP</option>
              <option value="SIP_SS">SIP_SS</option>
              <option value="SOP">SOP</option>
              <option value="IT">IT</option>
            </select>
          </td>
          <td className="text-center">
            <EditIcon
              className="text-primary"
              onClick={() => {
                onBulkUpdate(
                  Object.fromEntries(
                    Object.entries(patch).filter(([_, v]) => Boolean(v)),
                  ),
                );
                reset();
              }}
              role="button"
            />
          </td>
        </>
      )}
    </tr>
  );
};

const SegmentConstraintTableRow = React.memo(
  _SegmentConstraintTableRow,
  (prev, next) => {
    return deepEqual(prev.constraint, next.constraint);
  },
);

const DeletedSegmentConstraintTableRow = React.memo(
  _DeletedSegmentConstraintTableRow,
  (prev, next) => {
    return deepEqual(prev.constraint, next.constraint);
  },
);

const EditSegmentConstraintTableRow = React.memo(
  _EditSegmentConstraintTableRow,
  (prev, next) => {
    return (
      deepEqual(prev.constraint, next.constraint) &&
      prev.isSelected === next.isSelected
    );
  },
);
