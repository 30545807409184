import { useDispatch } from 'react-redux';
import { scenarioActions } from '../scenarios/scenarioSlice';
import { useCallback } from 'react';

/**
 * @template {import('../scenarios/scenarioSlice').ScenarioComponentType} T
 * @param {T} componentType
 * @returns
 */
export const useScenarioFunctions = (componentType) => {
  const dispatch = useDispatch();

  const removeItem = useCallback(
    /**
     *
     * @param {string} id
     */
    (id) => {
      dispatch(
        scenarioActions.removeComponent({ componentType, componentId: id }),
      );
    },
    [dispatch, componentType],
  );

  const bulkRemove = useCallback(
    /**
     *
     * @param {string[]} ids
     */
    (ids) => {
      dispatch(
        scenarioActions.bulkDelete({ componentType, componentIds: ids }),
      );
    },
    [dispatch, componentType],
  );

  const restoreItems = useCallback(
    /**
     *
     * @param {string[]} ids
     */
    (ids) => {
      dispatch(
        scenarioActions.restoreRemovedComponents({
          componentType,
          componentIds: ids,
        }),
      );
    },
    [dispatch, componentType],
  );

  const saveItem = useCallback(
    /**
     * @param {import('../scenarios/scenarioSlice').Scenario[T][0]} component
     */
    (component) => {
      dispatch(
        scenarioActions.updateComponent({
          componentType,
          component: component,
        }),
      );
    },
    [dispatch, componentType],
  );

  const append = useCallback(
    /**
     * @param {import('../scenarios/scenarioSlice').Scenario[T]} components
     */
    (components) => {
      dispatch(
        scenarioActions.appendComponents({
          componentType,
          components,
        }),
      );
    },
    [dispatch, componentType],
  );

  const overwrite = useCallback(
    /**
     * @param {import('../scenarios/scenarioSlice').Scenario[T]} components
     */
    (components) => {
      dispatch(
        scenarioActions.overwrite({
          componentType,
          components,
        }),
      );
    },
    [dispatch, componentType],
  );

  const bulkUpdate = useCallback(
    /**
     *
     * @param {string[]} ids
     * @param {Partial<import('../scenarios/scenarioSlice').Scenario[T][0]>} patch
     */
    (ids, patch) => {
      dispatch(
        scenarioActions.bulkUpdate({
          componentType,
          componentIds: ids,
          data: patch,
        }),
      );
    },
    [dispatch, componentType],
  );

  return {
    removeItem,
    bulkRemove,
    restoreItems,
    saveItem,
    append,
    overwrite,
    bulkUpdate,
  };
};
