import toast from 'react-hot-toast';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  useGetCapacityComponentsQuery,
  useGetCapacityComponentQuery,
  useFavoriteItemMutation,
  useUnfavoriteItemMutation,
  useGetNoticeComponentQuery,
  useGetScenariosQuery,
  useGetPriceComponentHistoryQuery,
} from '../services/edgeApi';
import { toIsoDate } from '../utils/stringUtils';
import { useDispatch } from 'react-redux';
import { utils } from 'xlsx';
import * as filtersSlice from '../filters/filtersSlice';
import { useMap, useMapEvents } from 'react-leaflet';
import { mapContext } from '../map/EdgeMap';
import { WebGLLayer } from '../map/webglLayer';
import { Vector as VectorSource } from 'ol/source.js';
import GeoJSON from 'ol/format/GeoJSON.js';
// import proccess from 'process';

export const useDelete = () => {
  const deleteTrigger = async (deleteFunction, _name) => {
    // if (!window.confirm(`Are you sure you want to delete ${name}?`)){
    // should make this useConfirm and make more of a customizable message
    if (!window.confirm('Are you sure?')) {
      return null;
    }

    try {
      await deleteFunction();
      // TODO: might need to pass in an onSuccess function
      // dispatch(selectedItemsActions.setState({edgeStartNode: [], edgeEndNode: [], edge: ['new']}))
      toast.success('We did it!');
    } catch {
      toast.error('Error, please try again');
    }
  };

  return deleteTrigger;
};

// can set this up as a hook to call functions, or can set it up as a simple function that returns true or false, basically all a window.confirm already does
// export const useConfirm = (message, onConfirm, onAbort) => {
//   const confirm = () => {
//     if(window.confirm(message))
//       onConfirm();
//     else
//       onAbort();
//   }
//   return confirm
// }

export const useConfirm = () => {
  const confirm = (message) =>
    // just return true or false
    window.confirm(message);

  return confirm;
};

export function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay], // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

/**
 * @template {unknown} T
 * @param {T} initialValue
 * @param {number} delay
 * @returns {[T, React.Dispatch<React.SetStateAction<T>>]}
 */
export const useDebouncedState = (initialValue, delay) => {
  const [value, _setValue] = useState(initialValue);
  let timeout = null;

  const setValue = useCallback(
    (setter) => {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        _setValue(setter);
      }, delay);
    },
    [delay],
  );

  return [value, setValue];
};

/**
 * @template {(...args: any) => void} T
 * @param {T} func
 * @param {number} delay
 * @returns {T} A function that when called will execute the original function after the delay. If called again before the delay, the timer will reset.
 * @warning This hook is unsuitable for functions that are triggered by inputs, in these cases the `DebouncedInput` component should be used.
 * If you can't avoid using this hook for an input callback and you're expecting the input value to be modified by the callback, make sure to pass the value
 * as `defaultValue` to the input component instead of `value`. If you do so, you will be able to use the input but might still experience issues if the value
 * is modified by a different source.
 */
export const useDebouncedCallback = (func, delay) => {
  const timeoutId = useRef(null);
  return useCallback((...args) => {
    if (timeoutId.current) clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(() => {
      func(...args);
    }, delay);
  }, []);
};

/**
 *
 * @param {{
 *  gasDate: string,
 *  nomCycle: string,
 *  itemsPerPage: number,
 *  capacityType: 'SEGMENT' | 'POINT',
 *  favorites: boolean,
 *  searchText: string,
 * }} options
 * @param {boolean} defaultFilter
 * @returns {{
 *  flows: {[key: string]: Flow}
 *  flowsLoading: boolean,
 *  flowsFetching: boolean,
 *  flowsLoadError: boolean,
 *  fetchNextPage: () => void,
 *  reset: () => void,
 * }}
 */
export const useCapacityComponents = (
  {
    gasDate,
    nomCycle,
    itemsPerPage = 30,
    capacityType = null,
    favorites = false,
    searchText = null,
  } = {},
  defaultFilter = false,
) => {
  const [shouldFetch, setShouldFetch] = useState(true);
  const [searchChanged, setSearchChanged] = useState(false);
  const [exhausted, setExhausted] = useState(false);
  const [flows, setFlows] = useState({});
  const [offset, setCursor] = useState(0);

  useEffect(() => {
    setSearchChanged(true);
    setShouldFetch(true);
    setCursor(0);
    setExhausted(false);
  }, [searchText]);

  const filters =
    defaultFilter === true && (searchText === '' || searchText === null)
      ? { gasDate, nomCycle, defaultFilter: true }
      : {
          gasDate,
          nomCycle,
          limit: itemsPerPage,
          offset,
          capacityType,
          favorites: favorites ? 'True' : 'False',
          searchText,
        };

  const { data, isLoading, isFetching, isError } =
    useGetCapacityComponentsQuery(filters, { skip: !shouldFetch || exhausted });

  if (shouldFetch && isFetching === false && exhausted === false) {
    if (searchChanged) {
      setCursor(0);
      setFlows({});
      setExhausted(false);
      setSearchChanged(false);
    }
    setFlows((prevFlows) => ({ ...prevFlows, ...data }));
    setShouldFetch(false);
    if (data && Object.values(data).length > 0) {
      setCursor((prevCursor) => prevCursor + Object.values(data).length);
    }
    if (data && Object.values(data).length < itemsPerPage) {
      setExhausted(true);
    }
  }

  const fetchNextPage = useCallback(() => {
    setShouldFetch(true);
  }, [setShouldFetch]);

  const reset = () => {
    setShouldFetch(true);
    setExhausted(false);
    setFlows({});
    setCursor(0);
  };

  return {
    flows,
    flowsLoading: isLoading,
    flowsFetching: isFetching,
    flowsLoadError: isError,
    fetchNextPage,
    reset,
  };
};

// TODO: type `Flows` more specifically
/**
 *
 * @param {*[]} flows The flows array
 * @returns {Date | null} The lowest gas date in the flows array
 */
const getLowestGasDate = (flows) => {
  if (flows.length === 0) {
    return null;
  }

  const dates = flows.map((flow) => {
    if (flow?.start_gas_date) {
      const gasDate = flow.start_gas_date
        .split('-')
        .map((value) => Number(value));
      return new Date(gasDate[0], gasDate[1] - 1, gasDate[2]);
    } else {
      const gasDate = flow.gas_date.split('/').map((value) => Number(value));
      return new Date(gasDate[2], gasDate[0] - 1, gasDate[1]);
    }
  });
  return dates.sort((dateA, dateB) => {
    return dateA.getTime() - dateB.getTime();
  })[0];
};

export const useCapacityComponentsHistory = ({
  componentId,
  itemsPerPage = 30,
}) => {
  const [shouldFetch, setShouldFetch] = useState(true);
  const [exhausted, setExhausted] = useState(false);
  const [capacityComponent, setCapacityComponent] = useState(null);
  const [flows, setFlows] = useState([]);
  const [cursor, setCursor] = useState(toIsoDate(new Date()));

  const { data, isLoading, isFetching, isError } = useGetCapacityComponentQuery(
    { componentId, limit: itemsPerPage, offset: cursor },
    { skip: !shouldFetch || exhausted },
  );

  if (isError) {
    setShouldFetch(false);
  } else if (shouldFetch && isFetching === false && exhausted === false) {
    // TODO: make this dynamic when we add sources
    // This spread is necessary because the array is read only
    const pipelineFlows = [...data.sources.platts_pipeline_flows];

    setCapacityComponent(data);
    setFlows((prevFlows) => [...prevFlows, ...pipelineFlows]);
    setShouldFetch(false);

    if (pipelineFlows.length > 0) {
      const nextCursor = getLowestGasDate(pipelineFlows);
      setCursor(toIsoDate(nextCursor));
    }
    if (pipelineFlows.length === 0) {
      setExhausted(true);
    }
  }

  const fetchNextPage = useCallback(() => {
    setShouldFetch(true);
  }, [setShouldFetch]);

  return {
    capacityComponent,
    flows,
    flowsLoading: isLoading,
    flowsFetching: isFetching,
    flowsLoadError: isError,
    fetchNextPage,
  };
};

/**
 *
 * @param {{
 *  componentId: string | number,
 *  itemsPerPage: number,
 *  priceSource: import('../scenarios/scenarioSlice').PriceDataProvider
 * }} params
 * @returns
 */
export const usePriceComponentHistory = ({
  componentId,
  itemsPerPage = 100,
  priceSource = 'PLATTS_DAILY',
}) => {
  const [shouldFetch, setShouldFetch] = useState(true);
  const [exhausted, setExhausted] = useState(false);
  const [priceComponent, setPriceComponent] = useState(null);
  const [prices, setPrices] = useState([]);
  const [cursor, setCursor] = useState(toIsoDate(new Date()));

  const { data, isLoading, isFetching, isError, error } =
    useGetPriceComponentHistoryQuery(
      { componentId, pageSize: itemsPerPage, cursor, priceSource },
      { skip: !shouldFetch || exhausted },
    );

  if (isError) {
    setShouldFetch(false);
    setExhausted(true);
  } else if (shouldFetch && isFetching === false && exhausted === false) {
    setPriceComponent(data.component);
    const history = Object.values(
      data.history.reduce((acc, record) => {
        if (!acc[record.assess_date]) {
          acc[record.assess_date] = {
            gas_date: new Date(record.assess_date).toLocaleDateString(),
          };
        }
        acc[record.assess_date][record.bate] = record.value;
        return acc;
      }, {}),
    );
    setPrices((prevFlows) => [...prevFlows, ...history]);
    setShouldFetch(false);

    if (history.length > 0) {
      setCursor(data.cursor);
    } else {
      setExhausted(true);
    }
  }

  const fetchNextPage = useCallback(() => {
    setShouldFetch(true);
  }, [setShouldFetch]);

  const reset = useCallback(() => {
    setShouldFetch(true);
    setExhausted(false);
    setPrices([]);
    setCursor(toIsoDate(new Date()));
  }, [setShouldFetch, setExhausted, setPrices, setCursor]);

  useEffect(() => {
    reset();
  }, [componentId, priceSource]);

  return {
    priceComponent,
    prices,
    isLoading,
    isFetching,
    isError,
    error,
    exhausted,
    fetchNextPage,
    reset,
  };
};

export const useNoticeComponentsHistory = ({
  componentId,
  itemsPerPage = 30,
}) => {
  const [shouldFetch, setShouldFetch] = useState(true);
  const [exhausted, setExhausted] = useState(false);
  const [noticeComponent, setNoticeComponent] = useState(null);
  const [flows, setFlows] = useState([]);
  const [cursor, setCursor] = useState(toIsoDate(new Date()));
  const { data, isLoading, isFetching, isError } = useGetNoticeComponentQuery(
    { componentId, limit: itemsPerPage, offset: cursor },
    { skip: !shouldFetch || exhausted },
  );
  if (isError) {
    setShouldFetch(false);
  } else if (shouldFetch && isFetching === false && exhausted === false) {
    const pipelineFlows = [...data.notice_records];
    setNoticeComponent(data);
    setFlows((prevFlows) => [...prevFlows, ...pipelineFlows]);
    setShouldFetch(false);
    if (pipelineFlows.length > 0) {
      const nextCursor = getLowestGasDate(pipelineFlows);
      setCursor(toIsoDate(nextCursor));
    }
    if (pipelineFlows.length === 0) {
      setExhausted(true);
    }
  }
  const fetchNextPage = () => {
    setShouldFetch(true);
  };
  return {
    noticeComponent,
    flows,
    flowsLoading: isLoading,
    flowsFetching: isFetching,
    flowsLoadError: isError,
    fetchNextPage,
  };
};

/**
 *
 * @param {{ all?: boolean, flowDate?: Date, search?: string, pageSize?: number}} params
 * @returns {{
 *  scenarios: import('../scenarios/scenarioSlice').Scenario[],
 *  isError: boolean,
 *  isLoading: boolean,
 *  isFetching: boolean,
 *  exhausted: boolean,
 *  fetchNextPage: () => void,
 *  reset: () => void,
 * }}
 */
export const useScenarios = ({
  all = false,
  flowDate = null,
  search,
  showSystem = false,
  pageSize = 30,
}) => {
  const [shouldFetch, setShouldFetch] = useState(true);
  const [scenarios, setScenarios] = useState([]);
  const [exhausted, setExhausted] = useState(false);
  const [cursor, setCursor] = useState(new Date().toISOString());
  const { data, isError, isFetching, isLoading, isSuccess } =
    useGetScenariosQuery(
      {
        all,
        search,
        limit: pageSize,
        offset: cursor,
        flow_date: flowDate,
        system: showSystem ? null : 0,
      },
      { skip: !shouldFetch || exhausted },
    );

  useUpdateEffect(() => reset(), [search, all, pageSize, flowDate, showSystem]);

  if (shouldFetch) {
    if (isError) {
      setShouldFetch(false);
    } else if (!isFetching && isSuccess && !exhausted) {
      const response = data || [];
      setScenarios((s) => [...s, ...response]);
      setShouldFetch(false);

      if (response.length > 0) {
        const nextCursor = response[response.length - 1].created_at;
        setCursor(nextCursor);
      }
      if (response.length === 0) {
        setExhausted(true);
      }
    }
  }

  const fetchNextPage = () => {
    if (exhausted) return;
    setShouldFetch(true);
  };

  const reset = () => {
    setCursor(new Date().toISOString());
    setScenarios([]);
    setExhausted(false);
    setShouldFetch(true);
  };

  return {
    scenarios,
    isError,
    isLoading,
    isFetching,
    exhausted,
    fetchNextPage,
    reset,
  };
};

export const useToggleFavorite = (itemType, initial = {}) => {
  const [favoriteState, setFavoriteState] = useState({});

  const [favoriteMutation, favoriteMutationData] = useFavoriteItemMutation();
  const [unfavoriteMutation, unfavoriteMutationData] =
    useUnfavoriteItemMutation();

  const getFavorite = (id) => {
    if (favoriteState[id] !== undefined) {
      return favoriteState[id];
    }

    if (initial[id] !== undefined) {
      return initial[id];
    }

    return false;
  };

  const toggleFavorite = async (id) => {
    if (favoriteMutationData.isLoading || unfavoriteMutationData.isLoading) {
      return;
    }

    const callback = (response, state) => {
      if (!response.error) {
        setFavoriteState((prev) => ({
          ...prev,
          [id]: state,
        }));
      }
    };

    if (getFavorite(id)) {
      unfavoriteMutation({ itemId: id, itemType }).then((response) =>
        callback(response, false),
      );
    } else {
      favoriteMutation({ itemId: id, itemType }).then((response) =>
        callback(response, true),
      );
    }
  };

  return {
    getFavorite,
    toggleFavorite,
    isLoading:
      favoriteMutationData.isLoading || unfavoriteMutationData.isLoading,
  };
};

/**
 * @template T
 * @param {T[]} data
 * @param {number} pageSize
 * @returns {{
 *  slice: T[],
 *  exhausted: boolean,
 *  getNext: () => void,
 *  reset: () => void,
 * }}
 */
export const useClientPagination = (data, pageSize) => {
  const [slice, setSlice] = useState(data.slice(0, pageSize));
  const page = useRef(1);
  const exhausted = false;

  const getNext = () => {
    if (exhausted) return;
    page.current += 1;
    setSlice(data.slice(0, Math.min(pageSize * page.current, data.length)));
  };

  const reset = useCallback(() => {
    page.current = 1;
    setSlice(data.slice(0, Math.min(pageSize, data.length)));
  }, [data, pageSize]);

  useEffect(() => {
    reset();
  }, [data, pageSize]);

  return {
    slice,
    exhausted,
    getNext,
    reset,
  };
};

/**
 * @template {{[key: string]: string | number}} T
 * @param {T[]} items
 * @param {Partial<T>} filter
 * @param {{[key: keyof T]: (val: string) => string}} mapFunctions
 * @returns {T[]}
 */
const filterItems = (items, filter, mapFunctions) => {
  if (Array.isArray(items) === false) return [];
  return items.filter((item) => {
    return Object.keys(filter)
      .filter((key) => filter[key]?.toString())
      .every((key) => {
        if (typeof item[key]?.toString !== 'function') return false; // Item must be string serializable
        const mapFunction = mapFunctions[key] ?? ((value) => value); // Use provided map function or default to identity
        if (typeof mapFunction !== 'function') return false; // If a map function is provided, it must be a function
        const mappedItem = mapFunction(item[key].toString());
        const match = mappedItem
          .replace(/\s/g, '')
          .toLowerCase()
          .includes(filter[key].toString().replace(/\s/g, '').toLowerCase());
        const filterDefined = filter[key] !== undefined;
        return match || !filterDefined;
      });
  });
};

/**
 * @template T
 * @param {T[]} data
 * @param {Partial<T>} filter
 * @param {{[key: keyof T]: (val: string) => string}} mapFunctions
 * @returns {T[]}
 */
export const useClientFiltering = (data, filter, mapFunctions = {}) => {
  const [filteredData, setFilteredData] = useState(
    filterItems(data, filter, mapFunctions),
  );

  useEffect(() => {
    setFilteredData(filterItems(data, filter, mapFunctions));
  }, [data, filter, mapFunctions]);

  return filteredData;
};

/**
 *
 * @param {CallableFunction} fn
 * @param {any[]} inputs
 */
export const useUpdateEffect = (fn, inputs) => {
  const isMountingRef = useRef(false);

  useEffect(() => {
    isMountingRef.current = true;
  }, []);

  useEffect(() => {
    if (!isMountingRef.current) {
      return fn();
    } else {
      isMountingRef.current = false;
    }
  }, inputs);
};

/**
 * @return {(tsps: string[]) => void}
 */
export const useTspFilter = () => {
  const dispatch = useDispatch();

  const filter = (tsps) => {
    dispatch(
      filtersSlice.filtersChanged({
        filterType: 'pointAttributes',
        name: 'tsp_name',
        value: tsps,
      }),
    );
  };
  return filter;
};

/**
 *
 * @param {string} selector
 * @returns {Element | null}
 */
const _getElement = (selector) => {
  return window.document.querySelector(selector);
};

/**
 *
 * @param {string} selector
 * @returns {import('xlsx').WorkBook}
 */
const _getWorkbook = (selector) => {
  const viewElement = _getElement(selector);
  const table = viewElement?.firstElementChild;
  if (table) {
    return utils.table_to_book(table);
  }
  return utils.book_new();
};

/**
 *
 * @param {string} tableSelector
 * @param {(wb: import('xlsx').WorkBook) => void} callback
 * @param {React.DependencyList} deps
 * @returns {void}
 */
export const useWorkbook = (tableSelector, callback, deps) => {
  useEffect(() => {
    callback(_getWorkbook(tableSelector));
  }, [tableSelector, ...deps]);

  useEffect(() => {
    const tableElement = _getElement(tableSelector);
    const observer = new MutationObserver((_) => {
      callback(_getWorkbook(tableSelector));
    });
    if (tableElement) {
      observer.observe(tableElement, {
        childList: true,
        subtree: true,
        attributes: true,
        characterData: true,
      });
    }
    return () => {
      observer.disconnect();
    };
  }, [tableSelector, callback]);
};

/**
 *
 * @param {{
 *  baseValue?: number,
 *  zoomRange?: [number, number],
 *  a?: number,
 *  b?: number
 * }} params
 * @returns {number}
 */
export const useZoomResponsiveValue = ({
  baseValue = 1,
  zoomRange = [1, 14],
  a = -29.6752,
  b = 18.2921,
}) => {
  const map = useMap();
  const [zoom, setZoom] = useState(map.getZoom());

  useMapEvents({
    zoomend: () => {
      setZoom(map.getZoom());
    },
  });

  const value = useMemo(() => {
    // I experimented with different discrete values for the scaling value
    // and did a regression analysis to find a good fit for the zoom level
    // x = zoom
    // y = value

    // Quadratic is alright, but gets weird at the extremes:
    // y = ax 2 + bx + c, where a ≠ 0
    // y = (5.5879)x 2 + (-2.4432)x + (0.2917)

    // const a = 5.5879;
    // const b = -2.4432;
    // const c = 0.2917;
    // const x = Math.min(14, Math.max(1, zoom));
    // const x2 = Math.pow(x, 2);
    // const y = Math.ceil(a * x2 + b * x + c);

    // Logarithmic is better
    // y = a + b * ln(x)
    // y = −29.6752 + 18.2921 * ln(x)
    const x = Math.min(zoomRange[1], Math.max(zoomRange[0], zoom));
    return baseValue * Math.ceil(a + b * Math.log(x));
  }, [zoom, baseValue, zoomRange, a, b]);

  return Math.max(baseValue, value);
};

export const useMapBounds = () => {
  const map = useMap();
  const [bounds, setBounds] = useState(map.getBounds());

  useMapEvents({
    moveend: () => {
      setBounds(map.getBounds());
    },
    zoomend: () => {
      setBounds(map.getBounds());
    },
  });

  return bounds;
};

export const useZoom = () => {
  const map = useMap();
  const [zoom, setZoom] = useState(map.getZoom());

  useMapEvents({
    moveend: () => {
      setZoom(map.getZoom());
    },
    zoomend: () => {
      setZoom(map.getZoom());
    },
  });

  return zoom;
};

/**
 *
 * @param {Exclude<import('ol/layer/Layer').Options<import('ol/source').Source>, {
 *  source: import('ol/source').Source}>
 * } options
 * @param {() => import('ol/Feature').FeatureLike[]} featureGenerator
 * @param {any[]} deps
 * @returns
 */
export const useMapLayer = (options, featureGenerator, deps) => {
  const { map } = useContext(mapContext);

  const layer = useMemo(() => {
    const _layer = new WebGLLayer({
      ...options,
      source: null,
    });
    // _layer.addChangeListener('source', () => {
    //   console.log('source changed');
    //   _layer.changed();
    //   map.renderSync();
    // });
    // _layer.addChangeListener('style', () => {
    //   console.log('style changed');
    //   _layer.changed();
    //   map.renderSync();
    // });
    return _layer;
  }, []);

  const { source } = useMemo(() => {
    const features = featureGenerator();
    const _source = new VectorSource({
      features: new GeoJSON().readFeatures({
        type: 'FeatureCollection',
        features,
      }),
      format: new GeoJSON(),
    });
    return { features, source: _source };
  }, [deps]);

  useEffect(() => {
    layer?.setSource(source);
  }, [source, layer]);

  useEffect(() => {
    if (!map || !layer) return;
    map.addLayer(layer);
    return () => {
      map.removeLayer(layer);
    };
  }, [map, layer]);

  const setStyle = useCallback(
    /**
     * @param {import('ol/style/Style').StyleLike} style
     */
    (style) => {
      // map?.getLayers().forEach((l) => {
      //   if (l === layer) {
      //     l.set('style', style, true);
      //     l.changed();
      //   }
      // });
      layer?.set('style', style);
      layer?.getRenderer().changed();
      map?.render();
    },
    [layer, map],
  );

  return { layer, setStyle };
};

/**
 * @typedef {'light' | 'dark'} Theme
 */

/**
 *
 * @returns {[Theme, (theme: Theme) => void]} current theme and a function to set the theme
 */
export const useTheme = () => {
  /**
   * @type {Theme}
   */
  const _theme = localStorage.getItem('theme') || 'light';

  /**
   * @type {React.RefObject<MutationObserver>}
   */
  const observer = useRef(
    new MutationObserver((mutations) => {
      mutations.forEach(function (mutation) {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'data-theme'
        ) {
          _setTheme(mutation.target.getAttribute('data-theme'));
        }
      });
    }),
  );

  /**
   * @type {import('../types/types').State<Theme>}
   */
  const [theme, _setTheme] = useState(
    document.body.getAttribute('data-theme') || _theme,
  );

  useEffect(() => {
    const body = document.body;
    if (body.getAttribute('data-theme') === null) {
      body.setAttribute('data-theme', _theme);
    }
  }, [document.body]);

  useEffect(() => {
    const body = document.body;

    observer.current.observe(body, {
      attributes: true,
    });

    return () => {
      observer.current.disconnect();
    };
  }, [document.body, observer]);

  /**
   * @param {Theme} theme
   * @returns {void}
   */
  const setTheme = useCallback(
    (theme) => {
      localStorage.setItem('theme', theme);
      document.body.setAttribute('data-theme', theme);
    },
    [document.body],
  );

  return [theme, setTheme];
};
