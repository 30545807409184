import React, { useImperativeHandle, useRef, useState } from 'react';
import Workbook, {
  EmptySelection,
  createFormulaParser,
} from 'react-spreadsheet';
import { DownloadIcon, PlusIcon, UploadIcon } from '@iconicicons/react';
import { utils, writeFileXLSX, read } from 'xlsx';
import { useTheme } from './hooks';

/**
 * @template {import('react-spreadsheet').CellBase} CellType
 * @param {import('react-spreadsheet').Matrix<CellType>} values
 * @param {[number, number]} size
 * @param {number[]} readonlyColumns
 * @returns {import('react-spreadsheet').Matrix<CellType>}
 */
const fillCells = (values = [], size = [10, 10], readonlyColumns = []) => {
  return Array.from({ length: Math.max(size[0], values.length) }, (_, i) => {
    const row = values?.length > i ? values[i] : [];
    return Array.from({ length: size[1] }, (_, j) => {
      if (row.length > j) {
        if (readonlyColumns.includes(j)) {
          row[j].readOnly = true;
        }
        return row[j];
      }
      return {
        value: '',
        style: {},
        readOnly: readonlyColumns.includes(j),
      };
    });
  });
};

/**
 * @template {import('react-spreadsheet').CellBase} CellType
 * @param {import('react-spreadsheet').Matrix<CellType>} data
 * @param {Omit<FormulaParserConfig, "onCell" | "onRange">} customParsers
 * @returns
 */
const formulaParsers = (data, customParsers) => {
  return createFormulaParser(data, { POINT: undefined, ...customParsers });
};

const TableComponent = React.forwardRef((props, ref) => {
  return (
    <table id={props.tableId} ref={ref} {...props}>
      <tbody>{props.children}</tbody>
    </table>
  );
});
TableComponent.displayName = 'TableComponent';

/**
 *
 * @param {import('react-spreadsheet').Matrix<CellType>} matrix
 * @returns {[number, number]}
 */
const getMatrixSize = (matrix) => {
  return [matrix.length, matrix.sort((a, b) => b.length - a.length)[0].length];
};

/**
 * @template {import('react-spreadsheet').CellBase} CellType
 * @typedef {{
 *  getData: () => import('react-spreadsheet').Matrix<CellType>,
 *  setData: (values: import('react-spreadsheet').Matrix<CellType>) => void,
 *  getSelection: () => Selection | null,
 *  clear: () => void,
 * }} SpreadsheetRef<CellType>
 */

/**
 * @template {import('react-spreadsheet').CellBase} CellType
 * @typedef {{
 *  fileName: string,
 *  sheetName: string,
 *  size: [number, number],
 *  sheetProps: Exclude<import('react-spreadsheet').Props<CellType>, 'data' | 'createFormulaParser'>,
 *  data: import('react-spreadsheet').Matrix<CellType>,
 *  customParsers: Omit<FormulaParserConfig, "onCell" | "onRange">,
 *  onAddRow: (rows: number) => void,
 *  onAddColumn: (columns: number) => void,
 *  readonlyColumns?: number[],
 * }} SpreadsheetProps<CellType>
 */

/**
 * @template {import('react-spreadsheet').CellBase} CellType
 * @param {SpreadsheetProps<CellType>} props
 * @param {React.Ref<SpreadsheetRef<CellType>>} ref
 */
const _Spreadsheet = (props, ref) => {
  const {
    customParsers,
    fileName = 'sheet.xlsx',
    sheetProps,
    size = [10, 10],
    readonlyColumns = [],
  } = props;

  const [theme] = useTheme();

  /** @type {[import('react-spreadsheet').Matrix<CellType>, React.SetStateAction<import('react-spreadsheet').Matrix<CellType>>]} */
  const [data, setData] = useState(
    fillCells(props.data, size, readonlyColumns),
  );

  /** @type {import('react').Ref<Selection | null>} */
  const selectionRef = useRef(null);

  /** @type {React.Ref<HTMLTableElement>} */
  const tableRef = React.useRef(null);

  useImperativeHandle(
    ref,
    () => {
      return {
        getData: () => {
          return data.slice();
        },
        setData: (values) => {
          setData(fillCells(values, size, readonlyColumns));
        },
        getSelection: () => {
          return selectionRef.current;
        },
        clear: () => {
          setData(fillCells([], size, readonlyColumns));
        },
      };
    },
    [data, size, readonlyColumns],
  );

  const xport = React.useCallback(() => {
    const table = tableRef.current;
    const wb = utils.table_to_book(table);
    writeFileXLSX(wb, fileName);
  }, [tableRef]);

  /** @type {React.FormEventHandler<HTMLInputElement>} */
  const imprt = React.useCallback(
    (e) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const bstr = e.target.result;
        const wb = read(bstr);
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        const jsonData = utils.sheet_to_json(ws, { header: 1, raw: false });
        setData((p) =>
          fillCells(
            jsonData.map((row) =>
              row.map((cell) => ({ value: cell, style: {} })),
            ),
            getMatrixSize(p),
            readonlyColumns,
          ),
        );
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    },
    [setData, readonlyColumns],
  );

  return (
    <div>
      <div className="my-2">
        <div className="btn-group">
          <label htmlFor="import-file" className="btn btn-primary">
            <DownloadIcon /> Import
          </label>
          <input
            id="import-file"
            type="file"
            className="d-none"
            onInput={imprt}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
          <button className="btn btn-primary" onClick={xport}>
            <UploadIcon /> Export
          </button>
        </div>
      </div>
      <div className="d-flex">
        <Workbook
          darkMode={theme === 'dark'}
          Table={(props) => (
            <TableComponent tableId="Table2XLSX" ref={tableRef} {...props} />
          )}
          {...sheetProps}
          data={data}
          onSelect={(range) => {
            sheetProps.onSelect?.(range);
            if (range.equals(new EmptySelection())) {
              return;
            }
            // console.log({ range });
            selectionRef.current = range;
          }}
          onChange={(values) => {
            setData(values);
            sheetProps.onChange?.(values);
          }}
          createFormulaParser={(data) => formulaParsers(data, customParsers)}
        />
        <div className="h-100">
          <button
            className="btn btn-primary btn-sm"
            role="button"
            onClick={() => {
              props.onAddColumn?.(data[0].length + 1);
              const size = getMatrixSize(data);
              setData(fillCells(data, [size[0], size[1] + 1], readonlyColumns));
            }}
          >
            <PlusIcon />
          </button>
        </div>
      </div>
      <button
        className="btn btn-primary btn-sm"
        role="button"
        onClick={() => {
          props.onAddRow?.(data.length + 1);
          const size = getMatrixSize(data);
          setData(fillCells(data, [size[0] + 1, size[1]], readonlyColumns));
        }}
      >
        <PlusIcon />
      </button>
    </div>
  );
};

export const Spreadsheet = React.forwardRef(_Spreadsheet);
