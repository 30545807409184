import { useSelector, useDispatch } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';

import { scenarioActions } from '../scenarios/scenarioSlice';

import {
  useLazyGetContractsQuery,
  useCreateUpdateScenarioMutation,
  useLazyGetSegmentConstraintsQuery,
  useLazyGetPointConstraintsQuery,
  useLazyGetPricesQuery,
  useLazyGetBaseloadQuery,
  useLazyGetDayTradesQuery,
} from '../services/edgeApi';
import { useLocalStorage } from '../helpers/useLocalStorage';
import { toIsoDate } from '../utils/stringUtils';
import { DateSelector } from '../helpers/DateSelector';
import { DebouncedInput } from '../helpers/DebounceInput';
import { store } from '../store';
import { ReduxPointSearch } from '../search/ReduxPointSearch';
import { selectAllPoints } from '../plotter/plotterSelectors';
import { useBelowMap } from '../layouts/BelowMapContext';
import { ChevronDownIcon, ChevronUpIcon } from '@iconicicons/react';

const dateToday = toIsoDate(new Date());

/**
 * @typedef {{
 *  scenario?: import('./scenarioSlice').Scenario,
 *  onSuccess: (scenario: import('./scenarioSlice').Scenario) => void,
 *  onCancel: () => void,
 * }} EditScenarioProps
 */

/**
 * @param {EditScenarioProps} props
 * @returns {React.FC<EditScenarioProps>}
 */
export function EditScenario(props) {
  const { onSuccess, onCancel } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const deliveryPointId = useSelector(
    (state) => state.scenario.data.settings.optimization_settings?.delivery,
  );

  /** @type {string} */
  const flowDate = useSelector((state) => state.scenario.data.flow_date);

  /** @type {string} */
  const name = useSelector((state) => state.scenario.data.name);

  /** @type {string} */
  const description = useSelector((state) => state.scenario.data.description);

  /** @type {import('../scenarios/scenarioSlice').PriceSource} */
  const priceSource = useSelector(
    (state) => state.scenario.data.settings.price_source,
  );

  const strip = useSelector((state) => state.scenario.data.settings.strip);

  /** @type {boolean} */
  const simulateSpread = useSelector(
    (state) => state.scenario.data.settings.simulate_spread,
  );

  /** @type {import('../scenarios/scenarioSlice').OptimizationType} */
  const optimizationType = useSelector(
    (state) => state.scenario.data.settings.optimization_type,
  );

  /** @type {import('../scenarios/scenarioSlice').PriceType} */
  const priceType = useSelector(
    (state) => state.scenario.data.settings.price_type,
  );

  const allPoints = useSelector(selectAllPoints);

  /** @type {string | number} */
  const id = useSelector((state) => state.scenario.data.id);

  const [createUpdateScenario, { isLoading: isCreatingUpdating }] =
    useCreateUpdateScenarioMutation();
  const [pricesTrigger, { isFetching: fetchingPrices }] =
    useLazyGetPricesQuery();
  const [
    segmentConstraintsTrigger,
    { isFetching: segmentConstraintsFetching },
  ] = useLazyGetSegmentConstraintsQuery();
  const [pointConstraintsTrigger, { isFetching: pointConstraintsFetching }] =
    useLazyGetPointConstraintsQuery();
  const [activeContractsTrigger, { isFetching: fetchingActiveContracts }] =
    useLazyGetContractsQuery();
  const [baseloadTrigger, { isFetching: fetchingBaseload }] =
    useLazyGetBaseloadQuery();
  const [dayTradeTrigger, { isFetching: fetchingDayTrades }] =
    useLazyGetDayTradesQuery();

  const [multiCycle, setMultiCycle] = useLocalStorage('multiCycle', false);
  const [marketDate, setMarketDate] = useState(dateToday);
  const [constraintDate, setConstraintDate] = useState(dateToday);
  const [activeContractsDate, setActiveContractsDate] = useState(dateToday);
  const [baseloadDate, setBaseloadDate] = useState(dateToday);
  const [includeBaseload, setIncludeBaseload] = useState(false);
  const [includeDayTrades, setIncludeDayTrades] = useState(false);
  const [includeMarks, setIncludeMarks] = useState(false);
  const [includeStorage, setIncludeStorage] = useState(false);
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const location = useLocation();

  const { setOpen: setBelowMapOpen } = useBelowMap();

  const setScenario = () => {
    if (location?.state?.scenario) {
      // if coming from "modify this scenario", use the scenario data passed through the link - could also pass in props if needed
      // set the id to 'new' so it creates instead of updates
      /** @type {import('../scenarios/scenarioSlice').Scenario} */
      const scenarioFromLocation = location.state.scenario;
      location.state.scenario = null;

      dispatch(scenarioActions.setScenario({ scenario: scenarioFromLocation }));
      return;
    }

    if (props.scenario) {
      /** @type {import('../scenarios/scenarioSlice').Scenario} */
      const scenarioFromProps = props.scenario;
      dispatch(scenarioActions.setScenario({ scenario: scenarioFromProps }));
      return;
    }

    // if no scenario passed in through props or location, create a new one
    dispatch(scenarioActions.resetState());
  };

  useEffect(() => {
    setScenario();
    setBelowMapOpen(false);
  }, []);

  useEffect(() => {
    setMarketDate(new Date(flowDate));
    setConstraintDate(new Date(flowDate));
    setActiveContractsDate(new Date(flowDate));
    setBaseloadDate(new Date(flowDate));
  }, [flowDate]);

  const getTheUsual = async () => {
    const promises = [
      overwriteWithActiveContracts(),
      ...overwriteConstraints(),
      overwriteMarketPrices(includeMarks, includeStorage),
      overwriteTrades(includeBaseload, includeDayTrades),
    ];
    await Promise.all(promises);
    // TODO: get the cycle and add it into the name - it's part of the descriptions of the segment_constraints but should be more structured so we can recreate when and how we determined what was a constraint
    if (!name) {
      dispatch(
        scenarioActions.update({
          updateData: { name: `GD ${flowDate}` },
        }),
      );
    }
  };

  const overwriteConstraints = () => {
    return [overwriteSegmentConstraints(), overwritePointConstraints()];
  };

  const overwriteSegmentConstraints = async () => {
    try {
      const result = await segmentConstraintsTrigger({
        source: 'PLATTS',
        date: toIsoDate(constraintDate),
      }).unwrap();
      dispatch(
        scenarioActions.overwrite({
          componentType: 'segment_constraints',
          components: result,
        }),
      );
    } catch (e) {
      console.error(e);
    }
  };

  const overwritePointConstraints = async () => {
    try {
      const result = await pointConstraintsTrigger({
        source: 'PLATTS',
        date: toIsoDate(constraintDate),
      }).unwrap();
      dispatch(
        scenarioActions.overwrite({
          componentType: 'point_constraints',
          components: result,
        }),
      );
    } catch (e) {
      console.error(e);
    }
  };

  const overwriteMarketPrices = async (includeMarks, includeStorage) => {
    try {
      /** @type {import('../scenarios/scenarioSlice').Market[]} */
      const marketPrices = await pricesTrigger({
        date: toIsoDate(marketDate),
        priceSource: priceSource,
        strip: strip,
        bidAsk: priceType === 'BID_ASK',
      }).unwrap();
      dispatch(
        scenarioActions.overwrite({
          componentType: 'markets',
          components: marketPrices.filter(
            (market) => market.base_price !== null,
          ),
          applySettings: true,
        }),
      );
      if (includeMarks) {
        appendMarket({
          endpoint: 'mtm_marks/',
          date: toIsoDate(marketDate),
          priceSource: priceSource,
          bidAsk: priceType === 'BID_ASK',
        });
      }
      if (includeStorage) {
        appendMarket({
          endpoint: 'storage_marks/',
          date: toIsoDate(marketDate),
          priceSource: priceSource,
          bidAsk: priceType === 'BID_ASK',
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const appendMarket = async ({ endpoint, date, priceSource, bidAsk }) => {
    try {
      /** @type {import('../scenarios/scenarioSlice').Market[]} */
      const markets = await pricesTrigger({
        endpoint: endpoint,
        date: date,
        priceSource: priceSource,
        strip: strip,
        bidAsk: bidAsk,
      }).unwrap();

      dispatch(
        scenarioActions.appendComponents({
          componentType: 'markets',
          components: markets.filter((market) => market.base_price !== null),
          applySettings: true,
        }),
      );
    } catch (e) {
      console.error(e);
    }
  };

  const overwriteTrades = async (includeBaseload, includeDayTrades) => {
    try {
      dispatch(
        scenarioActions.overwrite({
          componentType: 'trades',
        }),
      );
      if (includeBaseload) {
        appendBaseload();
      }
      if (includeDayTrades) {
        appendDayTrades();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const appendBaseload = async () => {
    try {
      const result = await baseloadTrigger({
        gasDate: toIsoDate(baseloadDate),
        priceSource: priceSource,
        bidAsk: priceType === 'BID_ASK',
      }).unwrap();
      dispatch(
        scenarioActions.appendComponents({
          componentType: 'trades',
          components: Object.values(result),
        }),
      );
    } catch (e) {
      console.error(e);
    }
  };

  const appendDayTrades = async () => {
    try {
      const result = await dayTradeTrigger({
        gasDate: toIsoDate(baseloadDate),
        priceSource: priceSource,
        bidAsk: priceType === 'BID_ASK',
      }).unwrap();
      dispatch(
        scenarioActions.appendComponents({
          componentType: 'trades',
          components: Object.values(result),
        }),
      );
    } catch (e) {
      console.error(e);
    }
  };

  const overwriteWithActiveContracts = async () => {
    try {
      const result = await activeContractsTrigger({
        date: toIsoDate(activeContractsDate),
        include_details: true,
      }).unwrap();
      dispatch(
        scenarioActions.overwrite({
          componentType: 'contracts',
          components: Object.values(result),
        }),
      );
    } catch (e) {
      console.error(e);
    }
  };

  const pickPaths = async () => {
    // save scenario
    try {
      const payload = {
        ...store.getState().scenario.data,
        optimize: false,
        multiCycle,
      };
      const result = await createUpdateScenario(payload).unwrap();
      // naviagte to /scenarios/:id/all-paths
      navigate(`/scenarios/${result.id}/all-paths`);
    } catch (e) {
      toast.error('Something went wrong, please try again');
      console.error(JSON.stringify(e));
    }
  };

  const handleSubmit = async (evt, optimize = false) => {
    evt.preventDefault();
    // set "optimize: true" so it will optimize immediately
    try {
      const payload = {
        ...store.getState().scenario.data,
        optimize,
        multiCycle,
      };
      const result = await createUpdateScenario(payload).unwrap();
      onSuccess(result);
    } catch (e) {
      toast.error('Something went wrong, please try again');
      console.error(JSON.stringify(e));
    }
  };

  /**
   *
   * @param {string} value
   */
  const setName = (value) => {
    dispatch(scenarioActions.setName({ name: value }));
  };

  /**
   *
   * @param {string} value
   */
  const setDescription = (value) => {
    dispatch(scenarioActions.setDescription({ description: value }));
  };

  const setFlowDate = (value) => {
    dispatch(scenarioActions.setFlowDate({ flow_date: value }));
  };

  /**
   *
   * @param {import('../scenarios/scenarioSlice').PriceSource} value
   */
  const setPriceSource = (value) => {
    dispatch(scenarioActions.setPriceSource({ source: value }));
  };

  const setStrip = (value) => {
    dispatch(scenarioActions.setStrip({ source: value }));
  };

  /**
   *
   * @param {boolean} value
   */
  const setSimulateSpread = (value) => {
    dispatch(scenarioActions.setSimulateSpread({ simulateSpread: value }));
  };

  /**
   *
   * @param {import('../scenarios/scenarioSlice').OptimizationType} value
   */
  const setOptimizationType = (value) => {
    dispatch(scenarioActions.setPriceSource({ source: 'PLATTS_DAILY' }));
    dispatch(scenarioActions.setOptimizationType({ optimizationType: value }));
    dispatch(scenarioActions.setStrip({ strip: 'Next Day Gas' }));
  };

  const setDeliveryPoint = (pointId) => {
    dispatch(
      scenarioActions.setOptimizationSettings({
        optimizationSettings: {
          delivery: pointId,
        },
      }),
    );
  };

  return (
    <>
      <h5>{id === 'new' ? 'Creating Scenario' : `Editing Scenario #${id}`}</h5>

      <div id="main-scenario-settings">
        <div className="form-floating mb-3">
          <DebouncedInput
            value={name}
            name="name"
            id="name"
            className="form-control"
            onChange={(value) => setName(value)}
          />
          <label>Name</label>
        </div>

        <div className="mb-3">
          <div className="input-group">
            <div className="form-floating">
              <select
                className="form-select h-100"
                value={priceSource || 'PLATTS_DAILY'}
                onChange={(evt) => setPriceSource(evt.target.value)}
              >
                <option value="PLATTS_DAILY">Platts Daily</option>
                <option value="ICE_DAILY">ICE Daily</option>
                <option value="ICE_LIVE">ICE Live</option>
                <option value="ICE_FUTURE">ICE Futures</option>
                <option value="NONE">None</option>
              </select>
              <label>Price Source</label>
            </div>
            {['ALL_PATHS_TO_POINT', 'BEST_PATHS_TO_POINT'].includes(
              optimizationType,
            ) && (
              <div className="input-group-text btn btn-default bg-light text-primary border border-primary align-items-center">
                Simulate Spread
                <div className="form-switch">
                  <input
                    onChange={(evt) => setSimulateSpread(evt.target.checked)}
                    className="form-check-input"
                    type="checkbox"
                    id="simulate-spread-input"
                    checked={simulateSpread}
                  />
                </div>
              </div>
            )}
          </div>

          {priceSource === 'ICE_LIVE' && (
            <div className="btn-group w-100 mt-2" role="group">
              <input
                type="radio"
                className="btn-check"
                name="last-trade-button"
                id="last-trade-button"
                readOnly
                checked={priceType === 'LAST_TRADE'}
                onClick={() =>
                  dispatch(
                    scenarioActions.setPriceType({ priceType: 'LAST_TRADE' }),
                  )
                }
              />
              <label
                className="btn btn-outline-primary"
                htmlFor="last-trade-button"
              >
                Last Trade
              </label>

              <input
                type="radio"
                className="btn-check"
                name="bid-ask-button"
                id="bid-ask-button"
                readOnly
                checked={priceType === 'BID_ASK'}
                onClick={() =>
                  dispatch(
                    scenarioActions.setPriceType({ priceType: 'BID_ASK' }),
                  )
                }
              />
              <label
                className="btn btn-outline-primary"
                htmlFor="bid-ask-button"
              >
                Bid / Offer
              </label>
            </div>
          )}
          {priceSource === 'ICE_LIVE' && (
            <div className="input-group">
              <select
                className="form-select h-100"
                value={strip || 'Next Day Gas'}
                onChange={(evt) => setStrip(evt.target.value)}
              >
                <option value="Next Day Gas">Next Day Gas</option>
                <option value="Same Day">Same Day</option>
              </select>
            </div>
          )}

          {priceSource === 'ICE_FUTURE' && (
            <div className="btn-group w-100 mt-2" role="group">
              <input
                type="radio"
                className="btn-check"
                name="last-trade-button"
                id="last-trade-button"
                readOnly
                checked={priceType === 'LAST_TRADE'}
                onClick={() =>
                  dispatch(
                    scenarioActions.setPriceType({ priceType: 'LAST_TRADE' }),
                  )
                }
              />
              <label
                className="btn btn-outline-primary"
                htmlFor="last-trade-button"
              >
                Last Settled
              </label>

              <input
                type="radio"
                className="btn-check"
                name="bid-ask-button"
                id="bid-ask-button"
                readOnly
                checked={priceType === 'BID_ASK'}
                onClick={() =>
                  dispatch(
                    scenarioActions.setPriceType({ priceType: 'BID_ASK' }),
                  )
                }
              />
              <label
                className="btn btn-outline-primary"
                htmlFor="bid-ask-button"
              >
                Bid / Offer
              </label>
            </div>
          )}
        </div>
      </div>

      <p
        role="button"
        className="text-center text-muted small"
        onClick={() => setShowAdvancedSettings((p) => !p)}
      >
        Advanced settings{' '}
        {showAdvancedSettings ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </p>

      <div
        id="advanced-scenario-settings"
        className={showAdvancedSettings ? 'show' : 'collapse'}
      >
        <div className="form-floating mb-3">
          <DebouncedInput
            value={description}
            name="description"
            id="description"
            className="form-control"
            textArea
            onChange={(value) => setDescription(value)}
          />
          <label>Description</label>
        </div>

        <div className="form-floating mb-3">
          <select
            className="form-select"
            value={optimizationType || 'DEFAULT'}
            onChange={(evt) => setOptimizationType(evt.target.value)}
          >
            <option value="DEFAULT">Default</option>
            <option value="BEST_PATHS_TO_POINT">Single Delivery</option>
            {/* <option value="ALL_PATHS_TO_POINT">All paths to a point</option> */}
          </select>
          <label>Optimization Type</label>
        </div>

        {['ALL_PATHS_TO_POINT', 'BEST_PATHS_TO_POINT'].includes(
          optimizationType,
        ) && (
          <div className="form-floating mb-3">
            <ReduxPointSearch
              value={allPoints[deliveryPointId]?.name}
              handler={(point) => setDeliveryPoint(point.id)}
            />
            <label>Delivery Point</label>
          </div>
        )}

        <legend>Scenario Settings</legend>
        <div className="parent-form gap-1 d-flex flex-column mb-3">
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="baseload-checkbox"
              onChange={(evt) => setIncludeBaseload(evt.target.checked)}
              checked={includeBaseload}
            />
            <label>Include Baseload</label>
          </div>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="daytrades-checkbox"
              onChange={(evt) => setIncludeDayTrades(evt.target.checked)}
              checked={includeDayTrades}
            />
            <label>Include Day Trades</label>
          </div>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="marks-checkbox"
              onChange={(evt) => setIncludeMarks(evt.target.checked)}
              checked={includeMarks}
            />
            <label>Include Marks</label>
          </div>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="storage-checkbox"
              onChange={(evt) => setIncludeStorage(evt.target.checked)}
              checked={includeStorage}
            />
            <label>Include Storage</label>
          </div>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="storage-checkbox"
              onChange={(evt) => setMultiCycle(evt.target.checked)}
              checked={multiCycle}
            />
            <label>Multi Cycle</label>
          </div>
        </div>
      </div>

      <DateSelector
        className="mb-3"
        onChange={(e) => setFlowDate(toIsoDate(new Date(e.target.value)))}
        inputProps={{ name: 'flow_date', defaultValue: flowDate }}
        onConfirm={getTheUsual}
        isLoading={
          segmentConstraintsFetching ||
          pointConstraintsFetching ||
          fetchingActiveContracts ||
          fetchingPrices ||
          fetchingBaseload ||
          fetchingDayTrades
        }
        buttonText="Get Market Conditions"
      />

      <SaveOptimizeButton
        pickPaths={pickPaths}
        handleSubmit={handleSubmit}
        isLoading={isCreatingUpdating}
        multiCycle={multiCycle}
        setMultiCycle={setMultiCycle}
      />

      <button onClick={onCancel} className="btn mb-3 w-100 btn-outline-warning">
        Cancel
      </button>
    </>
  );
}

/**
 *
 * @typedef {{
 *  pickPaths: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
 *  handleSubmit: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>, optimize?: boolean) => void,
 *  isLoading: boolean,
 *  multiCycle: boolean,
 *  setMultiCycle: (value: boolean) => void,
 * }} SaveOptimizeButtonProps
 *
 * @param {SaveOptimizeButtonProps} props
 * @returns {React.FC<SaveOptimizeButtonProps>}
 */
const SaveOptimizeButton = ({
  pickPaths,
  handleSubmit,
  isLoading,
  // multiCycle,
  // setMultiCycle,
}) => {
  return (
    <div className="btn-group mb-3 mt-3 w-100">
      <button
        className="btn btn-primary form-control"
        disabled={isLoading}
        onClick={handleSubmit}
      >
        {isLoading && (
          <span
            className="spinner-border spinner-border-sm me-1"
            role="status"
            aria-hidden="true"
          />
        )}
        Save
      </button>
      <button
        className="btn btn-primary form-control"
        disabled={isLoading}
        onClick={pickPaths}
      >
        {isLoading && (
          <span
            className="spinner-border spinner-border-sm me-1"
            role="status"
            aria-hidden="true"
          />
        )}
        Pick Paths
      </button>
      <button
        className="btn btn-primary form-control"
        disabled={isLoading}
        onClick={(evt) => handleSubmit(evt, true)}
      >
        {isLoading && (
          <span
            className="spinner-border spinner-border-sm me-1"
            role="status"
            aria-hidden="true"
          />
        )}
        Optimize
      </button>
    </div>
  );
};
