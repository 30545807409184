import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectDeletedSegmentConstraints,
  selectFlowDate,
  selectSegmentConstraints,
} from '../scenarios/scenarioSlice';
import { useScenarioFunctions } from './useScenarioFunctions';
import { useLazyGetSegmentConstraintsQuery } from '../services/edgeApi';
import { toIsoDate } from '../utils/stringUtils';

export const useScenarioSegmentConstraints = () => {
  const segmentConstraints = useSelector(selectSegmentConstraints);
  const deletedSegmentConstraints = useSelector(
    selectDeletedSegmentConstraints,
  );
  const flowDate = useSelector(selectFlowDate);

  const [constraintDate, setConstraintDate] = useState(new Date(flowDate));

  const [segmentConstraintsTrigger, { isFetching, isLoading }] =
    useLazyGetSegmentConstraintsQuery();

  useEffect(() => {
    setConstraintDate(new Date(flowDate));
  }, [flowDate]);

  const {
    saveItem,
    append,
    overwrite,
    removeItem,
    bulkRemove,
    restoreItems,
    bulkUpdate,
  } = useScenarioFunctions('segment_constraints');

  const refreshSegmentConstraints = async () => {
    try {
      /**
       * @type {import('../scenarios/scenarioSlice').SegmentConstraint[]}
       */
      const constraints = await segmentConstraintsTrigger({
        source: 'PLATTS',
        date: toIsoDate(constraintDate),
      }).unwrap();
      const userConstraints = segmentConstraints.filter(
        (constraint) => constraint.source_type === 'USER_DEFINED',
      );
      overwrite([...userConstraints, ...constraints]);
    } catch (e) {
      console.error(e);
    }
  };

  return {
    segmentConstraints,
    deletedSegmentConstraints,
    constraintDate,
    isFetching,
    isLoading,
    refreshSegmentConstraints,
    setConstraintDate,
    removeItem,
    bulkRemove,
    restoreItems,
    saveItem,
    append,
    overwrite,
    bulkUpdate,
  };
};
