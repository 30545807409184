import { CloseIcon, DownloadIcon } from '@iconicicons/react';
import { ReusableModal } from '../modals/ReusableModal';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { selectFilteredIOCContracts } from '../ioc/iocSlice';
import { MissingPointBadge, PointBadge } from '../helpers/PointBadge';
import { useTheme } from '../helpers/hooks';
import { selectAllPoints } from '../plotter/plotterSelectors';
import { numberFormatter } from '../helpers/formatters';
import { utils, writeFileXLSX } from 'xlsx';

/**
 * @typedef {{
 *  segmentKey: string;
 *  onClose: () => void;
 *  tspName: string;
 *  fromPoint: import('../plotter/plotterSlice').Point;
 *  toPoint: import('../plotter/plotterSlice').Point;
 *  iocContracts: import('../ioc/iocSlice').IOCContract[];
 * }} IOCSegmentModalProps
 */

/**
 *
 * @param {IOCSegmentModalProps} props
 * @returns
 */
export const IOCSegmentModal = ({
  segmentKey,
  onClose,
  tspName,
  fromPoint,
  toPoint,
}) => {
  const iocContracts = useSelector(selectFilteredIOCContracts);
  const allPoints = useSelector(selectAllPoints);

  const contractLookup = useMemo(
    () =>
      iocContracts.reduce(
        /**
         *
         * @param {{[key: string]: import('../ioc/iocSlice').IOCContract[]}} acc
         * @param {import('../ioc/iocSlice').IOCContract} contract
         * @returns
         */
        (acc, contract) => {
          contract.path_node_ids.forEach(([from, to]) => {
            const key = `${from}-${to}`;
            const reverseKey = `${to}-${from}`;
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(contract);
            if (!acc[reverseKey]) {
              acc[reverseKey] = [];
            }
            acc[reverseKey].push(contract);
          });
          return acc;
        },
        {},
      ),
    [iocContracts],
  );

  const secondaryRightsLookup = useMemo(
    () =>
      iocContracts.reduce(
        /**
         *
         * @param {{[key: string]: import('../ioc/iocSlice').IOCContract[]}} acc
         * @param {import('../ioc/iocSlice').IOCContract} contract
         * @returns
         */
        (acc, contract) => {
          contract.receipt_zones.forEach((zone_id) => {
            const key = `${zone_id}`;
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(contract);
          });
          contract.delivery_zones.forEach((zone_id) => {
            const key = `${zone_id}`;
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(contract);
          });
          return acc;
        },
        {},
      ),
    [iocContracts],
  );

  /**
   * @type {{
   *  primary: Array<import('../ioc/iocSlice').IOCContract>;
   *  secondary: Array<import('../ioc/iocSlice').IOCContract>;
   * }}
   */
  const selectedContracts = useMemo(() => {
    const primary = (contractLookup[segmentKey] ?? []).reduce(
      (acc, contract) => {
        acc[contract.id] = contract;
        return acc;
      },
      {},
    );
    const secondary = (
      secondaryRightsLookup[fromPoint?.receipt_zone?.toString()] ?? []
    ).reduce((acc, contract) => {
      if (!primary[contract.id]) {
        acc[contract.id] = contract;
      }
      return acc;
    }, {});

    return {
      primary: Object.values(primary),
      secondary: Object.values(secondary),
    };
  }, [segmentKey, contractLookup]);

  const contractsByOwner = useMemo(() => {
    /**
     *
     * @param {import('../ioc/iocSlice').IOCContract[]} contracts
     */
    const groupByOwner = (contracts) => {
      return contracts.reduce(
        /**
         *
         * @param {{[key: string]: import('../ioc/iocSlice').IOCContract[]}} acc
         * @param {import('../ioc/iocSlice').IOCContract} contract
         * @returns {{[key: string]: import('../ioc/iocSlice').IOCContract[]}}
         */
        (acc, contract) => {
          if (!acc[contract.owner]) {
            acc[contract.owner] = [];
          }
          acc[contract.owner].push(contract);
          return acc;
        },
        {},
      );
    };

    return {
      primary: groupByOwner(selectedContracts.primary),
      secondary: groupByOwner(selectedContracts.secondary),
    };
  }, [selectedContracts]);

  const saveAsExcel = () => {
    const headers = [
      'Owner',
      'K ID',
      'Orientation',
      'Rate Schedule',
      'Direction',
      'Loc Name',
      'Point Volume',
      'Max TRP',
      'Start Date',
      'End Date',
    ];

    const data = Object.keys(contractsByOwner.primary).reduce((acc, owner) => {
      const contracts = contractsByOwner.primary[owner];
      contracts.forEach((contract) => {
        acc = acc.concat(
          contract.receipt_volumes.map(({ point, vol }) => [
            contract.owner,
            contract.k_id,
            contract.orientation,
            contract.raw_rate_schedule,
            'R',
            allPoints[point].name,
            vol,
            contract.max_daily_quantity,
            new Date(contract.start_date).toLocaleDateString(),
            new Date(contract.end_date).toLocaleDateString(),
          ]),
        );
        acc = acc.concat(
          contract.delivery_volumes.map(({ point, vol }) => [
            contract.owner,
            contract.k_id,
            contract.orientation,
            contract.raw_rate_schedule,
            'D',
            allPoints[point].name,
            vol,
            contract.max_daily_quantity,
            new Date(contract.start_date).toLocaleDateString(),
            new Date(contract.end_date).toLocaleDateString(),
          ]),
        );
      });
      return acc;
    }, []);

    const ws = utils.json_to_sheet([headers, ...data], { skipHeader: true });
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Contracts');

    writeFileXLSX(wb, `contracts-${segmentKey}.xlsx`);
  };

  const headerHeight = 65 /* Column headers */ + 56; /* Download button */

  const headers = [
    'Owner',
    'K ID',
    'Orientation',
    'Rate Schedule',
    'Receipt',
    'Delivery',
    'Max TRP',
    'Start Date',
    'End Date',
  ];

  return (
    <ReusableModal
      key={segmentKey}
      isOpen={segmentKey !== null}
      onClose={onClose}
      size="xl"
      scrollable
      header={
        <div className="d-flex flex-column gap-3">
          <div className="d-flex justify-content-between align-items-center">
            <CloseIcon role="button" onClick={onClose} />
          </div>
          <div>
            <h5>
              {tspName} zone{' '}
              {fromPoint?.receipt_zone_name ?? toPoint?.delivery_zone_name}
            </h5>
            <h4>
              From {fromPoint?.name} to {toPoint?.name}
            </h4>
          </div>
        </div>
      }
    >
      <div>
        <table className="table table-bordered">
          <thead
            style={{
              position: 'sticky',
              zIndex: 2,
              top: -17,
              height: headerHeight,
              borderLeft: '4px solid var(--bs-border-color)',
              backgroundColor: 'var(--bs-table-bg)',
            }}
          >
            <tr
              style={{
                borderBottom: 0,
              }}
            >
              <th colSpan={headers.length}>
                <button
                  className="btn btn-outline float-end"
                  onClick={saveAsExcel}
                >
                  Download <DownloadIcon />
                </button>
              </th>
            </tr>
            <tr
              style={{
                borderTop: 0,
              }}
            >
              {headers.map((header) => (
                <th key="header">{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.keys(contractsByOwner.primary).length > 0 && (
              <tr
                style={{
                  position: 'sticky',
                  zIndex: 2,
                  top: headerHeight - 18,
                }}
              >
                <th
                  colSpan={headers.length}
                  className="text-center text-dark"
                  style={{
                    borderLeft: `4px solid hsl(60,100%,75%)`,
                    backgroundColor: 'hsl(60,100%,75%)',
                  }}
                >
                  Primary
                </th>
              </tr>
            )}
            <IOCContractsTableRows
              contractsByOwner={contractsByOwner.primary}
              color={'hsl(60,100%,75%)'}
              headerHeight={headerHeight + 41}
            />
            {Object.keys(contractsByOwner.secondary).length > 0 && (
              <tr
                style={{
                  position: 'sticky',
                  zIndex: 2,
                  top: headerHeight - 18,
                }}
              >
                <th
                  colSpan={headers.length}
                  className="text-center text-dark"
                  style={{
                    borderLeft: `4px solid hsl(200,100%,75%)`,
                    backgroundColor: 'hsl(200,100%,75%)',
                  }}
                >
                  Secondary
                </th>
              </tr>
            )}
            <IOCContractsTableRows
              contractsByOwner={contractsByOwner.secondary}
              color={'hsl(200,100%,75%)'}
              headerHeight={headerHeight + 41}
            />
          </tbody>
        </table>
      </div>
    </ReusableModal>
  );
};

/**
 *
 * @param {{
 *  contractsByOwner: {[key: string]: import('../ioc/iocSlice').IOCContract[]};
 *  color: string;
 *  headerHeight: number;
 * }} props
 * @returns
 */
const IOCContractsTableRows = ({ contractsByOwner, color, headerHeight }) => {
  const allPoints = useSelector(selectAllPoints);
  const [theme] = useTheme();

  return Object.keys(contractsByOwner).flatMap((owner) => {
    const contracts = contractsByOwner[owner] ?? [];
    return contracts?.map((contract, index) => (
      <tr key={contract.id} style={{ borderLeft: `4px solid ${color}` }}>
        {index === 0 && (
          <th scope="row" rowSpan={contracts.length}>
            <span style={{ position: 'sticky', top: headerHeight, zIndex: 0 }}>
              {contract.owner}
            </span>
          </th>
        )}
        <td>{contract.k_id}</td>
        <td>{contract.orientation}</td>
        <td>{contract.raw_rate_schedule}</td>
        <td>
          {contract.receipt_volumes.map(({ point, vol }) => (
            <PointBadge
              point={allPoints[point]}
              extra_text={`${numberFormatter(vol, 0)} Dth`}
              key={point.toString()}
              theme={theme}
            />
          ))}
          {contract.missing_points.receipt.map((point) => (
            <MissingPointBadge
              name={point.name}
              extra_text={`${numberFormatter(point.volume ?? 0, 0)} Dth`}
              theme={theme}
              key={point}
            />
          ))}
        </td>
        <td>
          {contract.delivery_volumes.map(({ point, vol }) => (
            <PointBadge
              point={allPoints[point]}
              extra_text={`${numberFormatter(vol, 0)} Dth`}
              key={point.toString()}
              theme={theme}
            />
          ))}
          {contract.missing_points.delivery.map((point) => (
            <MissingPointBadge
              name={point.name}
              extra_text={`${numberFormatter(point.volume ?? 0, 0)} Dth`}
              theme={theme}
              key={point}
            />
          ))}
        </td>
        <td>{numberFormatter(contract.max_daily_quantity)}</td>
        <td>{new Date(contract.start_date).toLocaleDateString()}</td>
        <td>{new Date(contract.end_date).toLocaleDateString()}</td>
      </tr>
    ));
  });
};
