import React, { forwardRef } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Spreadsheet } from './Spreadsheet';
import { CloseIcon, MaximizeIcon, MinimizeIcon } from '@iconicicons/react';

/**
 * @template {import('react-spreadsheet').CellBase} CellType
 * @param {import("./Spreadsheet").SpreadsheetProps<CellType> & {
 *  toggle: () => void,
 *  isOpen: boolean,
 *  children: React.ReactNode,
 *  title: React.ReactNode,
 *  subTitle: React.ReactNode,
 * }} props
 * @param {React.Ref<import("./Spreadsheet").SpreadsheetRef<CellType>>} ref
 * @returns
 */
const _SpreadsheetModal = (props, ref) => {
  const { toggle, isOpen, children, title, subTitle, ...spreadSheetProps } =
    props;

  const [fullscreen, setFullscreen] = React.useState(false);

  return (
    <Modal
      size="xl"
      fullscreen={fullscreen}
      scrollable
      isOpen={isOpen}
      toggle={toggle}
    >
      <div
        style={{
          borderBottom: '1px solid var(--bs-border-color)',
          padding: '0.5rem 1rem',
          height: '4rem',
        }}
        className="w-100 d-flex justify-content-between align-items-center"
      >
        <h5 className="modal-title">{title}</h5>
        <div>
          {fullscreen ? (
            <MinimizeIcon role="button" onClick={() => setFullscreen(false)} />
          ) : (
            <MaximizeIcon role="button" onClick={() => setFullscreen(true)} />
          )}
          <CloseIcon role="button" onClick={toggle} />
        </div>
      </div>
      <ModalBody>
        {subTitle}
        <Spreadsheet ref={ref} {...spreadSheetProps} />
      </ModalBody>
      <ModalFooter>{children}</ModalFooter>
    </Modal>
  );
};

export const SpreadsheetModal = forwardRef(_SpreadsheetModal);
