import React, { useCallback } from 'react';
import { useDebouncedCallback } from '../../helpers/hooks';
import { useSelector } from 'react-redux';
import { selectAllPoints } from '../../plotter/plotterSelectors';
import { SpreadsheetModal } from '../../helpers/SpreadsheetModal';
import { selectMarkets } from '../scenarioSlice';

/**
 * @typedef {{
 *  POINT: 0,
 *  LOC_NAME: 1,
 *  DESCRIPTION: 2,
 *  BUY_SELL: 3,
 *  MAX_VOLUME: 4,
 *  PRICE: 5,
 * }} MarketSpreadsheetColumns
 */

/**
 *
 * Spreadsheet column indexers
 *
 * @type {MarketSpreadsheetColumns}
 */
const COLUMNS = {
  POINT: 0,
  LOC_NAME: 1,
  DESCRIPTION: 2,
  BUY_SELL: 3,
  MAX_VOLUME: 4,
  PRICE: 5,
};

const REQUIRED_COLUMNS = [COLUMNS.POINT, COLUMNS.BUY_SELL, COLUMNS.PRICE];

/**
 * @type {Record<MarketSpreadsheetColumns, string>}
 */
const COLUMNS_LABELS = {
  [COLUMNS.POINT]: 'Point',
  [COLUMNS.LOC_NAME]: 'Location',
  [COLUMNS.DESCRIPTION]: 'Description',
  [COLUMNS.BUY_SELL]: 'Buy/Sell',
  [COLUMNS.MAX_VOLUME]: 'Max Volume',
  [COLUMNS.PRICE]: 'Market Price',
};

/**
 * @typedef {{
 *  isOpen: boolean;
 *  onToggle: () => void;
 *  onImport: (markets: import('../scenarioSlice').Market[]) => void;
 *  onOverwrite: (markets: import('../scenarioSlice').Market[]) => void;
 * }} MarketSpreadsheetProps
 */

/**
 *
 * @type {React.FC<MarketSpreadsheetProps>}
 */
export const MarketSpreadsheet = ({
  isOpen,
  onToggle,
  onImport,
  onOverwrite,
}) => {
  const allPoints = useSelector(selectAllPoints);

  const markets = useSelector(selectMarkets);

  /** @type {React.Ref<import('../helpers/Spreadsheet').SpreadsheetRef<import('react-spreadsheet').CellBase>>} */
  const sheetRef = React.useRef(null);

  const parseBuySell = useCallback(
    /**
     *
     * @param {string} buySell
     * @returns {'BUY' | 'SELL' | 'BOTH'}
     */
    (buySell) => {
      const upper = buySell.toUpperCase();
      if (['BUY', 'LIFT', 'B'].includes(upper)) {
        return 'BUY';
      }
      if (['SELL', 'HIT', 'S'].includes(upper)) {
        return 'SELL';
      }
      return 'BOTH';
    },
    [],
  );

  const parseData = useCallback(
    /**
     *
     * @param {import('react-spreadsheet').Matrix<import('react-spreadsheet').CellBase>} data
     * @returns {import('../scenarioSlice').Market[]}
     */
    (data) => {
      /** @type {import('../scenarioSlice').Market[]} */
      const parsedMarkets = data
        .filter((row) => {
          return REQUIRED_COLUMNS.every((col) => row[col].value !== '');
        })
        .map((row) => {
          const price = parseFloat(row[COLUMNS.PRICE].value);
          return {
            point: parseInt(row[COLUMNS.POINT].value),
            description: row[COLUMNS.DESCRIPTION].value,
            buy_sell: parseBuySell(String(row[COLUMNS.BUY_SELL].value)),
            max_volume: parseFloat(row[COLUMNS.MAX_VOLUME].value) ?? 10000,
            price,
            base_price: price,
            adder: 0,
            price_source: 'USER_DEFINED',
            price_explanation: 'User defined price',
          };
        });
      return parsedMarkets;
    },
    [],
  );

  /**
   * @type {(data: import('react-spreadsheet').Matrix<import('react-spreadsheet').CellBase>) => void}
   */
  const onChangeSpreadsheetDebounce = useDebouncedCallback(
    (data) => {
      const newData = data.map((row) => {
        let newRow = [...row];
        if (
          row[COLUMNS.POINT].value !== '' &&
          !isNaN(Number(row[COLUMNS.POINT].value))
        ) {
          newRow[COLUMNS.LOC_NAME].value =
            allPoints[row[COLUMNS.POINT].value]?.name ?? '';
        }
        return newRow;
      });
      sheetRef.current?.setData(newData);
    },
    1000,
    [allPoints],
  );

  /**
   *
   * @param {import('react-spreadsheet').Matrix<import('react-spreadsheet').CellBase>} data
   */
  const confirmImport = (data) => {
    onImport(parseData(data));
  };

  /**
   *
   * @param {import('react-spreadsheet').Matrix<import('react-spreadsheet').CellBase>} data
   */
  const overwrite = (data) => {
    onOverwrite(parseData(data));
  };

  return (
    <SpreadsheetModal
      ref={sheetRef}
      isOpen={isOpen}
      toggle={onToggle}
      title="Import Markets"
      subTitle={<p className="text-muted">Required fields: *</p>}
      data={[]}
      readonlyColumns={[COLUMNS.LOC_NAME]}
      sheetProps={{
        onChange: onChangeSpreadsheetDebounce,
        columnLabels: Object.keys(COLUMNS_LABELS).map((col) => {
          return `${COLUMNS_LABELS[col]}${
            REQUIRED_COLUMNS.includes(Number(col)) ? ' *' : ''
          }`;
        }),
      }}
      fileName="markets.xlsx"
      sheetName="Markets"
    >
      <button
        className="btn btn-danger"
        onClick={() => {
          sheetRef.current?.setData([]);
        }}
      >
        Clear
      </button>
      <button
        className="btn btn-primary"
        onClick={() => {
          const scenarioMarkets = markets.map((market) => [
            { value: market.point },
            { value: allPoints[market.point].name },
            { value: market.description },
            { value: market.buy_sell },
            { value: market.max_volume },
            { value: market.price },
          ]);
          const newMarkets = [
            ...sheetRef.current
              .getData()
              .filter((row) => row[COLUMNS.POINT].value !== ''),
            ...scenarioMarkets,
          ];
          sheetRef.current?.setData(newMarkets);
        }}
      >
        Pull From Scenario
      </button>
      <button
        className="btn btn-primary"
        onClick={() => {
          overwrite(sheetRef.current.getData());
          sheetRef.current.clear();
        }}
      >
        Overwrite
      </button>
      <button
        className="btn btn-success"
        onClick={() => {
          confirmImport(sheetRef.current.getData());
          sheetRef.current.clear();
        }}
      >
        Confirm Import
      </button>
    </SpreadsheetModal>
  );
};
