import {
  ChevronDownIcon,
  ChevronUpIcon,
  EditIcon,
  FilterIcon,
  TrashIcon,
  UndoIcon,
  ZoomInIcon,
} from '@iconicicons/react';
import React, { useCallback, useMemo, useState } from 'react';
import { deepEqual } from '../../utils/objectUtils';
import { useDebouncedCallback } from '../../helpers/hooks';
import { useSelector } from 'react-redux';
import {
  selectAllNodes,
  selectAllPoints,
} from '../../plotter/plotterSelectors';
import { usePanMap } from '../../plotter/usePanMap';
import { numberFormatter, priceFormatter } from '../../helpers/formatters';
import { PriceInput } from '../../helpers/PriceInput';
import { DebouncedInput } from '../../helpers/DebounceInput';

/**
 * @typedef {{
 *  trades: import("../scenarioSlice").Trade[];
 *  deletedTrades: import("../scenarioSlice").Trade[];
 *  onDelete: (row: import("../scenarioSlice").Trade) => void;
 *  onBulkDelete: (ids: string[]) => void;
 *  onRestore: (ids: string[]) => void;
 *  onBulkUpdate: (ids: string[], patch: Partial<import("../scenarioSlice").Trade>) => void;
 *  isEditing: boolean;
 *  onChange: (trade: import('../scenarioSlice').Trade) => void;
 * }} TradeTableProps
 */

/**
 *
 * @param { TradeTableProps } props
 * @returns { React.FC<TradeTableProps> }
 */
export const TradeTable = ({
  trades,
  deletedTrades,
  isEditing,
  onDelete,
  onBulkDelete,
  onBulkUpdate,
  onChange: _onChange,
  onRestore,
}) => {
  const panMap = usePanMap();
  const allPoints = useSelector(selectAllPoints);
  const allNodes = useSelector(selectAllNodes);
  const [filters, setFilters] = useState({
    point: '',
    pipeline: '',
    description: '',
    buy_sell: '',
    volume: ['', ''],
    price: ['', ''],
  });

  /**
   * @type {import('../../types/types').State<{[key: string]: boolean | undefined}>}
   */
  const [selectedTrades, setSelectedTrades] = useState({});
  const [showDeleted, setShowDeleted] = useState(false);

  /**
   *
   * @param {import('../scenarioSlice').Trade} trade
   * @param {boolean} selected
   */
  const onSelect = (trade, selected) => {
    setSelectedTrades((p) => ({
      ...p,
      [trade.id]: selected,
    }));
  };

  const onChange = useCallback(
    /**
     *
     * @param {import('../scenarioSlice').Trade} trade
     * @param {Partial<import('../scenarioSlice').Trade>} patch
     */
    (trade, patch) => {
      _onChange({ ...trade, ...patch });
    },
    [_onChange],
  );

  /**
   *
   * @param {import('../../services/edgeApi').Node} node
   */
  const onLocateNode = (node) => {
    if (node) {
      panMap(node, 9);
    }
  };

  const onChangeFilter = useDebouncedCallback((key, value) => {
    setFilters((current) => ({ ...current, [key]: value }));
  });

  const filteredTrades = useMemo(() => {
    if (Object.keys(allPoints).length === 0) {
      return [];
    }
    const nonNullFilters = Object.entries(filters).filter(
      ([_key, value]) => value !== null && value !== undefined,
    );
    const stringFilters = nonNullFilters.filter(
      ([key, value]) =>
        ['location', 'pipeline', 'description', 'buy_sell'].includes(key) &&
        value !== '',
    );
    const numberFilters = nonNullFilters.filter(([key, _value]) =>
      ['volume', 'price'].includes(key),
    );

    return trades.filter((trade) => {
      const point = allPoints[trade.point];
      const location = `${point.id} ${point.name}`;
      const data = { ...trade, location };

      return (
        stringFilters.every(([key, value]) => {
          return data[key]
            .trim()
            .toLowerCase()
            .includes(value.trim().toLowerCase());
        }) &&
        numberFilters.every(([key, [min, max]]) => {
          return (
            (min === '' ||
              min === null ||
              min === undefined ||
              Number(data[key]) >= min) &&
            (max === '' ||
              max === null ||
              max === undefined ||
              Number(data[key]) <= max)
          );
        })
      );
    });
  }, [trades, filters, allPoints]);

  const isEmptySelection = useMemo(
    () => Object.values(selectedTrades).every((m) => Boolean(m) === false),
    [selectedTrades],
  );

  if (trades.length === 0) {
    return (
      <div
        style={{
          minHeight: '200px',
          height: '100%',
        }}
        className="d-flex align-items-center justify-content-center"
      >
        <p className="text-center text-muted">No trades</p>
      </div>
    );
  }
  return (
    <table className="table">
      <thead
        style={{
          position: 'sticky',
          top: 0,
        }}
      >
        <tr>
          <th>
            Point
            <div className="d-flex align-items-center mt-2">
              <FilterIcon className="mx-3" />
              <input
                className="form-control"
                type="text"
                onChange={(evt) => onChangeFilter('location', evt.target.value)}
              />
            </div>
          </th>
          <th>
            TSP
            <div className="d-flex align-items-center mt-2">
              <input
                className="form-control"
                type="text"
                onChange={(evt) => onChangeFilter('pipeline', evt.target.value)}
              />
            </div>
          </th>
          <th>
            Description
            <div className="d-flex align-items-center mt-2">
              <input
                className="form-control"
                type="text"
                onChange={(evt) =>
                  onChangeFilter('description', evt.target.value)
                }
              />
            </div>
          </th>
          <th>
            Buy/Sell
            <div className="d-flex align-items-center mt-2">
              <select
                className="form-select"
                defaultValue=""
                style={{ width: '90px' }}
                onChange={(evt) => onChangeFilter('buy_sell', evt.target.value)}
              >
                <option value=""> </option>
                <option value="BUY">Buy</option>
                <option value="SELL">Sell</option>
              </select>
            </div>
          </th>
          <th>
            Volume
            <div
              className="d-flex align-items-center mt-2 input-group"
              style={{
                minWidth: '120px',
              }}
            >
              <input
                className="form-control"
                type="number"
                placeholder="Min"
                value={filters.volume[0]}
                onChange={(evt) =>
                  onChangeFilter('volume', [
                    evt.target.value,
                    filters.volume[1],
                  ])
                }
              />
              <input
                className="form-control"
                type="number"
                placeholder="Max"
                value={filters.volume[1]}
                onChange={(evt) =>
                  onChangeFilter('volume', [
                    filters.volume[0],
                    evt.target.value,
                  ])
                }
              />
            </div>
          </th>
          <th style={{ minWidth: '120px' }}>
            Price
            <div className="d-flex align-items-center mt-2 input-group">
              <input
                className="form-control"
                type="number"
                placeholder="Min"
                value={filters.price[0]}
                onChange={(evt) =>
                  onChangeFilter('price', [evt.target.value, filters.price[1]])
                }
              />
              <input
                className="form-control"
                type="number"
                placeholder="Max"
                value={filters.price[1]}
                onChange={(evt) =>
                  onChangeFilter('price', [filters.price[0], evt.target.value])
                }
              />
            </div>
          </th>
          <th>Price Detail</th>
          {isEditing && <th>Actions</th>}
        </tr>
      </thead>
      <tbody>
        {isEditing && (
          <>
            {showDeleted && deletedTrades.length > 0 && (
              <tr>
                <td colSpan={9}>
                  <div
                    onClick={() =>
                      onRestore(deletedTrades.map((trade) => trade.id))
                    }
                    role="button"
                    className="d-flex align-items-center justify-content-center text-success"
                  >
                    <span>
                      Restore all <UndoIcon />
                    </span>
                  </div>
                </td>
              </tr>
            )}
            {showDeleted &&
              deletedTrades.map((trade) => {
                const point = allPoints[trade.point];
                const node = allNodes[point?.node];

                return (
                  <DeletedTradeTableRow
                    key={trade.id}
                    trade={trade}
                    point={point}
                    node={node}
                    onLocateNode={onLocateNode}
                    onRestore={onRestore}
                  />
                );
              })}
            {deletedTrades.length > 0 && (
              <tr>
                <td colSpan={9}>
                  <div
                    onClick={() => setShowDeleted((p) => !p)}
                    role="button"
                    className="d-flex flex-column align-items-center justify-content-center"
                  >
                    <span>
                      {showDeleted ? 'Hide' : 'Show'} {deletedTrades.length}{' '}
                      deleted markets
                    </span>
                    <span>
                      {showDeleted ? <ChevronUpIcon /> : <ChevronDownIcon />}
                    </span>
                  </div>
                </td>
              </tr>
            )}
            <BulkEditTradeTableRow
              onSelectAll={(checked) => {
                const selected = filteredTrades.reduce((acc, trades) => {
                  acc[trades.component_key] = checked;
                  return acc;
                }, {});
                setSelectedTrades(selected);
              }}
              allChecked={filteredTrades.every(
                (trade) => selectedTrades[trade.component_key],
              )}
              noneChecked={isEmptySelection}
              onDeleteSelected={() => {
                const selected = filteredTrades
                  .filter((trade) => selectedTrades[trade.component_key])
                  .map((trade) => trade.id);
                if (selected.length > 0) {
                  onBulkDelete(selected);
                  setSelectedTrades({});
                }
              }}
              onBulkUpdate={(patch) => {
                const selection = filteredTrades
                  .filter((trade) => selectedTrades[trade.component_key])
                  .map((trade) => trade.id);
                onBulkUpdate(selection, patch);
              }}
            />
          </>
        )}
        {filteredTrades.map((trade) => {
          const point = allPoints[trade.point];
          const node = allNodes[point?.node];
          return isEditing ? (
            <EditTradeTableRow
              key={trade.id}
              trade={trade}
              point={point}
              node={node}
              onDelete={onDelete}
              onChange={onChange}
              onLocateNode={onLocateNode}
              onSelect={onSelect}
              isSelected={Boolean(selectedTrades[trade.id])}
            />
          ) : (
            <TradeTableRow
              key={trade.id}
              trade={trade}
              point={point}
              node={node}
              onLocateNode={onLocateNode}
            />
          );
        })}
      </tbody>
    </table>
  );
};

/**
 *
 * @param {{
 *  trade: import('../scenarioSlice').Trade & {pipeline: string;};
 *  point: import('../../services/edgeApi').Point;
 *  node: import('../../services/edgeApi').Node;
 *  onLocateNode: (node: import('../../services/edgeApi').Node) => void;
 * }} props
 * @returns
 */
const _TradeTableRow = ({ trade, point, node, onLocateNode }) => {
  return (
    <tr>
      <td>
        <div className="d-flex align-items-center">
          <button
            className="btn btn-light me-2"
            disabled={!node}
            style={{
              opacity: 1,
            }}
            onClick={() => onLocateNode(node)}
          >
            <ZoomInIcon />
          </button>
          <span>
            <strong className="me-2">#{trade.point}</strong> {point.name}
          </span>
        </div>
      </td>
      <td>{trade.pipeline}</td>
      <td>{trade.description}</td>
      <td className="text-center">{trade.buy_sell}</td>
      <td className="text-center">{numberFormatter(trade.volume)}</td>
      <td className="text-center">{priceFormatter.format(trade.price)}</td>
      <td>{trade.price_explanation}</td>
    </tr>
  );
};

/**
 *
 * @param {{
 *  trade: import('../scenarioSlice').Trade & {pipeline: string;};
 *  point?: import('../../services/edgeApi').Point;
 *  node: import('../../services/edgeApi').Node;
 *  onLocateNode: (node: import('../../services/edgeApi').Node) => void;
 *  onRestore: (ids: string[]) => void;
 * }} props
 * @returns
 */
const _DeletedTradeTableRow = ({
  trade,
  point,
  node,
  onLocateNode,
  onRestore,
}) => {
  return (
    <tr className="table-danger">
      <td>
        <div className="d-flex align-items-center">
          <button
            className="btn btn-light me-2"
            disabled={!node}
            style={{
              opacity: 1,
            }}
            onClick={() => onLocateNode(node)}
          >
            <ZoomInIcon />
          </button>
          <span>
            <strong className="me-2">#{trade.point}</strong> {point.name}
          </span>
        </div>
      </td>
      <td>{trade.pipeline}</td>
      <td>{trade.description}</td>
      <td className="text-center">{trade.buy_sell}</td>
      <td className="text-center">{numberFormatter(trade.volume)}</td>
      <td className="text-center">{priceFormatter.format(trade.price)}</td>
      <td>{trade.price_explanation}</td>
      <td
        className="text-center"
        role="button"
        onClick={() => onRestore([trade.id])}
      >
        <UndoIcon color="green" />
      </td>
    </tr>
  );
};

/**
 *
 * @param {{
 *  trade: import('../scenarioSlice').Trade & {pipeline: string;};
 *  point: import('../../services/edgeApi').Point;
 *  node: import('../../services/edgeApi').Node;
 *  onDelete: (row: import('../scenarioSlice').Trade) => void;
 *  onChange: (original: import('../scenarioSlice').Trade, edited: import('../scenarioSlice').Trade) => void;
 *  onLocateNode: (node: import('../../services/edgeApi').Node) => void;
 *  onSelect: (trade: import('../scenarioSlice').Trade, selected: boolean) => void;
 *  isSelected: boolean;
 * }} props
 * @returns
 */
const _EditTradeTableRow = ({
  trade,
  point,
  node,
  onLocateNode,
  onDelete,
  onChange,
  onSelect,
  isSelected,
}) => {
  return (
    <tr className={isSelected ? 'table-secondary' : ''}>
      <td>
        <div className="d-flex align-items-center">
          <div
            style={{ width: '30px' }}
            role="button"
            onClick={() => onSelect(trade, !isSelected)}
          >
            <input
              type="checkbox"
              readOnly
              checked={isSelected}
              className="form-check-input form-check-input-sm me-2"
            />
          </div>
          <button
            className="btn btn-light me-2"
            disabled={!node}
            style={{
              opacity: 1,
            }}
            onClick={() => onLocateNode(node)}
          >
            <ZoomInIcon />
          </button>
          <span onClick={() => onSelect(trade, !isSelected)} role="button">
            <strong className="me-2">#{trade.point}</strong> {point.name}
          </span>
        </div>
      </td>
      <td onClick={() => onSelect(trade, !isSelected)} role="button">
        {trade.pipeline}
      </td>
      <td>
        <DebouncedInput
          textArea
          type="text"
          className="form-control form-control-sm"
          value={trade.description}
          onChange={(description) => onChange(trade, { description })}
        />
      </td>
      <td className="text-center">
        <select
          type="select"
          className="form-select form-select-sm"
          value={trade.buy_sell}
          style={{ width: '90px' }}
          onChange={(e) => onChange(trade, { buy_sell: e.target.value })}
        >
          <option value="BUY">Buy</option>
          <option value="SELL">Sell</option>
        </select>
      </td>
      <td className="text-center">
        <DebouncedInput
          type="number"
          className="form-control form-control-sm"
          value={trade.volume}
          onChange={(volume) => onChange(trade, { volume })}
          min={0}
        />
      </td>
      <td className="text-center">
        <PriceInput price={trade} onChange={onChange} />
      </td>
      <td>{trade.price_explanation}</td>
      <td className="text-center">
        <TrashIcon
          onClick={() => onDelete(trade.id)}
          role="button"
          color="red"
        />
      </td>
    </tr>
  );
};

/**
 *
 * @param {{
 *  allChecked: boolean;
 *  noneChecked: boolean;
 *  onSelectAll: (selected: boolean) => void;
 *  onDeleteSelected: () => void;
 *  onBulkUpdate: (patch: Partial<import('../scenarioSlice').Trade>) => void;
 * }} props
 * @returns
 */
const BulkEditTradeTableRow = ({
  allChecked,
  noneChecked,
  onSelectAll,
  onDeleteSelected,
  onBulkUpdate,
}) => {
  /**
   * @type {import('../../types/types').State<Partial<import('../scenarioSlice').Trade>}
   */
  const [patch, setPatch] = useState({
    description: '',
    buy_sell: '',
    max_volume: 0,
    price: 0,
    base_price: 0,
    adder: 0,
    price_component: null,
    price_component_description: '',
  });
  return (
    <tr>
      <td colSpan={2}>
        <div className="d-flex align-items-center">
          <input
            type="checkbox"
            readOnly
            checked={allChecked}
            onChange={(e) => onSelectAll(e.target.checked)}
            className="form-check-input form-check-input-sm me-3"
          />
          <button
            className={`btn ${noneChecked ? 'btn-secondary' : 'btn-danger'}`}
            style={{ opacity: 1 }}
            disabled={noneChecked}
            onClick={onDeleteSelected}
          >
            Delete Selected
          </button>
        </div>
      </td>
      {noneChecked ? (
        <td colSpan={6}></td>
      ) : (
        <>
          <td>
            <input
              type="text"
              className="form-control form-control-sm"
              value={patch.description}
              onChange={(evt) => {
                setPatch((p) => ({ ...p, description: evt.target.value }));
              }}
            />
          </td>
          <td className="text-center">
            <select
              type="select"
              className="form-select form-select-sm"
              style={{ width: '90px' }}
              value={patch.buy_sell}
              onChange={(evt) => {
                setPatch((p) => ({ ...p, buy_sell: evt.target.value }));
              }}
            >
              <option value="">Select</option>
              <option value="BUY">Buy</option>
              <option value="SELL">Sell</option>
              <option value="BOTH">Both</option>
            </select>
          </td>
          <td className="text-center">
            <input
              type="number"
              className="form-control form-control-sm"
              min={0}
              value={patch.max_volume}
              onChange={(evt) => {
                setPatch((p) => ({ ...p, max_volume: evt.target.value }));
              }}
            />
          </td>
          <td className="text-center">
            <PriceInput
              price={patch}
              onChange={(_price, pricePatch) => {
                setPatch((p) => ({ ...p, ...pricePatch }));
              }}
            />
          </td>
          <td className="text-center"></td>
          <td className="text-center">
            <EditIcon
              className="text-primary"
              onClick={() =>
                onBulkUpdate(
                  Object.fromEntries(
                    Object.entries(patch).filter(([_, v]) => Boolean(v)),
                  ),
                )
              }
              role="button"
            />
          </td>
        </>
      )}
    </tr>
  );
};

const TradeTableRow = React.memo(_TradeTableRow, (prev, next) => {
  return deepEqual(prev.trade, next.trade);
});

const DeletedTradeTableRow = React.memo(_DeletedTradeTableRow, (prev, next) => {
  return deepEqual(prev.trade, next.trade);
});

const EditTradeTableRow = React.memo(_EditTradeTableRow, (prev, next) => {
  return (
    deepEqual(prev.trade, next.trade) && prev.isSelected === next.isSelected
  );
});
