/**
 * @typedef {{
 *  interconnect: import('../services/edgeApi').Interconnect | null,
 * }} InterconnectTooltipProps
 */

import { useSelector } from 'react-redux';
import { selectAllPoints } from '../plotter/plotterSelectors';
import { selectConstraintsAtPoints } from '../scenarios/scenarioSlice';
import { titleize } from '../utils/stringUtils';
import { numberFormatter } from '../helpers/formatters';

/**
 *
 * @param {InterconnectTooltipProps} props
 * @return {React.FC<InterconnectTooltipProps>}
 */
export const InterconnectTooltip = ({ interconnect }) => {
  const points = useSelector(selectAllPoints);
  const constraints = useSelector(selectConstraintsAtPoints);

  if (!interconnect) return null;

  const startPoint = points[interconnect.start_point];
  const endPoint = points[interconnect.end_point];

  const constraintsAtStartPoint = constraints[startPoint.id] ?? [];
  const constraintsAtEndPoint = constraints[endPoint.id] ?? [];

  const icConstraints = constraintsAtStartPoint.concat(constraintsAtEndPoint);

  return (
    <>
      <table className="table table-dark">
        <thead>
          <tr>
            <th scope="row">Interconnect</th>
            <td colSpan={2}>#{interconnect.id}</td>
          </tr>
          <tr>
            <th scope="row">Status</th>
            <td
              style={{
                color: interconnect.enabled ? 'limegreen' : 'red',
                fontWeight: 'bold',
              }}
              colSpan={2}
            >
              {interconnect.enabled ? 'Enabled' : 'Disabled'}
            </td>
          </tr>
          <tr>
            <th></th>
            <th>From</th>
            <th>To</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">TSP</th>
            <td>{startPoint?.tsp_name}</td>
            <td>{endPoint?.tsp_name}</td>
          </tr>
          <tr>
            <th scope="row">Loc Name</th>
            <td>{`#${startPoint.id} ${startPoint.name}`}</td>
            <td>{`#${endPoint.id} ${endPoint.name}`}</td>
          </tr>
          <tr>
            <th scope="row">Loc ID</th>
            <td>{startPoint.loc_id}</td>
            <td>{endPoint.loc_id}</td>
          </tr>
        </tbody>
      </table>

      {icConstraints.length > 0 && (
        <table className="table table-dark">
          <thead>
            <tr>
              <th className="text-center" colSpan="5">
                Constraints
              </th>
            </tr>
            <tr>
              <th>Description</th>
              <th>Priority</th>
              <th>Direction</th>
              <th>Source</th>
              <th>OAC</th>
            </tr>
          </thead>
          <tbody>
            {icConstraints.map((constraint) => {
              const point = points[constraint.point];
              return (
                <tr key={constraint.id}>
                  <td>{constraint.short_description}</td>
                  <td>{constraint.cuts_at_priority}</td>
                  <td>
                    {point.tsp_name}: {titleize(constraint.flow_direction)}
                  </td>
                  <td>
                    {titleize(
                      constraint.source_type?.split('_').join(' ') ?? '',
                    )}
                  </td>
                  <td>{numberFormatter(constraint.max_volume)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </>
  );
};
