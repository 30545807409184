import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

/**
 * @typedef {{
 *  header: import('react').ReactNode;
 *  footer: import('react').ReactNode;
 *  children: import('react').ReactNode;
 *  onClose: () => void;
 *  isOpen: boolean;
 * } & import('reactstrap').ModalProps} ReusableModalProps
 */

/**
 *
 * @type {React.FC<ReusableModalProps>}
 */
export const ReusableModal = (props) => {
  const { header, children, footer, onClose, isOpen, ...rest } = props;

  const handleToggle = () => {
    if (isOpen) {
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={handleToggle} {...rest}>
      {header && <ModalHeader>{header}</ModalHeader>}
      <ModalBody>{children}</ModalBody>
      {footer && <ModalFooter>{footer}</ModalFooter>}
    </Modal>
  );
};
