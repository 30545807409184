import { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { scenarioActions } from '../../scenarios/scenarioSlice';

const defaultConstraintValues = {
  description: '',
  flow_direction: 'placeholder',
  constraint_factor: 0,
  max_volume: 1000000,
  cuts_at_priority: 'placeholder',
};

export function PopupPointConstraints({ pointId }) {
  const componentType = 'point_constraints';
  const dispatch = useDispatch();
  const [isAdding, setIsAdding] = useState(false);

  /**
   * @type {import('../scenarios/scenarioSlice').ScenarioState}
   */
  const {
    data: { point_constraints: allPointConstraints },
    isEditing,
  } = useSelector((state) => state.scenario);
  const pointConstraints = allPointConstraints.filter(
    (constraint) => constraint.point == pointId,
  );

  const [newConstraint, setNewConstraint] = useState({
    ...defaultConstraintValues,
    point: pointId,
  });

  const resetNewConstraint = () => {
    setNewConstraint({ ...defaultConstraintValues, point: pointId });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewConstraint({ ...newConstraint, [name]: value });
  };

  const handleSubmit = (e) => {
    e.view.L.DomEvent.stopPropagation(e);
    dispatch(
      scenarioActions.updateComponent({
        componentType,
        component: {
          ...newConstraint,
          constraint_factor: Number(newConstraint.constraint_factor) / 100,
        },
      }),
    );
    resetNewConstraint();
    setIsAdding(false);
  };

  const handleCancel = (e) => {
    e.view.L.DomEvent.stopPropagation(e);
    resetNewConstraint();
    setIsAdding(false);
  };

  const isValid = useMemo(() => {
    if (newConstraint.flow_direction === 'placeholder') {
      return false;
    }
    if (newConstraint.cuts_at_priority === 'placeholder') {
      return false;
    }
    return true;
  }, [newConstraint.flow_direction, newConstraint.cuts_at_priority]);

  if (isEditing !== true) {
    return null;
  }

  return (
    <div className="mt-2" style={{ minWidth: 300 }}>
      {isAdding ? (
        <h5>New Constraint</h5>
      ) : (
        <div className="d-flex justify-content-between">
          <h5>Constraints</h5>
          {isEditing && (
            <button
              className="btn btn-sm btn-success mb-1"
              onClick={(e) => {
                e.view.L.DomEvent.stopPropagation(e);
                setIsAdding(true);
              }}
            >
              Add Constraint
            </button>
          )}
        </div>
      )}
      {isAdding ? (
        <>
          <div className="form-group my-2">
            <label
              className="form-label mt-2"
              htmlFor={`${pointId}-description`}
            >
              Description
            </label>
            <input
              className="form-control"
              id={`${pointId}-description`}
              name="description"
              type="text"
              value={newConstraint.description}
              onChange={handleChange}
            />

            <label
              className="form-label mt-2"
              htmlFor={`${pointId}-flow-direction`}
            >
              Flow Direction
            </label>
            <select
              className="form-control"
              id={`${pointId}-flow-direction`}
              name="flow_direction"
              value={newConstraint.flow_direction}
              onChange={handleChange}
            >
              <option value="placeholder" disabled>
                Select Direction
              </option>
              <option value="RECEIPT">Receipt</option>
              <option value="DELIVERY">Delivery</option>
            </select>

            <label
              className="form-label mt-2"
              htmlFor={`${pointId}-constraint-factor`}
            >
              Constraint Factor
            </label>
            <input
              className="form-control"
              id={`${pointId}-constraint-factor`}
              name="constraint_factor"
              min="0"
              max="100"
              type="number"
              value={newConstraint.constraint_factor}
              onChange={handleChange}
            />

            <label
              className="form-label mt-2"
              htmlFor={`${pointId}-max-volume`}
            >
              Max Volume
            </label>
            <input
              className="form-control"
              id={`${pointId}-max-volume`}
              name="max_volume"
              type="number"
              value={newConstraint.max_volume}
              onChange={handleChange}
            />

            <label className="form-label mt-2" htmlFor={`${pointId}-priority`}>
              Cuts at Priority
            </label>
            <select
              className="form-control"
              id={`${pointId}-priority`}
              name="cuts_at_priority"
              value={newConstraint.cuts_at_priority}
              onChange={handleChange}
            >
              <option value="placeholder" disabled>
                Select Priority
              </option>
              <option value="PIP">PIP</option>
              <option value="SIP_PS">SIP_PS</option>
              <option value="SIP_SP">SIP_SP</option>
              <option value="SIP_SS">SIP_SS</option>
              <option value="SOP">SOP</option>
              <option value="IT">IT</option>
            </select>
          </div>
          <div className="d-flex">
            <button className="btn btn-danger me-1 mb-1" onClick={handleCancel}>
              Cancel
            </button>
            <button
              className="btn btn-success mb-1"
              onClick={handleSubmit}
              disabled={isValid === false}
            >
              Save
            </button>
          </div>
        </>
      ) : (
        <table className="table">
          <thead>
            <tr>
              <th>Direction</th>
              <th>Constraint Factor</th>
            </tr>
          </thead>
          <tbody>
            {pointConstraints?.length > 0 ? (
              pointConstraints.map((constraint) => (
                <tr key={constraint.id}>
                  <td>{constraint.flow_direction}</td>
                  <td>{constraint.constraint_factor * 100}%</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2" className="text-center">
                  No Constraints
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
}
