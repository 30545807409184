import { useSelector } from 'react-redux';
import { useState, useMemo } from 'react';
import { DateSelector } from '../../helpers/DateSelector';
import { selectEditState } from '../scenarioSlice';
import { toIsoDate } from '../../utils/stringUtils';
import * as plotterSelectors from '../../plotter/plotterSelectors';
import { PointConstraintTable } from './PointConstraintTable';
import { CreatePointConstraint } from './CreatePointConstraint';
import { PointConstraintSpreadsheet } from './PointConstraintSpreadsheet';
import { TableRowsIcon } from '@iconicicons/react';
import { useScenarioPointConstraints } from '../../hooks/useScenarioPointConstraints';

/**
 *
 * @param {{
 *  contextKey: string
 * }} props
 * @returns
 */
export const PointConstraints = ({ contextKey }) => {
  const allPoints = useSelector(plotterSelectors.selectAllPoints);
  const isEditing = useSelector(selectEditState);

  const {
    pointConstraints: _pointConstraints,
    deletedPointConstraints: _deletedPointConstraints,
    constraintDate,
    isFetching,
    isLoading,
    refreshPointConstraints,
    setConstraintDate,
    removeItem,
    bulkRemove,
    restoreItems,
    saveItem,
    append,
    overwrite,
    bulkUpdate,
  } = useScenarioPointConstraints();

  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  const pointConstraints = useMemo(() => {
    return _pointConstraints.map((constraint) => {
      const point = allPoints[constraint.point];
      return {
        ...constraint,
        pipeline: point?.tsp_name || '',
      };
    });
  }, [_pointConstraints]);

  const deletedPointConstraints = useMemo(() => {
    return _deletedPointConstraints.map((constraint) => {
      const point = allPoints[constraint.point];
      return {
        ...constraint,
        pipeline: point?.tsp_name || '',
      };
    });
  }, [_deletedPointConstraints]);

  return (
    <>
      {isEditing && (
        <>
          <div className="row justify-content-between m-3">
            <div className="col-6 col-md-8 col-sm-12 d-flex gap-2 align-items-center">
              <DateSelector
                value={toIsoDate(constraintDate)}
                onChange={(evt) => setConstraintDate(evt.target.valueAsDate)}
                isLoading={isLoading || isFetching}
                onConfirm={refreshPointConstraints}
                buttonText="Get Constraints"
              />
              <span role="button" className="text-primary">
                <TableRowsIcon onClick={() => setIsImportModalOpen(true)} />
              </span>
            </div>
            <div className="col-auto">
              <button
                onClick={() => setIsAdding((prev) => !prev)}
                className="btn btn-success"
              >
                {isAdding ? 'Cancel' : 'Add Point Constraint'}
              </button>
            </div>
          </div>
          {isAdding && (
            <div className="row m-3">
              <CreatePointConstraint
                onCreate={(constraints) => {
                  constraints.forEach((constraint) => saveItem(constraint));
                  setIsAdding(false);
                }}
              />
            </div>
          )}
        </>
      )}
      <PointConstraintTable
        key={contextKey}
        pointConstraints={pointConstraints}
        deletedPointConstraints={deletedPointConstraints}
        onDelete={(constraint) => removeItem(constraint.id)}
        onBulkDelete={bulkRemove}
        onChange={saveItem}
        onCreate={saveItem}
        onRestore={restoreItems}
        onBulkUpdate={bulkUpdate}
        isEditing={isEditing}
      />
      <PointConstraintSpreadsheet
        isOpen={isImportModalOpen}
        onToggle={() => setIsImportModalOpen((p) => !p)}
        onImport={append}
        onOverwrite={overwrite}
      />
    </>
  );
};
